import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgStepperModule } from "angular-ng-stepper";

import { FindPatientComponent } from "./patient/find-patient/find-patient.component";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { FullCalendarModule } from "@fullcalendar/angular";
import { NgApexchartsModule } from "ng-apexcharts";
import { AlertModule } from "ngx-bootstrap/alert";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { DROPZONE_CONFIG, DropzoneModule } from "ngx-dropzone-wrapper";
import { LightboxModule } from "ngx-lightbox";
import { SimplebarAngularModule } from "simplebar-angular";
import { PatientComponent } from "./patient/patient/patient.component";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { ImageCropperModalComponent } from "./image-cropper/image-cropper-modal.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgSelectModule } from "@ng-select/ng-select";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MainComponent } from "./main/main.component";
import { OrdersComponent } from "./orders/orders.component";
import { PatientsComponent } from "./patient/patients/patients.component";
import { PatientDetailComponent } from "./patient/patient-detail/patient-detail.component";
import { CoreModule } from "../core/core.module";
import { UIModule } from "../shared/ui/ui.module";
import { EmployeesComponent } from "./employees/employees.component";
import { SelectModule } from "./select/select.module";
import { FavoritesComponent } from "./favorites/favorites.component";
import { PayableComponent } from "./payables/payable/payable.component";
import { PayablesComponent } from "./payables/payables.component";
import { PaidPayablesComponent } from "./payables/paid-payables/paid-payables.component";
import { UnPaidPayablesComponent } from "./payables/unpaid-payables/unpaid-payables.component";
import { PaidInvoiceComponent } from "./payables/paid-invoice/paid-invoice.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { PrescriptionComponent } from "./prescriptions/prescription/prescription.component";
import { PrescriptionsComponent } from "./prescriptions/prescriptions.component";
import { RxExpiredPipe } from "../core/pipes/rx-expired.pipe";
import { ReceivablesComponent } from "./payables/receivables/receivables.component";
import { VendorsComponent } from "./vendors/vendors.component";
import { VendorComponent } from "./vendor/vendor.component";
import { ExamsComponent } from "./exams/exams.component";
import { ExamComponent } from "./exams/exam/exam.component";
import { SaasComponent } from "./dashboard/saas/saas.component";
import { SellingchartComponent } from "./dashboard/saas/shared/sellingchart/sellingchart.component";
import { NgChartsModule } from "ng2-charts";
import { OrderComponent } from "./orders/order/order.component";
import { EmployeeComponent } from "./employees/employee/employee.component";
import { CurrencyFormatterDirective } from "../shared/directives/currency-formatter.directive";
import { UploadsComponent } from "./file-select/uploads.component";
import { VendorDataComponent } from "./dashboard/vendor-data/vendor-data.component";
import { StoreDataComponent } from "./dashboard/store-data/store-data.component";
import { PatientFilleComponent } from "./patient/patient-fill/patient-fill.component";
import { NewPatientOrderComponent } from "./new-patient-order/new-patient-order.component";
import { OrderMinimalComponent } from "./new-patient-order/order/order.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { OrderInvoiceComponent } from "./orders/order-invoice/order-invoice.component";

const routes: Routes = [
  { path: "", component: SaasComponent },
  {
    path: "dashboard",
    component: SaasComponent,
  },
  {
    path: "dashboard/vendors",
    component: VendorDataComponent,
  },
  {
    path: "dashboard/stores",
    component: StoreDataComponent,
  },
  {
    path: "patients/new",
    component: PatientComponent,
  },
  {
    path: "patients/:patientId/rx",
    component: PrescriptionsComponent,
  },
  {
    path: "patients/:patientId/orders",
    component: OrdersComponent,
  },
  {
    path: "patients/:patientId/order",
    component: NewPatientOrderComponent,
  },
  {
    path: "patients/:patientId/order/:orderId",
    component: NewPatientOrderComponent,
  },
  {
    path: "patients/:patientId",
    component: PatientComponent,
  },
  {
    path: "patients",
    component: PatientsComponent,
  },

  {
    path: "orders",
    component: OrdersComponent,
  },
  {
    path: "orders/new",
    component: NewPatientOrderComponent,
    data: { useWizard: true },
  },
  {
    path: "orders/:orderId/invoice",
    component: OrderInvoiceComponent,
  },
  {
    path: "orders/:orderId",
    component: NewPatientOrderComponent,
  },

  {
    path: "employees/new",
    component: EmployeeComponent,
  },
  {
    path: "employees/:employeeId",
    component: EmployeeComponent,
  },

  {
    path: "employees",
    component: EmployeesComponent,
  },

  {
    path: "prescriptions/new",
    component: PrescriptionComponent,
  },
  {
    path: "prescriptions/:id",
    component: PrescriptionComponent,
  },
  {
    path: "prescriptions",
    component: PrescriptionsComponent,
  },
  {
    path: "favorite-lines",
    component: FavoritesComponent,
  },
  {
    path: "receivables",
    component: ReceivablesComponent,
  },
  {
    path: "payables",
    component: PayablesComponent,
  },
  {
    path: "payables/vendor/:id/paid",
    component: PaidPayablesComponent,
  },
  {
    path: "payables/vendor/:id/unpaid",
    component: UnPaidPayablesComponent,
  },
  {
    path: "payables/group/:id",
    component: PayableComponent,
  },
  {
    path: "payables/invoice/:id",
    component: PaidInvoiceComponent,
  },
  {
    path: "receivables/invoice/:id",
    component: PaidInvoiceComponent,
    data: { receivable: true },
  },
  {
    path: "vendors",
    component: VendorsComponent,
  },
  { path: "vendors/:id", component: VendorComponent },
  {
    path: "vendors/new",
    component: VendorComponent,
  },
  {
    path: "eye-exams/new",
    component: ExamComponent,
  },
  {
    path: "eye-exams/:id",
    component: ExamComponent,
  },
  {
    path: "eye-exams",
    component: ExamsComponent,
  },
  {
    path: "patient-order",
    component: NewPatientOrderComponent,
    data: { useWizard: true },
  },
];

@NgModule({
  declarations: [
    PatientComponent,
    FindPatientComponent,
    ImageCropperComponent,
    ImageCropperModalComponent,
    MainComponent,
    OrdersComponent,
    OrderComponent,
    PatientsComponent,
    PatientDetailComponent,
    EmployeesComponent,
    EmployeeComponent,
    FavoritesComponent,
    PayablesComponent,
    PaidPayablesComponent,
    PayableComponent,
    UnPaidPayablesComponent,
    PaidInvoiceComponent,
    PrescriptionComponent,
    PrescriptionsComponent,
    ReceivablesComponent,
    VendorsComponent,
    VendorComponent,
    ExamsComponent,
    ExamComponent,
    SaasComponent,
    SellingchartComponent,
    UploadsComponent,
    CurrencyFormatterDirective,
    VendorDataComponent,
    StoreDataComponent,
    PatientFilleComponent,
    NewPatientOrderComponent,
    OrderMinimalComponent,
    OrderInvoiceComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    FullCalendarModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    SimplebarAngularModule,
    LightboxModule,
    PickerModule,
    BsDatepickerModule,
    PaginationModule,
    ImageCropperModule,
    DropzoneModule,
    NgSelectModule,
    CKEditorModule,
    CoreModule,
    UIModule,
    SelectModule,
    AccordionModule,
    RxExpiredPipe,
    SimplebarAngularModule,
    TabsModule,
    NgApexchartsModule,
    NgChartsModule,
    NgStepperModule,
    CdkStepperModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: {
        url: "https://httpbin.org/post",
        maxFilesize: 10,
        createImageThumbnails: true,
        autoProcessQueue: false,
      },
    },
  ],
  exports: [RouterModule, CurrencyFormatterDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesRoutingModule {}
