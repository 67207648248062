import { User } from "src/app/core/models";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

export const getUserFormGroup = (user?: User) =>
  new FormGroup({
    _id: new FormControl(user?._id),
    name: new FormControl(user?.name, Validators.required),
    email: new FormControl(user?.email, [
      Validators.required,
      Validators.email,
    ]),
    role: new FormControl(user?.role, Validators.required),
    stores: new FormArray(
      (user?.stores ?? [])?.map((store) => new FormControl(store)),
      [Validators.required, Validators.minLength(1)]
    ),
    disabled: new FormControl(user?.disabled),
    anyIp: new FormControl(user?.anyIp),
    password: new FormControl(null, !user?._id ? Validators.required : []),
    initials: new FormControl(user?.initials, Validators.required),
  });
