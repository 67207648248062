import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Observable, Subject, take } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { PayableResponse } from "src/app/core/models";
import { PayablesService } from "src/app/core/services/payables.service";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationParams } from "src/app/types/pagination";
import { BsModalService } from "ngx-bootstrap/modal";
import { PayableComponent } from "../payable/payable.component";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorService } from "src/app/core/services/vendor.service";
import { Options } from "src/app/types/select";
@Component({
  selector: "app-unpaid-payables",
  templateUrl: "./unpaid-payables.component.html",
  styleUrls: ["./unpaid-payables.component.css"],
})
export class UnPaidPayablesComponent implements OnInit, OnChanges {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;
  @Input() receivable: boolean = false;
  @Input() store: number;
  payables: Array<PayableResponse> = [];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;
  breadCrumbs = [{ label: "Admin", url: "/" }];

  vendorId: string;
  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "";
  dir = "";
  search$ = new Subject<void>();
  loading$ = true;

  pagination: PaginationParams = DEFAULT_PAGINATION;

  private editState: string | null = null;
  payments: Record<string, number> = {};

  storeMap: Record<number, string> = {
    1: "Mill Valley",
    2: "Berkeley",
  };
  stores: Array<Options> = [
    {
      _id: 1,
      label: "Mill Valley",
      value: 1,
    },
    {
      _id: 2,
      label: "Berkeley",
      value: 2,
    },
  ];


  get startIndex() {
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
      unpaid: true,
      ...(this.vendorId ? { vendors: [this.vendorId] } : {}),
      ...(this.receivable ? { receivable: true } : {}),
      ...(this.store ? { store: this.store } : {}),
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private payablesService: PayablesService,
    private ms: BsModalService,
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.breadCrumbs.push({
          label: "Payables",
          url: `/payables`,
        });
        this.vendorId = params["id"];
        this.getVendor();
        this.getPayableGroups();
      }
    });
  }

  ngOnInit() {
    if (this.receivable) {
      this.breadCrumbs.push({
        label: "Receivables",
        url: `/receivables`,
      });
      this.getPayableGroups();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.store && changes.store.currentValue) {
      this.getPayableGroups();
    }
  }

  onStoreChange(store: number) {
    this.store = store;
    this.getPayableGroups();
  }

  getVendor() {
    this.vendorService.getVendor(this.vendorId).subscribe((vendor) => {
      this.breadCrumbs.push({
        label: vendor.name,
        url: `/payables/vendor/${this.vendorId}/unpaid`,
      });
    });
  }
  getPayableGroups() {
    this.loading$;
    this.payablesService
      .getPayables(this.queryParams)
      .subscribe(({ items, offset, limit, total }) => {
        this.payables = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
        this.loading$ = false;
        this.payments = items.reduce((acc, payable) => ({
          ...acc,
          [payable._id]: 0,
        }), {});
      });
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getPayableGroups();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getPayableGroups();
  }

  onSort(col: string) {
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getPayableGroups();
  }

  openModal(payable?: PayableResponse) {
    this.modalId = new Date().getTime();
    const modalRef = this.ms.show(PayableComponent, {
      id: this.modalId,
      class: "modal-lg",
      initialState: {
        payable,
      },
    });
    modalRef.onHide.pipe(take(1)).subscribe();
  }

  editField(field: string, row: number) {
    const key = `${row}-${field}`;
    console.log(key);
    this.editState = key;
    console.log(this.editState);
  }

  isEditing(field: string, row: number): boolean {
    const key = `${row}-${field}`;
    return this.editState === key;
  }

  saveField(field: string, row: number) {
    this.editState = null;
    // Add your save logic here
    // You might want to call your API to update the changes
  }

  viewByStatus(status: string) {
    if(status === 'Paid') {
      this.router.navigate(['/payables/vendor', this.vendorId, 'paid']);
    } else {
      this.router.navigate(['/payables/vendor', this.vendorId, 'unpaid']);
    }
  }
}
