<div class="container-fluid">
  <app-page-title
  title="Summary by Store"
  [breadcrumbItems]="breadCrumbItems"
></app-page-title>
  <div class="row mb-4">
    <div class="col-12">
      <div class="d-flex justify-content-between">
        <div
          class="d-flex align-items-center justify-content-start btn-group mode-select"
        >
          <div class="dropdown" dropdown>
            <div class="d-flex justify-content-center">
              <div>
                <select
                  class="form-select form-control-sm mx-2 heading"
                  (change)="view$.next($event.target.value)"
                >
                  @for (option of viewOptions; track $index) {
                  <option [value]="option.key">{{ option.label }}</option>
                  }
                </select>
              </div>
            </div>
          </div>
          <div class="dropdown" dropdown>
            <div>
              <select
                class="form-select form-control-sm mx-2 heading"
                (change)="store$.next($event.target.value)"
              >
                @for (option of sOptions; track $index) {
                <option [value]="option.value">{{ option.label }}</option>
                }
              </select>
            </div>
          </div>
          <div class="dropdown" dropdown>
            <div>
              <select
                class="form-select form-control-sm mx-2 heading"
                (change)="displayMode$.next($event.target.value)"
              >
                @for (option of displayModeOptions; track $index) {
                <option [value]="option.value">{{ option.label }}</option>
                }
              </select>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-end btn-group displayMode-select"
        >
          <div *ngIf="displayMode.value !== 1" class="dropdown" dropdown>
            <div>
              <select
                class="form-select form-control-sm mx-2 heading"
                (change)="quarter$.next($event.target.value)"
              >
                @for (option of qOptions; track $index) {
                <option [value]="option.value">{{ option.label }}</option>
                }
              </select>
            </div>
          </div>
          <!-- <div  *ngIf="displayMode.value !== 0" class="dropdown" dropdown>
                <button class="btn btn-secondary dropdown-toggle rounded-start" type="button" id="*dropdownMenuButton4" dropdownToggle
                >
                  {{ month?.label || "Select Month" }}
                  <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu" *dropdownMenu>
                  @for (option of mOptions; track $index) {
                  <a class="dropdown-item" href="javascript: void(0);" (click)="month$.next(option)">{{ option.label }}</a>
                  }
                </div>
              </div> -->

          <div class="dropdown" dropdown>
            <div>
              <select
                class="form-select form-control-sm mx-2 heading"
                (change)="year$.next($event.target.value)"
              >
                @for (option of yoOptions; track $index) {
                <option [value]="option.value">{{ option.label }}</option>
                }
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if(loading$) {
  <div
    style="
      width: 100%;
      min-height: 75vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div
      role="status"
      class="spinner-border text-success m-1"
      style="width: 100px; height: 100px"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  } @else {

  <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div *ngIf="pieChart.data" class="col-12" data-show="true">
              <div class="chartjs-chart mt-3">
                <canvas
                  class="chartjs-chart"
                  height="500"
                  baseChart
                  [data]="pieChart.data"
                  [options]="pieChart.options"
                  [type]="'pie'"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div *ngIf="pieChart2.data" class="col-12" data-show="true">
              <div class="chartjs-chart mt-3">
                <canvas
                  class="chartjs-chart"
                  height="500"
                  baseChart
                  [data]="pieChart2.data"
                  [options]="pieChart2.options"
                  [type]="'doughnut'"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div *ngIf="pieChart3.data" class="col-12" data-show="true">
              <div class="chartjs-chart mt-3">
                <canvas
                  class="chartjs-chart"
                  height="500"
                  baseChart
                  [data]="pieChart3.data"
                  [options]="pieChart2.options"
                  [type]="'doughnut'"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="row">
    <div class="card">
      <div class="row">
        <div class="card-body">
          <div *ngIf="lineChart.data?.datasets?.length" class="row">
            <div class="row">
              <div class="col-12" [class.col-md-4]="displayMode.value === 2">
                <div *ngIf="lineChart" class="mt-3" data-show="true">
                  <div class="chartjs-chart">
                    <canvas
                      *ngIf="displayMode.value !== 2"
                      class="chartjs-chart"
                      height="500"
                      baseChart
                      [data]="lineChart.data"
                      [options]="lineChart.options"
                      [type]="'bar'"
                    >
                    </canvas>
                    <canvas
                      *ngIf="displayMode.value === 2"
                      class="chartjs-chart"
                      height="500"
                      baseChart
                      [data]="pieChart3.data"
                      [options]="pieChart3.options"
                      [type]="'pie'"
                    >
                    </canvas>
                  </div>
                  <!-- <div>
                        <table>
                          @for(item of lineChart.data.labels; track item) {
                          <tr>
                            <td>{{ item }}</td>
                            <td>{{ lineChart.data.datasets[0].data[item] | number }}</td>
                          </tr>
                          }
                        </table>
                      </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  }
</div>
