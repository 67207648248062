import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "../models";
import { environment } from "src/environments/environment";
import { Params } from "@angular/router";
import { catchError, Observable, of } from "rxjs";
import { Pagination } from "src/app/types/pagination";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private base_url = `${environment.apiDomain}/users`;
  constructor(private http: HttpClient) {}

  getUsers(params?: Params): Observable<Pagination<User>> {
    console.log(params);
    return this.http
      .get<Pagination<User>>(`${this.base_url}`, {
        params,
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of({
            items: [],
            offset: 0,
            limit: params?.limit || 10,
            total: 0,
          });
        })
      );
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.base_url}/${id}`);
  }

  createUser(user: Partial<User>): Observable<User> {
    return this.http.post<User>(`${this.base_url}`, user);
  }

  updateUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>(`${this.base_url}/${user._id}`, user);
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.base_url}/${id}`);
  }
}
