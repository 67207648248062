import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { OrdersService } from "src/app/core/services/orders.service";
import { Order } from "src/app/core/models";
import { getControl, getOrderFormGroup } from "src/app/shared/utils/forms";
import {
  concatMap,
  debounceTime,
  filter,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { VendorService } from "src/app/core/services/vendor.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable, of, Subject, Subscription, timer } from "rxjs";
import {
  FrameOptions,
  FramesService,
} from "src/app/core/services/frames.service";
import { InventoryOptions, Options } from "src/app/types/select";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { ImageCropperComponent } from "ngx-image-cropper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormControl, FormGroup } from "@angular/forms";
import { ImageCropperModalComponent } from "../../image-cropper/image-cropper-modal.component";
import { Image } from "src/app/core/models/image";
import { calculateProductPrice } from "src/app/core/utils/product";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Store } from "@ngrx/store";
import { getUserStore } from "src/app/store/Authentication/authentication-selector";
import { CurrencyPipe } from "@angular/common";
import { Lightbox } from "ngx-lightbox";
import { generateObjectId } from "src/app/core/utils/objectId";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(CKEditorComponent) ckEditor: CKEditorComponent;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild("orderImage") imageEl: HTMLImageElement;

  @Output() orderSaved = new EventEmitter<boolean>();
  @Input() findPatient: boolean = false;

  patientId: string;
  order: Order;
  orderId: string;
  _orderId: string = generateObjectId();
  orderForm: FormGroup;
  orderImages: Array<Image> = [];
  destroy$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();
  options: FrameOptions = {
    types: [],
    colors: [],
    sizes: [],
    models: [],
  };
  optionsSelect$ = new Subject<void>();

  defaultTypeOptions = ["RX", "SUN", "OTHER"].map((type) => ({
    _id: null,
    label: type,
    value: type,
    retail: undefined,
    pricingFormula: undefined,
    cost: undefined,
  }));

  vendorOptions$: Observable<Array<Options>> = of([]);
  modelOptions$: Observable<Array<InventoryOptions>> = of([]);
  colorOptions$: Observable<Array<InventoryOptions>> = of([]);
  sizeOptions$: Observable<Array<InventoryOptions>> = of([]);
  typeOptions$: Observable<Array<InventoryOptions>> = of([]);

  orderTypeOptions$: Observable<Array<Options>> = of(
    ["O", "N", "A", "F"].map((type, idx) => ({
      _id: idx,
      label: type,
      value: type,
    }))
  );

  frameVendor: string;
  frameId: string | number;
  selectedInventoryOption: InventoryOptions;

  breadCrumbs = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Orders",
      url: "/orders",
    },
  ];

  // CkEditor variables
  editor = ClassicEditor;
  editorConfig = {
    height: "150px",
    licenseKey: environment.editorLicenseKey,
    ai: {
      enabled: true,
      openAI: {
        requestHeaders: {
          Authorization: `Bearer ${environment.openAIKey}`,
        },
      },
    },
    toolbar: [
      "aiCommands",
      "aiAssistant",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
    ],
  };

  editor$: Subscription;
  notesReady$ = new Subject<void>();
  modalRef: BsModalRef;
  cp = new CurrencyPipe("en-US");

  previousPayload: any;
  modal: boolean = false;
  modalId: string;

  get productFromGroup() {
    return this.orderForm?.get("_product");
  }
  get vendorControl() {
    return this.productFromGroup?.get("vendor");
  }
  get modelControl() {
    return this.productFromGroup?.get("model");
  }
  get colorControl() {
    return this.productFromGroup?.get("color");
  }
  get sizeControl() {
    return this.productFromGroup?.get("size");
  }
  get typeControl() {
    return this.productFromGroup?.get("type");
  }
  get quantityControl() {
    return this.orderForm?.get("quantity");
  }
  get taxControl() {
    return this.orderForm?.get("tax");
  }
  get shippingControl() {
    return this.orderForm?.get("shipping");
  }
  get previewImage() {
    return this.orderImages[0] ?? "assets/images/users/user-dummy-img.jpg";
  }

  constructor(
    private oS: OrdersService,
    private vS: VendorService,
    private fS: FramesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private ms: BsModalService,
    private router: Router,
    private store: Store<AuthenticationState>,
    private lightbox: Lightbox,
    private bs: BsModalService
  ) {
    this.orderForm = getOrderFormGroup({ patient: this.patientId } as any);
  }

  ngOnInit() {
    if (this.order) {
      this.setOrder(this.order);
    } else {
      this.store
        .select(getUserStore)
        .pipe(
          take(1),
          concatMap(({ user, store }) => {
            return this.route.params.pipe(
              take(1),
              tap((params) => {
                console.log(params);
                this.patientId = params["patientId"];
                if (params["orderId"]) {
                  this.orderId = params["orderId"];
                  this.getOrder();
                } else {
                  this.orderForm.patchValue({
                    _id: this._orderId,
                    user: user._id,
                    store: Number(store),
                    patient: this.patientId,
                  });
                  this.setObservables();
                }
              })
            );
          })
        )
        .subscribe();
    }
    this.vendorOptions$ = this.fS.getFrameVendorOptions();
    this.optionsSelect$
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$),
        tap(() => {})
      )
      .subscribe(() =>
        this.getOptions({
          vendor: this.vendorControl.value,
          model: this.modelControl.value,
          size: this.sizeControl.value,
          color: this.colorControl.value,
        })
      );
  }

  ngAfterViewInit() {
    this.notesReady$.pipe(take(1)).subscribe(() => {
      const notes = this.order?.notes;
      if (!notes) return;
      this.ckEditor.editorInstance.data.set(notes ?? "");
    });

    timer(100, 100)
      .pipe(
        takeUntil(this.notesReady$),
        filter(() => !!this.order && !!this.ckEditor?.editorInstance)
      )
      .subscribe(() => {
        this.notesReady$.next();
      });
  }

  ngAfterViewChecked() {
    if (this.ckEditor && !this.editor$) {
      // Patch the editor changes to the log control of type 'patient'
      // only one exists
      this.editor$ = this.ckEditor.change
        .pipe(debounceTime(250), takeUntil(this.destroy$))
        .subscribe((event) => {
          const editorContent = this.ckEditor.editorInstance.data.get();
          const notesControl = getControl<FormControl>(this.orderForm, "notes");
          notesControl?.patchValue(editorContent);
          if (this.order?.notes && this.order.notes !== editorContent) {
            notesControl?.markAsDirty();
            this.orderForm.markAsDirty();
          }
        });
    }
  }

  getOptions(params: Params) {
    console.log(params);
    this.fS
      .getFrameSelectionOptions(params)
      .pipe(
        take(1),
        tap(({ types, colors, sizes, models }) => {
          this.options = {
            types,
            colors,
            sizes,
            models,
          };
          const opts = [...types, ...this.defaultTypeOptions].reduce(
            (acc, v) => {
              if (!acc.find((o) => o.value === v.value)) {
                acc.push(v);
              }
              return acc;
            },
            []
          );
          const tps = this.sizeControl.value ? opts : [];
          this.typeOptions$ = of(tps);
          this.colorOptions$ = of(colors);
          this.sizeOptions$ = of(sizes);
          this.modelOptions$ = of(models);
        })
      )
      .subscribe();
  }

  getOrder() {
    if (!this.orderId) return;
    this.unsubscribe$.next();
    this.oS
      .getOrder(this.orderId)
      .pipe(
        take(1),
        tap((order) => {
          console.log(order);
          this.setOrder(order);
        })
      )
      .subscribe();
  }

  getNumberValue(value: string | number) {
    if (typeof value === "number") return value;
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }

  calculateTotal(option?: InventoryOptions) {
    // Clear both acc areas
    if (!option) {
      this.orderForm.patchValue({
        cost: 0,
        total: 0,
        retail: 0,
      } as any);
      this.productFromGroup.patchValue({
        cost: 0,
        total: 0,
      } as any);
      return;
    }
    this.selectedInventoryOption = option;
    const { cost, pricingFormula } = option;
    if (!cost || !pricingFormula) return;
    const retail = calculateProductPrice({ cost, pricingFormula });
    this.setTotal(retail, cost, pricingFormula);
  }

  calculateTotalFromForm() {
    const { cost, pricingFormula } = this.productFromGroup.getRawValue();
    if (!cost || !pricingFormula) return;
    const retail = calculateProductPrice({ cost, pricingFormula });
    this.setTotal(retail, cost, pricingFormula);
  }

  setTotal(retail: number, cost: number, pricingFormula: string) {
    const quantity = this.quantityControl.value;
    const price = retail * quantity;
    const { shipping: orderShipping, tax: orderTax } =
      this.orderForm.getRawValue();
    const tax = this.getNumberValue(orderTax);
    const shipping = this.getNumberValue(orderShipping);
    this.orderForm.patchValue(
      {
        cost,
        total: this.cp.transform(price + shipping + tax),
        retail,
      } as any,
      { emitEvent: false }
    );
    this.productFromGroup.patchValue(
      {
        quantity,
        cost,
        pricingFormula,
        retail,
        total: Number((price + shipping + tax).toFixed(2)),
        tax,
        shipping,
      } as any,
      { emitEvent: false }
    );
  }

  onVendorSelect(vendorId: string) {
    console.log(vendorId);
    this.frameVendor = vendorId;
    this.modelControl.reset();
    this.colorControl.reset();
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onModelSelect(model: string) {
    console.log(model);
    const o = this.options.models.find(({ value }) => value === model);
    this.frameId = o?._id;
    o && this.calculateTotal(o);
    this.colorControl.reset();
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onColorSelect(color: string) {
    console.log(color);
    const o = this.options.colors.find(({ value }) => value === color);
    this.frameId = o?._id;
    o && this.calculateTotal(o);
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onSizeSelect(size: string) {
    console.log(size);
    const o = this.options.sizes.find(({ value }) => value === size);
    this.frameId = o?._id;
    o && this.calculateTotal(o);
    this.optionsSelect$.next();
  }

  onTypeSelect(type: string) {
    console.log(type);
    if (!this.options.types.length) return;
    const o = this.options.types.find(({ value }) => value === type);
    this.frameId = o?._id;
    o && this.calculateTotal(o);
  }

  obj(i: any): any {
    return Object.keys(i).reduce((acc, v) => {
      if (i[v]) {
        acc[v] = i[v];
      }
      return acc;
    }, {});
  }

  setOrder(order: Order) {
    if (!this.order) {
      order.invoiceNumber?.length &&
        this.breadCrumbs.push({
          label: order.invoiceNumber,
          url: `/orders/${order.invoiceNumber}`,
        });
    }
    this.order = order;
    console.log(this.order);
    this.orderImages = order._images ?? [];
    this.frameId = order?.inventory;

    this.orderForm = getOrderFormGroup(this.obj(order));
    if (order._product) {
      console.log(order._product);
      const { model, size, color, type, vendor } = order._product;
      this.getOptions({ model, size, color, type, vendor });
    }
    this.setObservables();
  }

  saveOrder() {
    const value = this.orderForm.getRawValue();
    console.log(value);
    const { tax, shipping, total, inventory, ...rest } = value;
    const payload = {
      ...rest,
      tax: this.getNumberValue(tax),
      shipping: this.getNumberValue(shipping),
      total: this.getNumberValue(total),
      inventory: this.frameId?.toString(),
    };
    const obs$ = this.order?._id
      ? this.oS.updateOrder(payload)
      : this.oS.createOrder(payload);
    obs$
      .pipe(
        take(1),
        tap((order) => {
          if (this.patientId) {
            this.orderSaved.emit(true);
            return;
          }
          if (this.modalId) {
            this.bs.setDismissReason("save");
            this.bs.hide(this.modalId);
            return;
          }
          if (!this.orderId) {
            this.router.navigate(["/orders", order._id]);
          }
          this.setOrder(order);
        })
      )
      .subscribe();
  }

  openImageCropper() {
    const id = this.order?._id ?? this._orderId;
    const modalRef = this.ms.show(ImageCropperModalComponent, {
      id: `${id}-image-cropper`,
      class: "modal-lg",
      ignoreBackdropClick: true,
      backdrop: !this.modalId,
      initialState: {
        refId: id,
        type: "order",
        modalId: `${id}-image-cropper`,
      },
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason !== "cancel") this.getOrder();
      modalRef.hide();
    });
  }

  setObservables() {
    if(!this.orderForm) return;
    this.unsubscribe$.next();

    this.modelControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap((value) => {
          console.log(value);
          this.onModelSelect(value);
        })
      )
      .subscribe();
    this.colorControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap((value) => {
          console.log(value);

          this.onColorSelect(value);
        })
      )
      .subscribe();

    this.sizeControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap((value) => {
          console.log(value);

          this.onSizeSelect(value);
        })
      )
      .subscribe();
    this.typeControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(250),
        tap((value) => {
          console.log(value);

          this.onTypeSelect(value);
        })
      )
      .subscribe();

    this.quantityControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(250),
        tap((value) => {
          this.productFromGroup
            .get("quantity")
            .setValue(this.getNumberValue(value));
          if (this.selectedInventoryOption) {
            this.calculateTotal(this.selectedInventoryOption);
          } else {
            this.calculateTotalFromForm();
          }
        })
      )
      .subscribe();
    this.shippingControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(250),
        tap((value) => {
          this.productFromGroup
            .get("shipping")
            .setValue(this.getNumberValue(value));
          if (this.selectedInventoryOption) {
            this.calculateTotal(this.selectedInventoryOption);
          } else {
            this.calculateTotalFromForm();
          }
        })
      )
      .subscribe();
    this.taxControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(250),
        tap((value) => {
          this.productFromGroup.get("tax").setValue(this.getNumberValue(value));
          if (this.selectedInventoryOption) {
            this.calculateTotal(this.selectedInventoryOption);
          } else {
            this.calculateTotalFromForm();
          }
        })
      )
      .subscribe();
  }

  openImages(idx: number) {
    const album = this.order._images.map((image, index) => ({
      caption: `Order image ${index + 1}`,
      src: image.image,
      thumb: image.image,
    }));
    this.lightbox.open(album, idx);
  }

  onCancel() {
    if (this.patientId) {
      this.orderSaved.emit(false);
      return;
    }
    this.bs.setDismissReason("cancel");
    this.bs.hide(this.modalId);
  }
}
