import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { concatMap, map, take, tap } from "rxjs/operators";
import { Order, Payable, Vendor } from "src/app/core/models";
import { PayablesService } from "src/app/core/services/payables.service";
import { VendorService } from "src/app/core/services/vendor.service";
import { Lightbox } from "ngx-lightbox";
@Component({
  selector: "app-paid-invoice",
  templateUrl: "./paid-invoice.component.html",
  styleUrls: ["./paid-invoice.component.css"],
})
export class PaidInvoiceComponent {
  vendor: Vendor;
  payable: Payable;
  payableId: string;
  breadCrumbs = [];
  isModal: boolean = false;
  cost: number = 0;
  isReceivable: boolean = false;

  get storeName() {
    const { store = 1 } = this.payable?._vendor ?? {};
    if(store === 2) return 'Berkeley';
    return 'Mill Valley';
  }

  constructor(
    private route: ActivatedRoute,
    private payableService: PayablesService,
    private vendorService: VendorService,
    private lightbox: Lightbox
  ) {
    this.isReceivable = this.route.snapshot.data.receivable;
    if (this.isReceivable) {
      this.breadCrumbs.push({ label: "Receivables", url: "/receivables" });
    } else {
      this.breadCrumbs.push({ label: "Payables", url: "/payables" });
    }
  }

  ngOnInit() {
    if (this.payable) return;
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.payableId = params["id"];
        this.getData();
      }
    });
  }

  getData() {
    this.payableService
      .getPayable(this.payableId)
      .pipe(
        take(1),
        tap((payable) => {
          this.payable = payable;
        }),
      )
      .subscribe((vendor) => {
        !this.isReceivable &&
          this.breadCrumbs.push(
            {
              label: this.payable._vendor.name,
              url: `/payables/vendor/${this.payable._vendor._id}/paid`,
            },
            {
              label: `${this.payable.invoiceNumber}`,
              url: `/payables/invoice/${this.payableId}`,
            }
          );
      });
  }

  getPayable() {
    this.payableService.getPayable(this.payableId).subscribe((payable) => {
      this.payable = payable;
    });
  }

  openLightbox(order: Order, index: number) {
    const album = order._products.map((product) => ({ 
      src: product._images[0].image,
      caption: `Invoice #${order.invoiceNumber} | ${product.vendorName} | ${product.model}`,
      thumb: product._images[0].image,
    }));
    this.lightbox.open(album, index);
  }

  vendorAddress() {
    if (!this.payable._vendor.returnAddress) return '';
    return this.payable._vendor.returnAddress.split('\n').join('<br>');
  }

  
}
