<div *ngIf="_patient" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="col-6 col-md-3">
        <h5 *ngIf="!this.patient" class="card-title">
          {{ _patient.firstName }} {{ _patient.lastName }}
        </h5>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Address</th>
                  <td>
                    {{ _patient.address }}<br />
                    {{ _patient.city }}, {{ _patient.state }} {{ _patient.zip }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Home Phone</th>
                  <td>{{ _patient.phone }}</td>
                </tr>
                <tr>
                  <th scope="row">Work Phone</th>
                  <td>{{ _patient.phone2 }}</td>
                </tr>
                <tr>
                  <th scope="row">Mobile</th>
                  <td>{{ _patient.cell }}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail</th>
                  <td>{{ _patient.email }}</td>
                </tr>
                <tr>
                  <th scope="row">Location</th>
                  <td>{{ _patient.city }}, {{ _patient.zip }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Favorite Lines</th>
                  <td>
                    {{ _patient.favoriteLines }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Be Back List</th>
                  <td>
                    {{ _patient.beBackList }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
