export const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const yearOptions = [
  { label: "2025", value: 2025 },
  { label: "2024", value: 2024 },
  { label: "2023", value: 2023 },
  { label: "2022", value: 2022 },
  { label: "2021", value: 2021 },
  { label: "2020", value: 2020 },
  { label: "2019", value: 2019 },
];

export const quarterOptions = [
  { label: "Q1", value: 1 },
  { label: "Q2", value: 2 },
  { label: "Q3", value: 3 },
  { label: "Q4", value: 4 },
];

export const storeOptions = [
  { label: "Mill Valley", value: 1 },
  { label: "Berkeley", value: 2 },
];

export const displayModeOptions = [
  { label: "Quarterly", value: 0 },
  { label: "Monthly", value: 1 },
  // { label: "Yearly", value: 2 },
];

export const keyLabels = {
  cost: "Cost",
  retail: "Retail",
  revenue: "Revenue",
  shipping: "Shipping",
  tax: "Tax",
  total: "Total",
  awaitingFrameCount: "Await Frames",
  dispensedCount: "Sold",
  restockCount: "Stock Orders",
  returnCount: "Status RETURN",
  returnedCount: "Status RETURNED",
  totalCount: "# of Orders",
  lessThanSixMonths: "< 6 months",
  sixMonths: "< 12 months",
  oneYear: "< 18 months",
  eighteenMonths: "< 24 months",
  twoYear: "< 30 months",
  thirtyMonths: "< 36 months",
  threeYear: "> 36 months",
}

export const currencyKeyOptions = [
  { key: "cost", label: "Cost" },
  { key: "retail", label: "Retail" },
  { key: "revenue", label: "Revenue" },
  { key: "total", label: "Total" },
];

export const countKeyOptions = [
  { key: "awaitingFrameCount", label: "Await Frames" },
  { key: "dispensedCount", label: "Sold" },
  { key: "restockCount", label: "Stock Orders" },
  { key: "returnCount", label: "Status RETURN" },
  { key: "returnedCount", label: "Status RETURNED" },
  { key: "totalCount", label: "# of Orders" },
];

export const ageKeyOptions = [
  { key: "lessThanSixMonths", label: "< 6 months" },
  { key: "sixMonths", label: "< 12 months" },
  { key: "oneYear", label: "< 18 months" },
  { key: "eighteenMonths", label: "< 24 months" },
  { key: "twoYear", label: "< 30 months" },
  { key: "thirtyMonths", label: "< 36 months" },
  { key: "threeYear", label: "> 36 months" },
];

export const chartTypes = ["line", "bar"];

export const viewOptions = [
  { key: "currency", label: "Inventory Value" },
  { key: "age", label: "Inventory Age" },
  { key: "count", label: "Inventory Count" },
];

export const viewKeys: Record<string, Array<any>> = {
  age: ageKeyOptions,
  count: countKeyOptions,
  currency: currencyKeyOptions,
};

export const filterKeys: Record<number, Array<any>> = {
  0: ["quarter", "year"],
  1: ["month", "year"],
  2: ["year"],
};

// Function to generate random light hex color
export function generateLightColorHex() {
  // Generate higher values for lighter colors (between 150-255)
  const r = Math.floor(Math.random() * 105 + 150).toString(16);
  const g = Math.floor(Math.random() * 105 + 150).toString(16);
  const b = Math.floor(Math.random() * 105 + 150).toString(16);

  // Pad with zeros if needed
  const paddedR = r.length === 1 ? '0' + r : r;
  const paddedG = g.length === 1 ? '0' + g : g;
  const paddedB = b.length === 1 ? '0' + b : b;

  return `#${paddedR}${paddedG}${paddedB}`;
}

// Alternative with HSL for more consistent lightness
export function generatePastelColor() {
  return `hsl(${Math.random() * 360}, 70%, 80%)`;
}

// Array of predefined light colors
const lightColors = [
  '#FFB3BA', // Light pink
  '#BAFFC9', // Light green
  '#BAE1FF', // Light blue
  '#FFFFBA', // Light yellow
  '#FFB3F7', // Light purple
  '#B3FFF7', // Light cyan
  '#FFC8B3', // Light orange
  '#E8C8FF', // Light violet
  '#C8FFB3', // Light lime
  '#FFE4B3'  // Light peach
];

// Get random predefined light color
export function getRandomLightColor() {
  return lightColors[Math.floor(Math.random() * lightColors.length)];
}