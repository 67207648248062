import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { concatMap, tap } from "rxjs";
import { PaginationComponent } from "src/app/core/components/pagination/pagination.component";
import { ExamsResponse } from "src/app/core/models";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Pagination } from "src/app/types/pagination";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-exams",
  templateUrl: "./exams.component.html",
  styleUrls: ["./exams.component.css"],
})
export class ExamsComponent extends PaginationComponent implements OnInit {
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Exams", url: "/exams" },
  ];
  items: Array<ExamsResponse> = [];

  expandedRows: Record<number, boolean> = {};

  constructor(
    private http: HttpClient,
    private store: Store<AuthenticationState>
  ) {
    super();
  }

  getData() {
    this.store
      .select(getStore)
      .pipe(
        concatMap((store) =>
          this.http
            .get<Pagination<ExamsResponse>>(
              `${environment.apiDomain}/eye-exams`,
              {
                params: { ...this.queryParams, store },
              }
            )
            .pipe(
              tap(({ items, limit, total, offset }) => {
                this.items = items;
                this.pagination = { total, skip: offset, take: limit };
              })
            )
        )
      )
      .subscribe();
  }

  toggleRow(index: number) {
    const expanded = !!this.expandedRows[index];
    this.expandedRows = { [index]: !expanded };
  }
}
