<div *ngIf="patientForm" [class.container-fluid]="!child">
  <div class="col-12">
    <div [formGroup]="patientForm" class="row">
      <div class="col-12">
        <div class="row ms-0 ps-0">
          <div class="col-md-8">
            <div *ngIf="patient" class="row">
              <div [class.card]="!child">
                <div [class.row]="!child">
                  <div class="col-12">
                    <div class="d-flex align-items-center p-2">
                      <div class="me-3">
                        <div
                          class="image-selection"
                          (click)="openImageCropper()"
                        >
                          <p class="img-edit">Upload Image</p>
                          <img
                            #userImage
                            [src]="profileImage"
                            alt="Header Avatar"
                            class="rounded-circle avatar-md"
                          />
                        </div>
                      </div>
                      <div>
                        @if(patient) {
                        <h5 class="card-title">
                          {{ patient.lastName }}, {{ patient.firstName }}
                        </h5>
                        <h6 class="card-title-desc">
                          # {{ patient.patientNumber }} | Updated
                          {{ patient.dateUpdated | date : "MM-d-YYYY" }}
                        </h6>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div [class.card]="!child">
                <div [class.card-body]="!child">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          formControlName="firstName"
                          class="form-control form-select"
                          id="firstName"
                          placeholder="First Name"
                        />
                        <label for="firstName">First Name</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          formControlName="lastName"
                          class="form-control form-select"
                          id="lastName"
                          placeholder="Last Name"
                        />
                        <label for="lastName">Last Name</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating mb-3">
                        <select
                          formControlName="prefix"
                          class="form-select"
                          id="prefix"
                          aria-label="Prefix"
                        >
                          @for (option of prefixOptions$ | async; track option)
                          {
                          <option value="{{ option.value }}">
                            {{ option.label }}
                          </option>
                          }
                        </select>
                        <label for="prefix">Prefix</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating mb-3">
                        <input
                          type="date"
                          formControlName="dob"
                          id="dob"
                          class="form-control form-select"
                        />
                        <label for="dob">DOB</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div>
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          formControlName="address"
                          class="form-control form-select"
                          id="address"
                        />
                        <label for="address">Address</label>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-floating mb-3">
                            <input
                              type="email"
                              formControlName="city"
                              class="form-control form-select"
                              id="city"
                            />
                            <label for="city">City</label>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-floating mb-3">
                            <select
                              formControlName="state"
                              class="form-select"
                              id="state"
                              aria-label="Select State"
                            >
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA" selected>California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                            <label for="floatingSelectGrid">State</label>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-floating mb-3">
                            <input
                              type="email"
                              formControlName="zip"
                              class="form-control form-select"
                              id="zip"
                            />
                            <label for="zip">Zip</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6 col-md-3">
                          <div class="form-floating mb-3">
                            <input
                              type="tel"
                              formControlName="phone"
                              class="form-control form-select"
                              id="phone"
                            />
                            <label for="phone">Home Phone</label>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-floating mb-3">
                            <input
                              type="tel"
                              formControlName="phone2"
                              class="form-control form-select"
                              id="phone2"
                            />
                            <label for="phone2">Work Phone</label>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-floating mb-3">
                            <input
                              type="tel"
                              formControlName="cell"
                              class="form-control form-select"
                              id="cell"
                            />
                            <label for="cell">Cell Phone</label>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-floating mb-3">
                            <input
                              type="email"
                              formControlName="email"
                              class="form-control form-select"
                              id="email"
                            />
                            <label for="email">Email</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-floating mb-3">
                            <input
                              formControlName="occupation"
                              type="email"
                              class="form-control form-select"
                              id="occupation"
                              placeholder="Occupation"
                            />
                            <label for="occupation">Occupation</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div
                            class="form-floating form-floating-ng-select mb-3"
                          >
                            <ng-select
                              [multiple]="true"
                              id="fovorites"
                              bindLabel="label"
                              bindValue="value"
                              class="form-select"
                              [items]="vendorOptions$ | async"
                            >
                            </ng-select>
                            <label for="favorites">Favorite Lines</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-floating mb-3">
                            <textarea
                              formControlName="beBackList"
                              class="form-control form-select auto-size"
                              id="beBackList"
                              rows="2"
                            ></textarea>
                            <label for="beBackList">Be Back List</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-floating mb-3">
                            <textarea
                              formControlName="interests"
                              class="form-control form-select auto-size"
                              id="interests"
                              rows="1"
                            ></textarea>
                            <label for="interests">Interests</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-floating mb-3">
                            <select
                              formControlName="howDidYouFindUs"
                              class="form-select"
                              id="howDidYouFindUs"
                            >
                              @for (option of findUsOptions$ | async; track
                              option) {
                              <option value="{{ option.value }}">
                                {{ option.label }}
                              </option>
                              }
                            </select>
                            <label for="howDidYouFindUs"
                              >How did you find us?</label
                            >
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="form-floating mb-3">
                            <textarea
                              formControlName="howDidYouFindUsOther"
                              class="form-control form-select auto-size"
                              id="howDidYouFindUsOther"
                              rows="2"
                            ></textarea>
                            <label for="howDidYouFindUsOther">Other</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!modal" class="card-actions">
                    <div class="row">
                      <div class="col-4 col-md-2">
                        <button
                          type="button"
                          class="btn w-md"
                          [class.btn-primary]="patientForm.dirty"
                          [class.btn-light]="!patientForm.dirty"
                          (click)="savePatient()"
                          [disabled]="!patientForm.dirty"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>

                  <!--
          <div class="mb-3">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="floatingCheck">
              <label class="form-check-label" for="floatingCheck">
                Check me out
              </label>
            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-primary w-md">Submit</button>
          </div> -->
                </div>
                <!-- end card body -->
              </div>

              <!-- end col -->
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div [class.card]="!child">
                <div [class.card-body]="!child">
                  <div [class.row]="!child">
                    <div class="col-12 mb-3">
                      <ckeditor
                        #patientNotes
                        id="patient-notes"
                        [editor]="editor"
                        [config]="editorConfig"
                        (ready)="notesReady$.next()"
                      ></ckeditor>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-floating mb-3">
                        <input
                          formControlName="storeCredit"
                          type="number"
                          class="form-control form-select"
                          id="storeCredit"
                        />
                        <label for="storeCredit">Store Credit</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-floating mb-3">
                        <select
                          formControlName="discountStatus"
                          class="form-select"
                          id="discountStatus"
                          aria-label="Discount Status"
                        >
                          @for (option of discountOptions$ | async; track
                          option) {
                          <option value="{{ option.value }}">
                            {{ option.label }}
                          </option>
                          }
                        </select>
                        <label for="discountStatus">Discount Status</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          type="checkbox"
                          formControlName="sleep"
                          id="sleep"
                          class="form-check-input"
                        /><label for="sleep" class="form-check-label"
                          >Sleep</label
                        >
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          type="checkbox"
                          formControlName="optOut"
                          id="optOut"
                          class="form-check-input"
                        /><label for="optOut" class="form-check-label"
                          >Marketing Opt-out</label
                        >
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          type="checkbox"
                          formControlName="alertNotes"
                          id="sleep"
                          class="form-check-input"
                        /><label for="alertNotes" class="form-check-label"
                          >Alert Notes</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="modal" class="row">
          <div class="d-flex justify-content-end px-3 py-2">
            <button
              type="button"
              class="btn w-md"
              [class.btn-primary]="!patientForm.dirty"
              [class.btn-danger]="patientForm.dirty"
              (click)="closeModal()"
            >
              {{ patientForm.dirty ? "Cancel" : "Close" }}
            </button>
            <button
              type="button"
              class="btn w-md ms-3"
              [class.btn-primary]="patientForm.dirty"
              [class.btn-light]="!patientForm.dirty"
              (click)="savePatient()"
              [disabled]="!patientForm.dirty || !patientForm.valid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="patient">
    <accordion
      *ngIf="patient"
      [closeOthers]="true"
      class="accordion"
      [isAnimated]="true"
    >
      <accordion-group
        [isOpen]="isFirstOpen"
        heading="Orders"
        class="accordion-item"
        #group
        ><span accordion-heading class="float-end fs-5">
          <i
            class="mdi"
            [ngClass]="
              !group?.isOpen
                ? 'mdi mdi-chevron-down accor-plus-icon'
                : 'mdi mdi-chevron-up accor-plus-icon'
            "
          ></i>
        </span>
        <app-orders
          [patientId]="patient?._id"
          [limit]="5"
          [child]="true"
        ></app-orders>
      </accordion-group>
    </accordion>
    <accordion
      *ngIf="patient"
      [closeOthers]="true"
      class="accordion"
      [isAnimated]="true"
    >
      <accordion-group
        [isOpen]="isFirstOpen"
        heading="Prescriptions"
        class="accordion-item"
        #group
        ><span accordion-heading class="float-end fs-5">
          <i
            class="mdi"
            [ngClass]="
              !group?.isOpen
                ? 'mdi mdi-chevron-down accor-plus-icon'
                : 'mdi mdi-chevron-up accor-plus-icon'
            "
          ></i>
        </span>
        <app-prescriptions
          [patientId]="patient?._id"
          [limit]="5"
          [child]="true"
        ></app-prescriptions>
      </accordion-group>
    </accordion>
  </ng-container>
</div>
