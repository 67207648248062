import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, debounceTime, takeUntil, tap } from "rxjs";
import { PaginationParams } from "src/app/types/pagination";
import { DEFAULT_PAGINATION } from "../../constants/pagination";
import { PaginationComponent as BsPaginationComponent } from "ngx-bootstrap/pagination";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild(BsPaginationComponent) pC: BsPaginationComponent;
  form: FormGroup = new FormGroup({});
  items: Array<unknown> = [];
  col = "_id";
  dir = "asc";
  expandedRows: Record<number, boolean> = {};
  page: number = 1;
  searchId: number;
  modalId: string | number;
  search = "";
  search$ = new Subject<void>();
  destroy$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;

  breadCrumbs = [];

  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return {
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
    };
  }

  get totalRecords() {
    return this.pagination.total;
  }

  ngOnInit(): void {
    this.getData();
    this.search$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap(() => {
          this.page = 1;
          this.pagination.skip = 0;
          this.getData();
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {}

  ngAfterViewChecked() {}

  ngOnChanges() {}

  ngOnDestroy() {}

  getData() {}

  expandRow(index: number | string) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.getData();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getData();
  }

  onSort(col: string) {
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getData();
  }

  openModal(obj?: unknown) {}

  onCreate(obj?: unknown) {}

  onEdit(obj?: unknown) {}

  onDelete(obj?: unknown) {}
}
