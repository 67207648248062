// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiDomain: "https://dev.autooptiq.com/api",
  openAIKey:
    "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",
  editorLicenseKey:
    "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3Njk4MTc1OTksImp0aSI6ImNjMTViNjRjLTU2NGEtNGNmNS04NTI2LTgwYjQ3OTJhODVmZCIsImxpY2Vuc2VkSG9zdHMiOlsiMTI3LjAuMC4xIiwibG9jYWxob3N0IiwiMTkyLjE2OC4qLioiLCIxMC4qLiouKiIsIjE3Mi4qLiouKiIsIioudGVzdCIsIioubG9jYWxob3N0IiwiKi5sb2NhbCJdLCJ1c2FnZUVuZHBvaW50IjoiaHR0cHM6Ly9wcm94eS1ldmVudC5ja2VkaXRvci5jb20iLCJkaXN0cmlidXRpb25DaGFubmVsIjpbImNsb3VkIiwiZHJ1cGFsIl0sImxpY2Vuc2VUeXBlIjoiZGV2ZWxvcG1lbnQiLCJmZWF0dXJlcyI6WyJEUlVQIl0sInZjIjoiZWU0MTNiOGMifQ.FH1ZTylQzRuzwSk4jvfWrfpJjb3mi20twYN5Cs97OsEqVyxtCQlmCWfKvqLk7ds3NocBrIqMHpQREGtkZT2BvQ",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
