import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PhonePipe } from "./pipes/phone.pipe";
import { PaginationComponent } from "./components/pagination/pagination.component";

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, PhonePipe],
  exports: [PhonePipe, PaginationComponent],
})
export class CoreModule {}
