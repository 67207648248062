import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { concatMap, map, tap } from "rxjs/operators";
import { AuthResponse } from "../models/api.model";
import { AuthenticationService } from "./auth.service";
import { select, Store } from "@ngrx/store";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { environment } from "src/environments/environment";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { Params } from "@angular/router";
import { Pagination } from "src/app/types/pagination";
import { Frame } from "src/app/types/frame";
import { Patient } from "../models/patient";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  private base_url = `${environment.apiDomain}/patients`;

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  getFileBlob(url: string): Observable<Blob> {
    return this.http
      .get(url, { responseType: "blob" })
      .pipe(tap((res) => console.log(res)));
  }

  queryPatients(query: string): Observable<Patient[]> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http
          .get(`${this.base_url}/query/${query}/store/${store}`)
          .pipe(map((res: AuthResponse<Patient[]>) => res.patients))
      )
    );
  }

  queryPatientsList(query: String): Observable<Patient[]> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http
          .get(`${this.base_url}/queryList/${query}/store/${store}`)
          .pipe(map((res: AuthResponse<Patient[]>) => res.patients))
      )
    );
  }

  addPatient(patient: Partial<Patient>): Observable<Patient> {
    return this.store.pipe(
      select(getStore),
      concatMap((storeId) =>
        this.http.post<Patient>(
          `${this.base_url}`,
          JSON.stringify({ ...patient, storeId })
        )
      )
    );
  }

  getPatient(patientId: string): Observable<Patient> {
    return this.http.get<Patient>(`${this.base_url}/${patientId}`);
  }

  updatePatient(patient: Partial<Patient>): Observable<Patient> {
    return this.http.put<Patient>(
      `${this.base_url}/${patient._id}`,
      JSON.stringify(patient)
    );
  }

  deletePatient(patientId: any): Observable<unknown> {
    return this.http.delete(`${this.base_url}/${patientId}`);
  }

  getPatients(params?: Params): Observable<Pagination<Patient>> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Pagination<Patient>>(`${this.base_url}/store/${store}`, {
          params,
        })
      )
    );
  }

  searchPatients(params: Partial<Patient>): Observable<Patient[]> {
    return this.http.post<Patient[]>(`${this.base_url}/search`, params);
  }

  getFrames(patientId: string): Observable<Frame[]> {
    return this.http.get<Array<Frame>>(`${this.base_url}/${patientId}/frames`);
  }

  // copyPatient(patient: Partial<Patient>): Observable<AuthResponse<any>> {
  //   return this.store.pipe(
  //     select(getStore),
  //     concatMap((store) =>
  //       this.http
  //         .post(
  //           `${this.base_url}/duplicate`,
  //           JSON.stringify({ ...patient, store })
  //         )
  //         .pipe(map((res: AuthResponse<any>) => res))
  //     )
  //   );
  // }

  // updatedCopiedPatient(
  //   patient: Partial<Patient>
  // ): Observable<AuthResponse<any>> {
  //   return this.store.pipe(
  //     select(getStore),
  //     concatMap((store) =>
  //       this.http
  //         .put(
  //           `${this.base_url}/duplicate`,
  //           JSON.stringify({ ...patient, store })
  //         )
  //         .pipe(map((res: AuthResponse<any>) => res))
  //     )
  //   );
  // }

  // updatePatientDiscount(patientId: any, discount: Object): Observable<Patient> {
  //   return this.http
  //     .put<Patient>(
  //       `${this.base_url}/patients/${patientId}/updateDiscount`,
  //       JSON.stringify(discount)
  //     );
  // }

  // updateFavoriteLines(
  //   patientId: any,
  //   favoriteLines: Object
  // ): Observable<Patient> {
  //   return this.http
  //     .put(
  //       `${this.base_url}/patients/${patientId}`,
  //       JSON.stringify(favoriteLines)
  //     )
  //     .pipe(map((res: AuthResponse<Patient>) => res.patient));
  // }
}
