import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./auth.service";
import { Observable } from "rxjs";
import { Params } from "@angular/router";
import { Pagination } from "src/app/types/pagination";
import { Prescription } from "../models/prescription";

@Injectable({
  providedIn: "root",
})
export class PrescriptionsService {
  private base_url = `${environment.apiDomain}`;

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  createPrescription(
    prescription: Partial<Prescription>
  ): Observable<Prescription> {
    return this.http.post<Prescription>(
      `${this.base_url}/prescriptions`,
      prescription
    );
  }

  getPrescriptions(params?: Params): Observable<Pagination<Prescription>> {
    return this.http.get<Pagination<Prescription>>(
      `${this.base_url}/prescriptions`,
      {
        params,
      }
    );
  }

  getPatientPrescriptions(
    patientId: string,
    params?: Params
  ): Observable<Pagination<Prescription>> {
    return this.http.get<Pagination<Prescription>>(
      `${this.base_url}/patients/${patientId}/prescriptions`,
      {
        params,
      }
    );
  }

  updatePrescription(
    prescription: Partial<Prescription>
  ): Observable<Prescription> {
    return this.http.put<Prescription>(
      `${this.base_url}/prescriptions/${prescription._id}`,
      prescription
    );
  }
}
