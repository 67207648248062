import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, of, Subject, Subscription } from "rxjs";
import { ConfigService } from "src/app/core/services/config.service";
import { Options } from "src/app/types/select";
@Component({
  selector: "app-multi-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @Input() type: "findUs" | "vendor" | "discount" | "prefix" | "store" | "role";
  @Input() values: Array<string | number>;
  @Input() label: string;
  @Input() multiple: boolean = true;
  @Input() class: string = "col-12";
  @Output() selected = new EventEmitter<Array<any>>();
  select$: Subscription;
  destroy$ = new Subject<void>();
  selectionEnabled = false;
  id = new Date().getTime().toString();
  options$: Observable<Options[]> = of([]);

  constructor(
    public cs: ConfigService,
    private ms: BsModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log(this);
    if (this.type) {
      this.options$ = this.cs[`${this.type}Options`]();
    }
  }

  ngAfterViewChecked() {
    if (
      this.values &&
      this.ngSelect?.itemsList.items.length &&
      !this.selectionEnabled
    ) {
      this.populateValues();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  populateValues() {
    const unselectedItems = this.ngSelect.itemsList.items.filter((item) =>
      this.values.includes(item.value?.value)
    );
    console.log(unselectedItems);
    unselectedItems.forEach((item) => {
      this.ngSelect.select(item);
    });
    this.selectionEnabled = true;
    this.ngSelect.changeEvent.subscribe((event) => {
      this.selected.emit(event);
    });
  }

  /**
   * Compare the currently selected values with the original values
   * @param event ng-select change event [{id: number, label: string, value: number}]
   * @returns void and output the new values
   */
  onSelectChange(event?: Array<Options<number | string>>) {
    if (!event || !this.selectionEnabled) return;
    const selectedValues: Array<any> = event.map((item) => item.value);
    const existingValues =
      this.values?.filter((value) => selectedValues.includes(value)) ?? [];

    const nextValues: Array<any> = event.filter(
      (item) => !existingValues.includes(item.value)
    );
    const values = [...existingValues, ...nextValues];
    this.selected.emit(values);
  }
}
