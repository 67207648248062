import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 66,
    label: "Main",
    icon: "bx-menu-alt-right",
    isCollapsed: false,
    subItems: [
      {
        id: 888,
        label: "New Patient Order",
        link: "/patient-order",
        parentId: 66,
      },
      {
        id: 0,
        label: "Patients",
        link: "/patients",
        parentId: 66,
      },
      {
        id: 1,
        label: "Orders",
        link: "/orders",
        parentId: 66,
      },
      {
        id: 69,
        label: "Vendors",
        link: "/vendors",
        parentId: 66,
      },
      {
        id: 70,
        label: "Eye Exams",
        link: "/eye-exams",
        parentId: 66,
      },
      {
        id: 72,
        label: "Price Check",
        link: "/price-check",
        parentId: 66,
      },
    ],
  },
  {
    id: 0,
    label: "Admin",
    icon: "bx-trending-up",
    isCollapsed: false,
    subItems: [
      // {
      //   id: 2,
      //   label: "Import Customers",
      //   link: "/import-customers",
      //   parentId: 1,
      // },
      {
        id: 3,
        label: "Employees",
        link: "/employees",
        parentId: 1,
      },
      {
        id: 4,
        label: "Favorites",
        link: "/favorite-lines",
        parentId: 1,
      },
      {
        id: 5,
        label: "Payables",
        link: "/payables",
        parentId: 1,
      },
      {
        id: 6,
        label: "Receivables",
        link: "/receivables",
        parentId: 1,
      },
      {
        id: 7,
        label: "Reports",
        link: "/reports",
        parentId: 1,
      },
      {
        id: 8,
        label: "Fix Orders",
        link: "/fix-orders",
        parentId: 1,
      },
      {
        id: 9,
        label: "Fix Duplicates",
        link: "/fix-duplicates",
        parentId: 1,
      },
      {
        id: 10,
        label: "Fix Duplicates (No Size)",
        link: "/fix-duplicates-no-size",
        parentId: 1,
      },
      {
        id: 1,
        label: "Shopify",
        link: "/shopify",
        parentId: 1,
      },
    ],
  },

  {
    id: 67,
    label: "Managed Board",
    isCollapsed: false,
    icon: "bx-file",
    subItems: [
      {
        id: 53,
        label: "The Stack",
        icon: "bx-right-indent",
        link: "/stack",
      },
      {
        id: 54,
        label: "Inventory",
        icon: "mdi mdi-glasses",
        link: "/inventory",
      },
      {
        id: 55,
        label: "Awaiting Frames",
        icon: "mdi mdi-truck-outline",
        link: "/awaiting-frames",
      },
      {
        id: 56,
        label: "Returns",
        icon: "mdi mdi-replay",
        link: "/returns",
      },
      {
        id: 57,
        label: "Borrowing",
        icon: "mdi mdi-rotate-right",
        link: "/borrowing",
      },
      {
        id: 58,
        label: "Invoice",
        icon: "bx-receipt",
        link: "/invoice",
      },
      {
        id: 59,
        label: "Credit",
        icon: "mdi mdi-credit-card-clock-outline",
        link: "/credit",
      },
    ],
  },
  {
    id: 66,
    label: "Analytics",
    icon: "bx-data",
    isCollapsed: false,
    subItems: [
      {
        id: 69,
        label: "Dashboard",
        link: "/dashboard",
        parentId: 66,
      },
      {
        id: 70,
        label: "Stores",
        link: "/dashboard/stores",
        parentId: 66,
      },
      {
        id: 72,
        label: "Vendors",
        link: "/dashboard/vendors",
        parentId: 66,
      },
    ],
  },
];
