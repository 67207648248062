import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationState } from "./store/Authentication/authentication.reducer";
import { debounceTime, Observable, take, tap } from "rxjs";
import { getAuthentication } from "./store/Authentication/authentication-selector";
import { loginSuccess } from "./store/Authentication/authentication.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  authenticated$: Observable<boolean>;

  constructor(private store: Store<AuthenticationState>) {}
  ngOnInit() {
    this.authenticated$ = this.store.select(getAuthentication);
    // Check if user is logged in on page refresh
    // And a logout action has not been dispatched
    // to clear the local storage
    this.authenticated$
      .pipe(
        debounceTime(200),
        take(1),
        tap((isAuthenticated) => {
          console.log("Test auth");
          if (!isAuthenticated) {
            this.loginFromStorage();
          }
        })
      )
      .subscribe();
  }

  loginFromStorage() {
    const storedAuth = localStorage.getItem("auth");
    const authState = storedAuth && JSON.parse(storedAuth);
    if (authState) {
      this.store.dispatch(loginSuccess(authState));
    }
  }
}
