import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { getControl, getLogFormGroup } from "./form";
import { getOrderFormGroup } from "./order";
import { StockOrder, StockOrderPayment } from "src/app/core/models";

export const getPayableFormGroup = (payable?: StockOrder) => {
  return new FormGroup({
    _id: new FormControl(payable?._id),
    amountDue: new FormControl(payable?.amountDue),
    archived: new FormControl(payable?.archived),
    borrowingStore: new FormControl(payable?.borrowingStore),
    cost: new FormControl(payable?.cost),
    credit: new FormControl(payable?.credit),
    date: new FormControl(payable?.date),
    dateAdded: new FormControl(payable?.dateAdded),
    datePaid: new FormControl(payable?.datePaid),
    dateReceived: new FormControl(payable?.dateReceived),
    dateUpdated: new FormControl(payable?.dateUpdated),
    groupId: new FormControl(payable?.groupId),
    invoiceNumber: new FormControl(payable?.invoiceNumber),
    log: new FormArray((payable?.log ?? []).map(getLogFormGroup)),
    notes: new FormControl(payable?.notes),
    orders: new FormControl(payable?.orders),
    partialPayment: new FormControl(payable?.partialPayment),
    partialPaymentDate: new FormControl(payable?.partialPaymentDate),
    payments: new FormArray((payable?.payments ?? []).map(getPaymentFormGroup)),
    products: new FormControl(payable?.products),
    proposedCredit: new FormControl(payable?.proposedCredit),
        raNumber: new FormControl(payable?.raNumber),
    shipping: new FormControl(payable?.shipping),
    shippingMethod: new FormControl(payable?.shippingMethod),
    status: new FormControl(payable?.status),
    storeId: new FormControl(payable?.storeId),
    tax: new FormControl(payable?.tax),
    total: new FormControl(payable?.total),
    totalCredit: new FormControl(payable?.totalCredit),
    trackingNumber: new FormControl(payable?.trackingNumber),
    type: new FormControl(payable?.type),
    user: new FormControl(payable?.user),
    vendor: new FormControl(payable?.vendor),
  });
};

export const getPaymentFormGroup = (payment?: StockOrderPayment) => {
  return new FormGroup({
    id: new FormControl(payment?._id),
    date: new FormControl(payment?.date),
    groupId: new FormControl(payment?.groupId),
    amount: new FormControl(payment?.amount),
  });
};
