<div class="container-fluid">
    <!-- start page title -->
  
    <app-page-title
      title="Invoice"
      [breadcrumbItems]="breadCrumbs"
    ></app-page-title>
  
    <!-- end page title -->
  
    <div  *ngIf="order" class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 *ngIf="order?.invoiceNumber" class="float-end font-size-16">
                <div class="d-flex flex-column align-items-end">
                  <span>Invoice # {{ order.invoiceNumber }}</span>
                  <small class="text-muted">{{ storeName }}</small>
                </div>
              </h4>
              <div class="auth-logo mb-4">
                <a routerLink="/" class="logo logo-light">
                  <span>
                    <img src="assets/images/logo-redraw.png" alt="" height="17" />
                  </span>
                </a>
        
                <a routerLink="/" class="logo logo-dark">
    
                  <span>
                    <img src="assets/images/logo-redraw-white.png" alt="" height="19" />
                  </span>
                </a>
              </div>
            </div>
            <hr />
            <div *ngIf="patient" class="row">
              <div class="col-6">
                <strong>{{ patient.firstName }} {{ patient.lastName }}</strong>
                <address *ngIf="patient.address">
                    {{ patient.address }}<br>
                    {{ patient.city }}, {{ patient.state }} {{ patient.zip }}<br>
                    {{ patient.phone }}
                </address>
                <email *ngIf="patient.email">{{ patient.email }}</email>
              </div>
            </div>
            <div class="py-2 mt-3">
              <h3 class="font-size-15 fw-bold">Order Summary</h3>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Model</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th>Tax</th>
                    <th>Shipping</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
  
                    @for (_product of order._products; track _product._id; let i = $index) {
                      <tr>
                        <td>@if(_product._images.length > 0) {
                          <a (click)="openLightbox(order, i)">
                            <img
                              [src]="_product._images[0].url"
                              alt="Product Image"
                              style="width: 100px"
                            />
                          </a>
                          }</td>
                        <td>{{ _product.model }}</td>
                        <td>{{ _product.color }}</td>
                        <td>{{ _product.size }}</td>
                        <td>{{ _product.type }}</td>
                        <td>{{ _product.tax || 0 | currency }}</td>
                        <td>{{ _product.shipping || 0 | currency }}</td>
                        <td>{{ _product.cost | currency }}</td>
                      </tr>
                      }
  
                  <tr>
                    <th colspan="5" class="text-end border-0">Subtotal</th>
                    <th class="border-0">{{ order.tax || 0 | currency }}</th>
                    <th class="border-0">
                      {{ order.shipping || 0 | currency }}
                    </th>
                    <th class="border-0">{{ cost | currency }}</th>
                  </tr>
                  <tr>
                    <th colspan="7" class="text-end border-0">Total</th>
                    <th class="border-0">
                      <h4 class="m-0">{{ order.total | currency }}</h4>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-print-none">
              <div class="float-end">
                <a *ngIf="patient?.email" href="javascript:void(0)" class="btn btn-primary me-3"
                  ><i class="fa fa-envelope"></i
                ></a>
                <a href="javascript:window.print()" class="btn btn-success"
                  ><i class="fa fa-print"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
  