import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ChartComponent } from "ng-apexcharts";
import { defaultMonthlyChart, defaultTimeChart } from "./data";
import {
  ChartType,
  ChatMessage,
  RevenueAggregate,
  RevenueResponse,
} from "./saas.model";
import { ConfigService } from "../../../core/services/config.service";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { environment } from "src/environments/environment";
import { catchError, concatMap, EMPTY, Subject, tap } from "rxjs";
import { CurrencyPipe, DatePipe } from "@angular/common";

import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  PointElement,
  LineElement,
  BarController,
  DoughnutController,
  ArcElement,
  PolarAreaController,
  RadialLinearScale,
  PieController,
  RadarController
);

@Component({
  selector: "app-saas",
  templateUrl: "./saas.component.html",
  styleUrls: ["./saas.component.scss"],
})
/**
 * Saas-dashboard component
 */
export class SaasComponent implements OnInit {
  @ViewChild(ChartComponent) apexChart: ChartComponent;

  // bread crumb items
  breadCrumbItems: Array<{}>;
  earningLineChart: ChartType;
  salesAnalyticsDonutChart: ChartType;
  ChatData: ChatMessage[];
  sassEarning: any;
  sassTopSelling: any;
  formData: UntypedFormGroup;

  // Form submit
  chatSubmit: boolean;
  lineChart: any = defaultTimeChart;
  monthChart: any = defaultMonthlyChart;

  dp = new DatePipe("en-us");
  cp = new CurrencyPipe("en-us");
  month: number = new Date().getMonth() + 1;
  month$ = new Subject<number>();
  year: number = new Date().getFullYear();
  type: string = "year";
  loading$: boolean = true;
  endMonth: number = new Date().getMonth() + 1;
  endYear: number = new Date().getFullYear() - 1;
  data: any;
  monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
    { label: "Clear", value: null },
  ];

  yearOptions = [
    { label: "2025", value: 2025 },
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 2020 },
    { label: "2019", value: 2019 },
    { label: "Clear", value: null },
  ];

  typeOptions = [
    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
    { label: "Vendor", value: "vendor" },
    { label: "Model", value: "model" },
    { label: "Store", value: "store" },
  ];

  selectedMonth: (typeof this.monthOptions)[0];
  selectedYear: (typeof this.yearOptions)[0];
  selectedType: (typeof this.typeOptions)[0];

  showAll = true;
  monthData: RevenueAggregate;
  prevMonthData: RevenueAggregate;

  get compareMonth() {
    return this.month === 1 ? 12 : this.month - 1;
  }
  get compareYear() {
    return this.month === 1 ? this.year - 1 : this.year;
  }

  get daysLeft() {
    const activeDate = new Date(this.year, this.month + 1, 0); // last day of the month
    const d = new Date();
    if (activeDate < d) return "No";
    return (
      new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() - d.getDate()
    );
  }

  get maxDate() {
    return new Date(this.year, this.month + 1, -1);
  }

  get minDate() {
    const m = this.type === "month" ? this.month : this.month + 1;
    return new Date(this.year, m, 1);
  }

  get queryParams() {
    return {
      group: this.type,
      col: "year",
      dir: "desc",
      ...(!this.showAll && {
        start: this.dp.transform(this.maxDate, "yyyy-MM-dd"),
        end: this.dp.transform(this.minDate, "yyyy-MM-dd"),
      }),
    };
  }

  get monthPercentage() {
    const { total: mTotal } = this.monthData;
    const { total: pTotal } = this.prevMonthData;
    if (!pTotal && mTotal) return 100;
    const value = mTotal / pTotal;
    const fixedValue = value.toFixed(2);
    const fixedValueNumber = Number(fixedValue);
    return Math.abs(1 - fixedValueNumber);
  }

  get chartMonthPercentage() {
    let p = 0;
    if (this.monthData.total > this.prevMonthData.total) {
      p = this.monthPercentage + 1;
    } else {
      p = 1 -this.monthPercentage;
    }
    return Number((p * 100).toFixed(2));
  }

  constructor(
    public formBuilder: UntypedFormBuilder,
    private configService: ConfigService,
    public store: Store,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    console.log(this);
    this.getSalesData();
  }

  getSalesData() {
    this.loading$ = true;
    this.store
      .select(getStore)
      .pipe(
        concatMap((store) =>
          this.http
            .get<RevenueResponse>(
              `${environment.apiDomain}/orders/analytics/sales`
            )
            .pipe(
              tap((data) => {
                console.log(data);
                this.data = data;
                this.setChartData();
              })
            )
        ),
        catchError((error) => {
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: "Home", active: true }];
  }

  setChartData() {
    console.log(this.data);

    const { year, month, store, model, vendor } = this.data ?? {};
    const totalRevenue = year.reduce((acc, item) => acc + item.total, 0);

    console.log(model);
    if (model?.length) {
      const stockRetail = this.data.vendor.reduce(
        (acc, item) => acc + (item.vendorStockRetail ?? 0),
        0
      );
      const stockTotal = this.data.vendor.reduce(
        (acc, item) => acc + (item.vendorStock ?? 0),
        0
      );
      console.log(stockRetail, stockTotal);
      this.sassTopSelling = [
        {
          title: "Inventory Value by Vendor",
          amount: this.cp.transform(stockRetail),
          revenue: stockRetail,
          list: this.data.vendor.slice(0, 10).map((d) => ({
            name: d.vendor,
            text: `Stock: ${d.vendorStock}`,
            sales: d.vendorStockRetail,
            chartVariant:
              "#" + Math.floor(Math.random() * 16777215).toString(16),
          })),
        },
      ];
    }

    if (month?.length) {
      this.sassEarning = [
        {
          amount: this.cp.transform(month[0].total),
          month: `${this.dp.transform(
            new Date(month[1]._id.year, month[1]._id.month),
            "MMMM"
          )} ${month[1]._id.year}`,
          name: `${this.dp.transform(
            new Date(month[0]._id.year, month[0]._id.month),
            "MMMM"
          )} Revenue`,
          previousamount: this.cp.transform(month[1].total),
          revenue: month[0].total / totalRevenue,
          series: { data: month.map((d) => d.total) },
        },
      ];

      this.lineChart.data.datasets = this.getMonthChartData();
    }

    this.cdr.detectChanges();
    this.monthData =
      month?.find(
        (d) => d._id?.month === this.month && d._id?.year === this.year
      ) ?? month[month.length - 1];
    this.prevMonthData =
      month?.find(
        (d) =>
          d._id?.month === this.compareMonth && d._id?.year === this.compareYear
      ) ?? month[month.length - 2];

    this.monthChart.series = [this.chartMonthPercentage];
    this.loading$ = false;
  }

  hexToRgba(hex: string, alpha: number) {
    // Remove the '#' if it exists
    hex = hex.replace("#", "");

    // Handle the case where the hex code is shortened (e.g., #abc)
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    // Parse the hex values for red, green, and blue
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // If alpha is not provided, default to 1 (fully opaque)
    alpha = alpha === undefined ? 1 : alpha;

    // Return the rgba value as a string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  getMonthChartData() {
    const { month } = this.data;
    const months = new Array(12).fill(0).map((_, i) => i);
    const years = Array.from(new Set(month.map((d) => d._id.year)))
      .filter(Boolean)
      .sort();
    const yearsGrouped = month.reduce((acc, item) => {
      // console.log(item);
      if (!item._id.year) return acc;
      if (!acc[item._id.year]) {
        acc[item._id.year] = [];
      }
      acc[item._id.year].push(item);
      return acc;
    }, {});

    const filledGroups = Object.entries(yearsGrouped).reduce(
      (acc: Record<number, Array<any>>, item: any) => {
        const [year, data] = item;
        const series = data;
        months.forEach((m) => {
          const idx = m + 1;
          if (!series.find((i) => i._id.month === idx)) {
            series.push({
              _id: { month: idx, year },
              cost: 0,
              key: `${year}-${idx}`,
              orders: 0,
              retail: 0,
              revenue: 0,
              shipping: 0,
              tax: 0,
              total: 0,
            });
          }
        });

        return {
          ...acc,
          [year]: series.sort((a, b) => a._id.month - b._id.month),
        };
      },
      {}
    );
    return Object.entries(filledGroups).map(
      ([year, data]: [string, Array<any>]) => {
        const hex = "#" + Math.floor(Math.random() * 16777215).toString(16);
        // const hex = "#9987D5";
        const rgba = this.hexToRgba(hex, 0.6);
        return {
          label: `${year}`,
          fill: false,
          tension: 0.5,
          backgroundColor: `${rgba}`,
          borderColor: `${hex}`,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: `${hex}`,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: `${hex}`,
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data.map((d) => Number(d.revenue.toFixed(2))),
        };
      }
    );
  }

  selectMonth(v: number | string) {
    const value = Number(v);
    this.selectedMonth = this.monthOptions.find((d) => d.value === value);
    this.month = value
    this.monthData = this.data.month?.find(
      (d) => d._id.month === this.month && d._id.year === this.year
    );

    this.prevMonthData = this.data.month.find(
      (d) =>
        d._id.month === this.compareMonth && d._id.year === this.compareYear
    );
    this.monthChart.series = [this.chartMonthPercentage];
  }

  selectYear(v: number | string) {
    const value = Number(v);
    this.selectedYear = this.yearOptions.find((d) => d.value === value);
    this.year = value;
    this.monthData = this.data.month?.find(
      (d) => d._id.month === this.month && d._id.year === this.year
    );
    this.prevMonthData = this.data.month.find(
      (d) =>
        d._id.month === this.compareMonth && d._id.year === this.compareYear
    );
    console.log(this.monthData, this.prevMonthData);

    this.monthChart.series = [this.chartMonthPercentage];
  }

  selectType(value: any) {
    this.selectedType = value;
    this.type = value.value;
  }
}
