<ng-container>
  <div class="form-floating form-floating-ng-select mb-3">
    <ng-select
      #ngSelect
      [multiple]="true"
      [id]="id"
      bindLabel="label"
      bindValue="value"
      class="form-select"
      [items]="options$ | async"
    >
    </ng-select>
    <label [for]="id">{{ label || "Select" }}</label>
  </div>
</ng-container>
