import { DatePipe } from "@angular/common";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import {
  catchError,
  debounceTime,
  EMPTY,
  filter,
  Observable,
  of,
  Subject,
  Subscription,
  take,
  takeUntil,
  tap,
  timer,
} from "rxjs";
import { ConfigService } from "src/app/core/services/config.service";
import { PatientService } from "src/app/core/services/patient.service";
import { Options } from "src/app/types/select";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { environment } from "src/environments/environment";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ImageCropperComponent } from "ngx-image-cropper";
import { ImageCropperModalComponent } from "../../image-cropper/image-cropper-modal.component";
import { Image } from "src/app/core/models/image";
import { ActivatedRoute, Router } from "@angular/router";
import { getControl } from "src/app/shared/utils/forms/form";
import { getPatientFormGroup } from "src/app/shared/utils/forms/patient";
import { Patient } from "src/app/core/models";
import { OrderComponent } from "../../orders/order/order.component";

@Component({
  selector: "app-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.css"],
})
export class PatientComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @ViewChild("patientNotes") ckEditor: CKEditorComponent;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild("userImage") userImage: HTMLImageElement;
  @ViewChild("content") content: TemplateRef<any>;
  @Output() selectPatient = new EventEmitter<Patient>();

  @Input() patientRef: Patient;
  @Input() child: boolean = false;

  patientId: string;
  modal: boolean = false;
  modalId: string;
  patient: Patient;
  patientImage: Image;
  // CkEditor variables
  editor = ClassicEditor;
  editorConfig = {
    licenseKey: environment.editorLicenseKey,
    ai: {
      enabled: true,
      openAI: {
        requestHeaders: {
          Authorization: `Bearer ${environment.openAIKey}`,
        },
      },
    },
    toolbar: [
      "aiCommands",
      "aiAssistant",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
    ],
  };

  findUsOptions$: Observable<Options[]> = of([]);
  vendorOptions$: Observable<Options[]> = of([]);
  discountOptions$: Observable<Options[]> = of([]);
  prefixOptions$: Observable<Options[]> = of([]);

  isSelectActive = false;
  selectActive$ = new Subject<boolean>();
  selectionEnabled = false;

  dp = new DatePipe("en-US");

  patientForm = getPatientFormGroup();

  editor$: Subscription;
  select$: Subscription;
  notesReady$ = new Subject<void>();
  destroy$: Subject<void> = new Subject<void>();
  modalRef: BsModalRef;

  // Helpers to reduce code in the template
  private get selectedFavorites() {
    return this.patientForm.value.favoriteLines ?? [];
  }

  private get log() {
    return this.patient?.patientNotes ?? "";
  }

  get profileImage() {
    return this.patientImage?.image ?? "assets/images/users/user-dummy-img.jpg";
  }

  constructor(
    public cs: ConfigService,
    protected ps: PatientService,
    private ms: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.findUsOptions$ = this.cs.findUsOptions();
    this.vendorOptions$ = this.cs.vendorOptions();
    this.discountOptions$ = this.cs.discountOptions();
    this.prefixOptions$ = this.cs.prefixOptions();
    this.route.params.subscribe((params) => {
      const patientId = params.patientId ?? this.patientId;
      if (patientId) {
        this.getPatient(patientId);
      } else {
        this.selectionEnabled = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.patientRef) {
      this.setPatient(this.patientRef);
    }
  }

  // TODO: Break out ckEditor and NgSelect into reusable components

  ngAfterViewInit() {
    this.notesReady$.pipe(take(2)).subscribe(() => {
      const notes = this.patient?.patientNotes;
      if (!notes) return;
      this.ckEditor.editorInstance.data.set(notes ?? "");
    });

    timer(100, 100)
      .pipe(
        takeUntil(this.notesReady$),
        filter(() => !!this.patient && !!this.ckEditor?.editorInstance)
      )
      .subscribe(() => {
        this.notesReady$.next();
      });
  }

  ngAfterViewChecked() {
    if (this.ngSelect?.itemsList.items.length && !this.select$) {
      this.patient && this.populateFavorites();
      this.select$ = this.ngSelect.changeEvent
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          console.log(event);
          this.onSelectChange(event);
        });
    }

    if (this.ckEditor && !this.editor$) {
      // Patch the editor changes to the log control of type 'patient'
      // only one exists
      this.editor$ = this.ckEditor.change
        .pipe(debounceTime(250), takeUntil(this.destroy$))
        .subscribe((event) => {
          const editorContent = this.ckEditor.editorInstance.data.get();
          const logControl = getControl<FormControl>(
            this.patientForm,
            "patientNotes"
          );
          logControl?.patchValue(editorContent);
          if (this.log !== editorContent) {
            logControl?.markAsDirty();
            this.patientForm.markAsDirty();
          } else {
            logControl?.markAsPristine();
          }
        });
    }
  }

  detectOptionChanges(options: Array<Options>) {
    this.cdr.detectChanges();
    return options;
  }

  setPatient(patient: Patient) {
    this.patient = patient;
    this.patientImage = patient._images.pop();
    this.patientForm = getPatientFormGroup(this.patient);
    this.patientForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      console.log(value);
    });
    this.ngSelect && this.populateFavorites();
    this.ckEditor && this.notesReady$.next();
  }

  getPatient(patientId: string) {
    if (!patientId) return;
    this.ps
      .getPatient(patientId)
      .pipe(
        take(1),
        tap((patient) => {
          this.setPatient(patient);
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  openImageCropper() {
    const modalRef = this.ms.show(ImageCropperModalComponent, {
      id: `${this.patient._id}-image-cropper`,
      class: "modal-lg",
      ignoreBackdropClick: true,
      initialState: {
        refId: this.patient._id,
        type: "patient",
        modalId: `${this.patient._id}-image-cropper`,
      },
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason !== "cancel") this.getPatient(this.patient._id);
      modalRef.hide();
    });
  }

  openOrderModal() {
    const modalRef = this.ms.show(OrderComponent, {
      id: `${this.patient._id}-order`,
      initialState: {
        patientId: this.patientId ?? this.patient?._id,
        modal: true,
        modalId: `${this.patientId ?? this.patient?._id}-order`,
      },
      class: "modal-xl",
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason === "save" && this.patient?._id)
        this.getPatient(this.patient._id);
    });
  }

  /**
   * Compare the currently selected favorites with the patient's favorites
   * and update the patient form with the new favorites
   * @param event ng-select change event [{id: number, label: string, value: number}]
   * @returns void
   */
  onSelectChange(event?: Array<Options<string>>) {
    console.log(event);
    if (!event) return;
    const favoritesFormArray = getControl<FormArray>(
      this.patientForm,
      "favoriteLines"
    );
    favoritesFormArray?.clear();
    favoritesFormArray?.markAsPristine();

    const selectedFavorites = event.map((item) => item.value) as Array<any>;
    const existingFavorites =
      this.patient?.favoriteLines?.filter((favorite) =>
        selectedFavorites.includes(favorite)
      ) ?? [];

    const nextFavorites: Array<string> = event
      .filter((item) => !existingFavorites.includes(item.value))
      .map((item) => item.value);
    const favorites = [...existingFavorites, ...nextFavorites];

    favorites?.forEach((favorite) => {
      const favoriteControl = new FormControl(favorite);
      if (!existingFavorites.includes(favorite)) favoriteControl.markAsDirty();
      favoritesFormArray.push(favoriteControl);
    });

    console.log(favoritesFormArray.value);

    this.patientForm.markAsDirty();
    this.cdr.detectChanges();
  }

  populateFavorites() {
    console.log("populate favorite lines");
    const unselectedItems = this.ngSelect.itemsList.items.filter((item) =>
      this.patient?.favoriteLines?.includes(item.value?.value)
    );
    console.log(unselectedItems);
    unselectedItems.forEach((item) => {
      this.ngSelect.select(item);
    });
    this.selectionEnabled = true;
    this.patientForm.markAsUntouched();
    this.patientForm.markAsPristine();
  }

  savePatient() {
    const payload = this.patientForm.getRawValue();
    const obs$ = payload._id
      ? this.ps.updatePatient(payload)
      : this.ps.addPatient(payload);
    obs$
      .pipe(
        take(1),
        tap((patient) => {
          if (this.child) {
            this.selectPatient.emit(patient);
            return;
          }
          if (!this.patient) {
            this.router.navigate(["/patients", patient._id]);
            return;
          }
          if (this.modal) {
            this.closeModal("save");
          } else {
            this.setPatient(patient);
          }
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  closeModal(reason: "cancel" | "save" = "cancel") {
    this.ms.setDismissReason(reason);
    this.ms.hide(this.patient._id);
  }
}
