<div [class.container-fluid]="!child">
  <app-page-title
    *ngIf="!child"
    title="Patients"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div [class.card]="!child">
      <div [class.card-body]="!child">
        <div [class.row]="!child">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div
                  *ngIf="!child"
                  class="dataTables_length"
                  id="tickets-table_length"
                >
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchOrders
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <ng-container *ngIf="!child">
                      <th>Home Phone</th>
                      <th>Work Phone</th>
                      <th>Cell</th>
                      <th>Store Credit</th>
                      <th>Occupation</th>
                    </ng-container>
                    <ng-container *ngIf="child">
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>DOB</th>
                      <th>Select</th>
                    </ng-container>
                    <!-- <th>Edit</th> -->
                  </tr>
                </thead>
                <tbody>
                  @if(loading$) {
                  <tr>
                    <td align="center" colspan="8">
                      <div
                        style="
                          width: 100%;
                          min-height: 200px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 15px;
                        "
                      >
                        <div
                          role="status"
                          class="spinner-border text-success m-1"
                          style="width: 75px; height: 75px"
                        >
                          <span class="sr-only">Loading Payables...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  } @else { @if(noResults || noSearch) {
                  <tr>
                    <td colspan="10">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                        style="width: 100%; min-height: 100px; opacity: 0.3"
                      >
                        <h4 class="mt-3">
                          {{
                            noSearch
                              ? "Search for a patient"
                              : "No patients found"
                          }}
                          {{
                            search && !noSearch
                              ? "for the search " + search
                              : ""
                          }}
                        </h4>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (patient of patients; track patient) {
                  <tr>
                    <td scope="row">
                      <a [routerLink]="['/patients', patient._id]">
                        {{ patient.lastName }}, {{ patient.firstName }}
                      </a>
                    </td>
                    <td>{{ patient.email }}</td>
                    <ng-container *ngIf="!child">
                      <td>{{ patient.phone | phone }}</td>
                      <td>{{ patient.phone2 | phone }}</td>
                      <td>{{ patient.cell | phone }}</td>
                      <td align="right">
                        {{ patient.storeCredit | currency }}
                      </td>
                      <td>{{ patient.occupation }}</td>
                    </ng-container>
                    <ng-container *ngIf="child">
                      <td>{{ patient.address }}</td>
                      <td>{{ patient.city }}</td>
                      <td>{{ patient.state }}</td>
                      <td>{{ patient.dob | date }}</td>
                      <td>
                        <i
                          class="fas fa-plus"
                          (click)="selectPatient.emit(patient)"
                        ></i>
                      </td>
                    </ng-container>
                  </tr>
                  }}}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              *ngIf="pagination.total"
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  patients
                </div>
              </div>
              <!-- Pagination -->
              <div
                *ngIf="pagination.total > pagination.take"
                class="col-sm-12 col-md-5"
              >
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [totalItems]="pagination.total"
                    [maxSize]="4"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!child" class="row">
    <div class="col-12">
      <button class="btn btn-primary" [routerLink]="['/patients/new']">
        Add Patient
      </button>
    </div>
  </div>
</div>
