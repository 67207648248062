import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { concatMap, Observable } from "rxjs";
import { Options } from "../../types/select";
import { environment } from "src/environments/environment";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { select, Store } from "@ngrx/store";
import { getStore } from "src/app/store/Authentication/authentication-selector";
@Injectable({
  providedIn: "root",
})
export class ConfigService {
  URL = "assets/dashboard.json";

  constructor(
    private http: HttpClient,
    protected store: Store<AuthenticationState>
  ) {}
  getConfig(): Observable<any> {
    return this.http.get<any>(`${this.URL}`);
  }

  findUsOptions(): Observable<Array<Options>> {
    return this.http.get<Array<Options>>(
      `${environment.apiDomain}/options/find-us`
    );
  }

  discountOptions(): Observable<Array<Options>> {
    return this.http.get<Array<Options>>(
      `${environment.apiDomain}/options/discount`
    );
  }

  vendorOptions(): Observable<Array<Options>> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Array<Options>>(
          `${environment.apiDomain}/options/vendors/${store}`
        )
      )
    );
  }

  favoriteOptions(): Observable<Array<Options>> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Array<Options>>(
          `${environment.apiDomain}/options/favorites/${store}`
        )
      )
    );
  }

  prefixOptions(): Observable<Array<Options>> {
    return this.http.get<Array<Options>>(
      `${environment.apiDomain}/options/prefix`
    );
  }

  storeOptions() {
    return this.http.get<Array<Options>>(
      `${environment.apiDomain}/options/stores`
    );
  }

  roleOptions() {
    return this.http.get<Array<Options>>(
      `${environment.apiDomain}/options/roles`
    );
  }
}
