import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 66,
    isLayout: true,
  },
  {
    id: 67,
    label: "Main",
    isTitle: true,
  },
  {
    id: 888,
    label: "New Patient Order",
    icon: 'bxs-user-circle',
    link: "/patient-order",
    parentId: 66,
  },
  {
    id: 69,
    label: "Vendors",
    icon: "mdi mdi-account-search",
    link: "/vendors",
  },
  {
    id: 70,
    label: "Eye Exams",
    icon: "mdi mdi-eye-outline",
    link: "/eye-exams",
  },
  {
    id: 72,
    label: "Price Check",
    icon: "bx-purchase-tag",
    link: "/price-check",
  },

  // {
  //   id: 235,
  //   label: "Stores",
  //   link: "/dashboard/stores",
  //   parentId: 233,
  //   icon: "bx-store",
  // },
  // {
  //   id: 236,
  //   label: "Vendors",
  //   link: "/dashboard/vendors",
  //   parentId: 233,
  //   icon: "bx-line-chart",
  // },
  {
    id: 0,
    label: "Admin",
    isTitle: true,
  },
  {
    id: 151,
    label: "Patients",
    link: "/patients",
    icon: "bx-search-alt",
  },
  {
    id: 151,
    label: "Orders",
    link: "/orders",
    icon: "bx-search-alt",
  },
  // {
  //   id: 152,
  //   label: "Import Customers",
  //   link: "/import-customers",
  //   icon: "bx-import",
  // },
  {
    id: 153,
    label: "Employees",
    link: "/employees",
    icon: "bx-user",
  },
  {
    id: 154,
    label: "Favorites",
    link: "/favorite-lines",
    icon: "bx-star",
  },
  {
    id: 155,
    label: "Payables",
    link: "/payables",
    icon: "bx-money",
  },
  {
    id: 156,
    label: "Receivables",
    link: "/receivables",
    icon: "bx-trending-up",
  },
  // {
  //   id: 157,
  //   label: "Reports",
  //   link: "/reports",
  //   icon: "bx-notepad",
  // },
  // {
  //   id: 158,
  //   label: "Fix Orders",
  //   link: "/fix-orders",
  //   icon: "bx-book",
  // },
  // {
  //   id: 159,
  //   label: "Fix Duplicates",
  //   link: "/fix-duplicates",
  //   icon: "bx-copy",
  // },
  // {
  //   id: 160,
  //   label: "Fix Duplicates (No Size)",
  //   link: "/fix-duplicates-no-size",
  //   icon: "bx-wrench",
  // },
  // {
  //   id: 161,
  //   label: "Shopify",
  //   link: "/shopify",
  //   icon: "bx-data",
  // },

  // {
  //   id: 71,
  //   label: "Change Log",
  //   icon: "dripicons-blog",
  //   link: "/change-log",
  // },
  {
    id: 233,
    label: "Analytics",
    isTitle: true,
  },
  {
    id: 234,
    label: "Dashboard",
    link: "/dashboard",
    parentId: 233,
    icon: "bx-data",
  },
  {
    id: 235,
    label: "Stores",
    link: "/dashboard/stores",
    parentId: 233,
    icon: "bx-store",
  },
  {
    id: 236,
    label: "Vendors",
    link: "/dashboard/vendors",
    parentId: 233,
    icon: "bx-line-chart",
  },
  {
    id: 66,
    isLayout: true,
  },
  {
    id: 67,
    label: "Managed Board",
    isTitle: true,
  },
  {
    id: 53,
    label: "The Stack",
    icon: "bx-right-indent",
    link: "/stack",
  },
  {
    id: 54,
    label: "Inventory",
    icon: "mdi mdi-glasses",
    link: "/inventory",
  },
  {
    id: 55,
    label: "Awaiting Frames",
    icon: "mdi mdi-truck-outline",
    link: "/awaiting-frames",
  },
  {
    id: 56,
    label: "Returns",
    icon: "mdi mdi-replay",
    link: "/returns",
  },
  {
    id: 57,
    label: "Borrowing",
    icon: "mdi mdi-rotate-right",
    link: "/borrowing",
  },
  {
    id: 58,
    label: "Invoice",
    icon: "bx-receipt",
    link: "/invoice",
  },
  {
    id: 59,
    label: "Credit",
    icon: "mdi mdi-credit-card-clock-outline",
    link: "/credit",
  },
];
