import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { ImageCropperComponent } from "ngx-image-cropper";
import { catchError, EMPTY, Subject, tap } from "rxjs";
import { FileService } from "src/app/core/services/file.service";
import { Image } from "src/app/core/models/image";

@Component({
  selector: "app-image-cropper-modal",
  template: `<div class="modal-header">
      <h5 class="modal-title" id="myModalLabel">
        {{ activeImage ? "Crop Image" : "Upload Image" }}
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="onCancel()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row">
        @if(!activeImage) {
        <app-uploads
          (onFilesSelected)="onFilesSelected($event)"
          [standalone]="true"
          [refId]="refId"
          [type]="type"
        ></app-uploads>
        } @else {
        <app-image-cropper
          [img]="activeImage"
          (cropped)="onCropped($event)"
        ></app-image-cropper>
        }
      </div>
    </div>`,
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperModalComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild("selectedImage") fileInput: ElementRef;
  activeImage: Image;
  refId: string;
  type: string;
  selectedImage: File | null = null;
  base64Image: string | null = null;
  blobUrl: string | null = null;
  modalId: string;
  modalRef?: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  // file upload
  dropzoneConfig: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: true,
    previewsContainer: false,
  };

  image$ = new Subject<Image>();

  constructor(
    private ms: BsModalService,
    private fs: FileService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.modalId) {
      this.ms.setDismissReason("id");
      this.ms.hide(this.modalId);
    }
    if (!this.type) {
      this.ms.setDismissReason("type");
      this.ms.hide(this.modalId);
    }
  }

  onUploadSuccess(event: any) {}

  removeFile(event: any) {}

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  onCropped(event?: any) {
    this.image$.next(event);
    this.ms.setDismissReason(event._id);
    this.ms.hide(this.modalId);
  }

  onCancel() {
    if (!this.activeImage) {
      this.ms.hide(this.modalId);
      return;
    }
    this.fs
      .deleteFile(this.activeImage._id)
      .pipe(
        tap((res) => {
          console.log(res);
          this.ms.setDismissReason("cancel");
          this.ms.hide(this.modalId);
        }),
        catchError((err) => {
          if (typeof err === "string") {
            this.ms.setDismissReason("cancel");
            this.ms.hide(this.modalId);
          }
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  onFilesSelected(event: Array<Image>) {
    console.log(event);
    if (event.length === 0 || /pdf/i.test(event[0].img.name)) {
      return;
    }
    this.activeImage = event[0];
    this.cd.detectChanges();
  }
}
