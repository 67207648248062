import { Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Observable, of, Subject, tap, debounceTime } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { Prescription } from "src/app/core/models";
import { PrescriptionsService } from "src/app/core/services/prescriptions.service";
import { getPrescriptionFormGroup } from "src/app/shared/utils/forms";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { Pagination, PaginationParams } from "src/app/types/pagination";

@Component({
  selector: "app-prescriptions",
  templateUrl: "./prescriptions.component.html",
  styleUrls: ["./prescriptions.component.css"],
})
export class PrescriptionsComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @Input() child: boolean = false;
  @Input() limit: number = 10;
  @Input() offset: number = 0;
  @Input() patientId: number;
  @Input() vendorId: number;
  prescriptionForm = getPrescriptionFormGroup();
  prescriptions: Array<Prescription> = [];
  prescriptionSub$: Observable<Pagination<Prescription>> = of({
    items: [],
    offset: this.offset ?? 0,
    limit: this.limit ?? 100,
    total: 0,
  });
  expandedRows: Record<number, boolean> = {};
  page: number = 1;
  searchId: number;
  search = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;

  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Prescriptions", url: "/prescriptions" },
  ];

  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return { search: this.search, ...this.pagination };
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(private ps: PrescriptionsService, private route: ActivatedRoute) {
    this.route.params
      .pipe(
        tap(({ patientId, vendorId }) => {
          console.log(this.route.snapshot.params);
          console.log(patientId, vendorId);
          this.searchId = patientId || vendorId;
          this.getPrescriptions();
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.page = 1;
          this.pagination.skip = 0;
          this.getPrescriptions();
        })
      )
      .subscribe();
  }

  getCall(params?: Params) {
    console.log(params);
    if (params.patientId) {
      return this.ps.getPatientPrescriptions(
        params.patientId,
        this.queryParams
      );
    }
    return this.ps.getPrescriptions(this.queryParams);
  }

  getPrescriptions() {
    const { skip, take } = this.pagination;
    this.getCall(
      removeEmptyParams({
        skip,
        take,
        search: this.search,
        ...{
          patientId: this.patientId,
          vendorId: this.vendorId,
        },
        ...this.route.snapshot.params,
      })
    ).subscribe(({ items, offset, limit, total }) => {
      this.prescriptions = items;
      this.pagination = {
        skip: +offset,
        take: +limit,
        total: +total,
      };
      this.noResults = !items.length;
      console.log(this.noResults);
    });
  }

  expandRow(index: number | string) {
    this.expandedRows = { [index]: true };
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getPrescriptions();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getPrescriptions();
  }
}
