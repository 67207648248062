<div class="container-fluid">
  <app-page-title
    title="Receivables"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>

  <div class="row">
    <div class="col-12">
      <app-unpaid-payables
        [receivable]="true"
        [store]="selectedStore"
      ></app-unpaid-payables>
    </div>
  </div>
  <!-- @for (item of _items; track $index) {
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mt-0">{{ item.store }}</h4>
          <h6 class="card-subtitle font-14 text-muted mt-3">
            {{ item.unpaid }} Unpaid | {{ item.paid }} Paid
          </h6>
          <div>
            <div
              role="group"
              aria-label="Basic radio toggle button group"
              class="btn-group mt-3"
              (click)="toggleType($index)"
            >
              <a
                for="btnradio4"
                class="btn"
                [class.btn-outline-secondary]="item.showPaid"
                [class.btn-secondary]="!item.showPaid"
                >Unpaid</a
              >
              <a
                for="btnradio5"
                class="btn"
                [class.btn-outline-secondary]="!item.showPaid"
                [class.btn-secondary]="item.showPaid"
                >Paid</a
              >
            </div>
          </div>
        </div>

        <accordion [closeOthers]="true" class="accordion" [isAnimated]="true">
          <accordion-group heading="Orders" class="accordion-item" #group>
            <span accordion-heading class="float-end fs-5">
              <i
                class="mdi"
                [ngClass]="
                  !group?.isOpen
                    ? 'mdi mdi-chevron-down accor-plus-icon'
                    : 'mdi mdi-chevron-up accor-plus-icon'
                "
              ></i>
            </span>
            @if(item.showPaid) {
            <app-paid-payables
              [receivable]="true"
              [store]="item._id"
            ></app-paid-payables>
            } @else {
            <app-unpaid-payables
              [receivable]="true"
              [store]="item._id"
            ></app-unpaid-payables>
            }
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>

  } -->
</div>
