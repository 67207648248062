import { createAction, props } from "@ngrx/store";
import { User } from "src/app/core/models/user";
import { Vendor } from "src/app/core/models/vendor";

// login action
export const login = createAction(
  "[Authentication] Login",
  props<{ email: string; password: string; store: number }>()
);
export const loginSuccess = createAction(
  "[Authentication] Login Success",
  props<{ user: User; token: string; store: number }>()
);
export const loginFailure = createAction(
  "[Authentication] Login Failure",
  props<{ error: any }>()
);

export const tokenExpiration = createAction(
  "[Authentication] Token Expiration",
  props<{ status: number }>()
);

export const vendorsSuccess = createAction(
  "[Authentication] Vendors Success",
  props<{ vendors: Array<Vendor> }>()
);

export const selectStore = createAction(
  "[Authentication] Select Store",
  props<{ store: number }>()
);

// logout action
export const logout = createAction("[Authentication] Logout");

export const logoutSuccess = createAction("[Auth] Logout Success");
