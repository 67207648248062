<div class="container-fluid">
  <app-page-title
    *ngIf="!receivable"
    title="Paid Orders"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-md-2 justify-content-between">
              <div class="col-sm-12 col-md-4">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchOrders
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-control form-control-sm mx-2"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <div  *ngIf="!receivable" class="col-sm-12 col-md-4">
                <div class="d-flex justify-content-center">
                  <div  style="width: 100px;">
                    <select class="form-select form-control-sm mx-2 heading" (change)="viewByStatus($event.target.value)">
                      <option>Unpaid</option>
                      <option selected>Paid</option>
                    </select>
                  </div>
                  
                </div>
              </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-4">
                              <!-- <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-end"
                              >
                                <label class="d-inline-flex align-items-center"
                                  >Search:
                                  <input
                                    type="text"
                                    name="searchTerm"
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table"
                                    [(ngModel)]="search"
                                    (ngModelChange)="search$.next()"
                                  />
                                </label>
                              </div> -->
                            </div>
                            <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table  table-bordered mb-0">
                <thead>
                  <tr>
                    <th *ngIf="!receivable">Vendor</th>
                    <th>Invoice #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Shipping</th>
                    <th>Tax</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @if(loading$) {
                  <tr>
                    <td align="center" colspan="8">
                      <div
                        style="
                          width: 100%;
                          min-height: 200px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 15px;
                        "
                      >
                        <div
                          role="status"
                          class="spinner-border text-success m-1"
                          style="width: 75px; height: 75px"
                        >
                          <span class="sr-only">Loading Payables...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (payable of payables; track payable) {
                  <tr class="row-header">
                    <td *ngIf="!receivable"></td>
                    <td></td>
                    <td>{{ payable.datePaid | date : "MM/dd/yyyy" }}</td>
                    <td>{{ payable.status }}</td>
                    <td align="right">{{ payable.shipping | currency }}</td>
                    <td align="right">{{ payable.tax | currency }}</td>
                    <td align="right">{{ payable.total | currency }}</td>
                    <td><i class="fas fa-print"></i></td>
                  </tr>
                  @for (order of payable.orders; track order) {
                  <tr>
                    <td *ngIf="!receivable">{{ order.vendor?.name }}</td>
                    <td>{{ order.invoiceNumber }}</td>
                    <td>{{ order.datePaid | date : "MM/dd/yyyy" }}</td>
                    <td>{{ order.status }}</td>
                    <td align="right">{{ order.shipping | currency }}</td>
                    <td align="right">{{ order.tax | currency }}</td>
                    <td align="right">{{ order.total | currency }}</td>
                    <td>
                      <i
                        class="fas fa-eye"
                        [routerLink]="['/payables/invoice', order._id]"
                      ></i>
                    </td>
                  </tr>
                  } }}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  paid invoice groups
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [itemsPerPage]="pagination.take"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content> </ng-template>
