import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { select, Store } from "@ngrx/store";
import { map, tap, Observable } from "rxjs";
import { getUser } from "src/app/store/Authentication/authentication-selector";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AuthenticationState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(getUser),
      map((user) => !!user),
      tap((authenticated) => {
        console.log(state.url);
        if (!authenticated) {
          this.router.navigate(["/auth/login"]);
        }
      })
    );
  }
}
