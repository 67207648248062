@if(wizardMode) {
<div *ngIf="orderForm" class="ao-form" [class.container-fluid]="!minimal">
  <div [formGroup]="orderForm" class="row">
    <div class="col"></div>
    <div class="col-lg-10">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">New Patient Order</h4>
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard">
              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>Patient Details</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){
                <section>
                  @if(!newPatient) {
                  <app-patients
                    [child]="true"
                    (selectPatient)="onPatientSelect($event)"
                    [searchOnly]="true"
                  ></app-patients>
                  } @else {

                  <app-patient
                    [child]="true"
                    (selectPatient)="onPatientSelect($event)"
                  ></app-patient>
                  }

                  <ul
                    class="list-inline wizard d-flex justify-content-end mb-0 mt-3"
                  >
                    <li class="previous list-inline-item" aria-disabled="true">
                      <button
                        class="btn btn-secondary"
                        (click)="newPatient = !newPatient"
                      >
                        {{ newPatient ? "Find Patient" : "Add Patient" }}
                      </button>
                    </li>

                    <li class="previous list-inline-item" aria-disabled="true">
                      <button class="btn btn-danger" (click)="onCancel()">
                        Cancel
                      </button>
                    </li>
                    <!-- <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li> -->
                  </ul>
                </section>
                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Add Products</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section>
                  <div>
                    <app-patient-fill [patient]="patient"></app-patient-fill>
                  </div>
                  <div class="row">
                    <div class="col-12 my-2 py-1 border-bottom">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-2">
                          <div class="v-label">
                            <h6>Add Product</h6>
                          </div>
                        </div>

                        <div class="col-6 col-sm-8 col-md-10">
                          <div
                            class="d-flex align-items-center justify-content-start"
                          >
                            <button
                              class="btn btn-sm btn-light border-0 bg-transparent"
                              (click)="addProduct()"
                            >
                              <i class="fas fa-plus"></i> Inventory
                            </button>
                            <button
                              class="btn btn-sm btn-light border-0 bg-transparent ms-2"
                              (click)="addProduct(true)"
                            >
                              <i class="fas fa-plus"></i> New Product
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 my-2 py-1 border-bottom">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-2">
                          <div class="v-label">
                            <h6>Favorite Lines</h6>
                          </div>
                        </div>

                        <div class="col-6 col-sm-8 col-md-10">
                          <div
                            class="d-flex align-items-center justify-content-start"
                          >
                            <div>
                              <a
                                *ngFor="let vendor of favoriteVendorOptions"
                                class="d-inline-block mx-1 my-1"
                                href="javascript:void(0)"
                                (click)="addProduct(false, vendor._id)"
                              >
                                <kbd>{{ vendor.label }}</kbd>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="hasControls" class="table-responsive mb-5">
                    <table class="table w-100 mb-5">
                      <tbody>
                        <tr
                          *ngFor="
                            let control of productControls;
                            let index = index
                          "
                        >
                          <td class="px-0 mx-0">
                            <app-order-minimal
                              [product]="productValues[index]"
                              (productChange)="onProductChange($event, index)"
                              [manualEntry]="manualEntry[index]"
                              (cancel)="removeProduct(index)"
                            ></app-order-minimal>
                          </td>
                          <td width="25" valign="center" class="align-middle">
                            <div
                              class="h-100 w-100 d-flex align-items-center justify-content-center"
                            >
                              <i
                                class="fas fa-trash-alt"
                                (click)="removeProduct(index)"
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul
                    class="list-inline wizard d-flex justify-content-end mb-0"
                  >
                    <!-- <li class="previous list-inline-item" aria-disabled="true">
                      <button class="btn btn-secondary" (click)="addProduct()">
                        Add Product
                      </button>
                    </li> -->
                    <li class="previous list-inline-item" aria-disabled="true">
                      <button class="btn btn-primary" cdkStepperPrevious>
                        Previous
                      </button>
                    </li>
                    <li class="next list-inline-item" aria-disabled="false">
                      <button
                        [disabled]="!orderForm.valid"
                        class="btn btn-primary"
                        (click)="onConfirm()"
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </section>
                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">3.</span>
                  <span>Order Detail</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 2){

                <section>
                  <div>
                    <div class="text-end">
                      <div class="form-check form-check-right mb-3"><input type="radio" name="formRadiosRight" id="formRadiosRight1" checked="" class="form-check-input"><label for="formRadiosRight1" class="form-check-label"> New </label></div>
                    </div>

                  </div>
                  <div>
                    <app-patient-fill [patient]="patient"></app-patient-fill>
                  </div>
                  <div class="table-responsive mb-3">
                    <table class="table w-100">
                      <tbody>
                        <tr
                          *ngFor="
                          let control of productControls;
                            let index = index
                          "
                        >
                          <td class="px-0 mx-0">
                            <app-order-minimal
                              [product]="control.value"
                              [readOnly]="true"
                            ></app-order-minimal>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-lg-8">
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  formControlName="duty"
                                  class="form-check-input"
                                />
                                <label for="duty" class="form-check-label"
                                  >RX/No Tax</label
                                >
                              </div>
                            </div>

                            <div class="col-6">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  formControlName="part"
                                  class="form-check-input"
                                />
                                <label for="part" class="form-check-label"
                                  >Part</label
                                >
                              </div>
                            </div>

                            <div class="col-6">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  formControlName="repair"
                                  class="form-check-input"
                                />
                                <label for="repair" class="form-check-label"
                                  >Send in for repair</label
                                >
                              </div>
                            </div>

                            <div class="col-6">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  formControlName="notify"
                                  class="form-check-input"
                                />
                                <label for="notify" class="form-check-label"
                                  >Call with estimate</label
                                >
                              </div>
                            </div>

                            <div class="col-6">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  [checked]="shippingMethod === 'UPS/FEDEX'"
                                  (change)="
                                    onShippingMethodChange($event, 'UPS/FEDEX')
                                  "
                                />
                                <label class="form-check-label"
                                  >UPS/FEDEX</label
                                >
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div
                                class="form-check form-switch form-switch-md mb-1"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  [checked]="shippingMethod === 'US POSTAL'"
                                  (onChange)="
                                    onShippingMethodChange($event, 'US POSTAL')
                                  "
                                />
                                <label class="form-check-label"
                                  >US POSTAL</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-12">
                              <div
                                class="d-flex flex-column align-items-center justify-content-between gap-4 my-2"
                              >
                                <div
                                  class="image-upload h"
                                  style="height: 300px"
                                  (click)="modal ? openImages(0) : openImageCropper()"
                                >
                                  <ng-container
                                    *ngIf="!uploadedImages.length; else newFile"
                                  >
                                    <i class="bx bx-upload"></i
                                    ><span>Upload</span><span>Image</span>
                                  </ng-container>
                                  <ng-template #newFile>
                                    <img
                                      [src]="uploadedImages[0].image"
                                      alt="Image"
                                      class="h-100"
                                    />
                                  </ng-template>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-12">
                              <div class="h my-2">
                                <ckeditor
                                  [editor]="editor"
                                  [config]="editorConfig"
                                ></ckeditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-3 mt-md-0">
                      <div class="flot-end">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="invoiceNumber"
                            class="form-control"
                            id="invoiceNumber"
                            placeholder="Invoice Number"
                          />
                          <label for="tax">Invoice #</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="tax"
                            class="form-control"
                            id="tax"
                            placeholder="Tax"
                          />
                          <label for="tax">Tax</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="shipping"
                            class="form-control"
                            id="shipping"
                            placeholder="Shipping"
                          />
                          <label for="shipping">Postage</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="total"
                            class="form-control"
                            id="total"
                            placeholder="Total"
                          />
                          <label for="total">Total</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="deposit"
                            class="form-control"
                            id="deposit"
                            placeholder="Deposit"
                          />
                          <label for="total">Deposit</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            formControlName="balance"
                            class="form-control disabled"
                            id="balance"
                            placeholder="Balance"
                          />
                          <label for="total">Balance</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul
                    class="list-inline wizard d-flex justify-content-end mb-0"
                  >
                    <li class="previous list-inline-item" aria-disabled="true">
                      <button class="btn btn-primary" cdkStepperPrevious>
                        Previous
                      </button>
                    </li>
                    <li class="next list-inline-item" aria-disabled="false">
                      <button class="btn btn-primary" (click)="onSubmit()">
                        Save
                      </button>
                    </li>
                  </ul>
                </section>
                }
              </cdk-step>
            </ng-stepper>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <div class="col"></div>
    <!-- end col -->
  </div>
</div>
} @else {
<div *ngIf="orderForm" [class.container-fluid]="!minimal" [class.p-3]="modal" [class.ao-form-read]="!editable">
  <app-page-title
    *ngIf="!modal && !minimal"
    title="Orders"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div class="col-12">
      <div *ngIf="patient" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <app-patient-fill [patient]="patient" [readonly]="true"></app-patient-fill>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="row" *ngIf="editable">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-sm-4 col-md-2">
              <div class="v-label">
                <h6>Add Product</h6>
              </div>
            </div>
    
            <div class="col-6 col-sm-8 col-md-10">
              <div
                class="d-flex align-items-center justify-content-start"
              >
                <button
                  class="btn btn-sm btn-light border-0 bg-transparent"
                  (click)="addProduct()"
                >
                  <i class="fas fa-plus"></i> Inventory
                </button>
                <button
                  class="btn btn-sm btn-light border-0 bg-transparent ms-2"
                  (click)="addProduct(true)"
                >
                  <i class="fas fa-plus"></i> New Product
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editable && favoriteVendorOptions.length" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-sm-4 col-md-2">
              <div class="v-label">
                <h6>Favorite Lines</h6>
              </div>
            </div>
    
            <div class="col-6 col-sm-8 col-md-10">
              <div
                class="d-flex align-items-center justify-content-start"
              >
                <div>
                  <a
                    *ngFor="let vendor of favoriteVendorOptions"
                    class="d-inline-block mx-1 my-1"
                    href="javascript:void(0)"
                    (click)="addProduct(false, vendor._id)"
                  >
                    <kbd>{{ vendor.label }}</kbd>
                  </a>
                </div>
              </div>
            </div>
          </div>
  </div>
      </div>
    </div>
  </div>
  <div [formGroup]="orderForm" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
            <div *ngIf="productControls.length" class="table-responsive px-0 m-2">
              <table class="table w-100">
                <tbody>
                  <tr
                    *ngFor="let control of productControls; let index = index"
                  >
                    <td class="">
                      <app-order-minimal
                        [manual]="true"
                        [product]="productValues[index]"
                        (productChange)="onProductChange($event, index)"
                        [manualEntry]="manualEntry[index]"
                        (cancel)="removeProduct(index)"
                      ></app-order-minimal>
                    </td>
                    <td width="25" valign="center" class="align-middle">
                      <div
                        class="h-100 w-100 d-flex align-items-center justify-content-center"
                      >
                        <i
                          class="fas fa-trash-alt"
                          (click)="removeProduct(index)"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          <div class="row">
            <div class="col-lg-8">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input
                          type="checkbox"
                          formControlName="duty"
                          class="form-check-input"
                        />
                        <label for="duty" class="form-check-label"
                          >RX/No Tax</label
                        >
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input
                          type="checkbox"
                          formControlName="part"
                          class="form-check-input"
                        />
                        <label for="part" class="form-check-label">Part</label>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input
                          type="checkbox"
                          formControlName="repair"
                          class="form-check-input"
                        />
                        <label for="repair" class="form-check-label"
                          >Send in for repair</label
                        >
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input
                          type="checkbox"
                          formControlName="notify"
                          class="form-check-input"
                        />
                        <label for="notify" class="form-check-label"
                          >Call with estimate</label
                        >
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input type="checkbox" class="form-check-input" />
                        <label class="form-check-label">UPS/FEDEX</label>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-check form-switch form-switch-md mb-1">
                        <input type="checkbox" class="form-check-input" />
                        <label class="form-check-label">US POSTAL</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="d-flex flex-column align-items-center justify-content-between gap-4 my-2"
                      >
                        <div
                          class="image-upload h"
                          style="height: 300px"
                          (click)="modal ? openImages(0) : openImageCropper()"
                          >
                          <ng-container
                            *ngIf="!uploadedImages.length; else newFile"
                          >
                            <i class="bx bx-upload"></i><span>Upload</span
                            ><span>Image</span>
                          </ng-container>
                          <ng-template #newFile>
                            <img
                              [src]="uploadedImages[0].image"
                              alt="Image"
                              class="h-100"
                            />
                          </ng-template>
                        </div>
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="h my-2">
                        <ckeditor
                          #patientOrderNotes
                          [editor]="editor"
                          [config]="editorConfig"
                          (ready)="notesReady$.next()"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mt-3 mt-md-0">
              <div class="flot-end">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="invoiceNumber"
                    class="form-control"
                    id="invoiceNumber"
                    placeholder="Invoice Number"
                  />
                  <label for="tax">Invoice #</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="tax"
                    class="form-control"
                    id="tax"
                    placeholder="Tax"
                  />
                  <label for="tax">Tax</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="shipping"
                    class="form-control"
                    id="shipping"
                    placeholder="Shipping"
                  />
                  <label for="shipping">Postage</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="total"
                    class="form-control"
                    id="total"
                    placeholder="Total"
                  />
                  <label for="total">Total</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="deposit"
                    class="form-control"
                    id="deposit"
                    placeholder="Deposit"
                  />
                  <label for="total">Deposit</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    formControlName="balance"
                    class="form-control disabled"
                    id="balance"
                    placeholder="Balance"
                  />
                  <label for="total">Balance</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="externalSave" class="row">
    <div class="text-end">
      <div class="col-12">
        <!-- <button class="btn btn-secondary" (click)="addProduct()">
          Add Product
        </button> -->
        <button
          class="btn btn-primary ms-2"
          (click)="onSubmit()"
          [disabled]="!orderForm.valid"
        >
          Save
        </button>
        <button class="btn btn-danger ms-2" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
}
