import { Action, createReducer, on } from "@ngrx/store";
import {
  changesLayout,
  changeMode,
  changeLayoutWidth,
  changeSidebarMode,
  changeTopbarMode,
} from "./layout.actions";

export interface LayoutState {
  LAYOUT_MODE: string;
  DATA_LAYOUT: string;
  LAYOUT_WIDTH: string;
  SIDEBAR_MODE: string;
  TOPBAR_TYPE: string;
}

// INIT_STATE
export const initialState: LayoutState = {
  LAYOUT_MODE: "light",
  DATA_LAYOUT: "horizontal",
  LAYOUT_WIDTH: "boxed",
  SIDEBAR_MODE: "dark",
  TOPBAR_TYPE: "dark",
  ...(JSON.parse(localStorage.getItem("layoutState") ?? "{}") as LayoutState),
};

// Reducer
export const layoutReducer = createReducer(
  initialState,
  on(changeMode, (state, action) => {
    const nextState = { ...state, LAYOUT_MODE: action.mode };
    localStorage.setItem("layoutState", JSON.stringify(nextState));
    return nextState;
  }),
  on(changesLayout, (state, action) => {
    const nextState = {
      ...state,
      DATA_LAYOUT: action.layoutMode,
    };
    localStorage.setItem("layoutState", JSON.stringify(nextState));
    return nextState;
  }),
  on(changeLayoutWidth, (state, action) => {
    const nextState = {
      ...state,
      LAYOUT_WIDTH: action.layoutWidth,
    };
    localStorage.setItem("layoutState", JSON.stringify(nextState));
    return nextState;
  }),
  on(changeSidebarMode, (state, action) => {
    const nextState = {
      ...state,
      SIDEBAR_MODE: action.sidebarMode,
    };
    localStorage.setItem("layoutState", JSON.stringify(nextState));
    return nextState;
  }),
  on(changeTopbarMode, (state, action) => {
    const nextState = {
      ...state,
      TOPBAR_TYPE: action.topbarmode,
    };
    localStorage.setItem("layoutState", JSON.stringify(nextState));
    return nextState;
  })
);

// on(changeMode, (state, action) => ({ ...state, LAYOUT_MODE: action.mode })),
// on(changesLayout, (state, action) => ({ ...state, DATA_LAYOUT: action.layoutMode })),
// on(changeLayoutWidth, (state, action) => ({ ...state, LAYOUT_WIDTH: action.layoutWidth })),
// on(changeSidebarMode, (state, action) => ({ ...state, SIDEBAR_MODE: action.sidebarMode })),
// on(changeTopbarMode, (state, action) => ({ ...state, TOPBAR_TYPE: action.topbarmode })),

// Selector
export function reducer(state: LayoutState | undefined, action: Action) {
  return layoutReducer(state, action);
}
