import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { getLogFormGroup } from "./form";
import { Prescription } from "src/app/core/models";

export const getPrescriptionFormGroup = (prescription?: Prescription) => {
  return new FormGroup({
    _id: new FormControl(prescription?._id),
    archived: new FormControl(prescription?.archived),
    dateAdded: new FormControl(prescription?.dateAdded),
    dateUpdated: new FormControl(prescription?.dateUpdated),
    patientId: new FormControl(prescription?.patientId),
    type: new FormControl(prescription?.type),
    typeOther: new FormControl(prescription?.typeOther),
    expirationDate: new FormControl(prescription?.expirationDate),
    examDate: new FormControl(prescription?.examDate),
    from2020Now: new FormControl(prescription?.from2020Now),
    left: new FormGroup({
      sph: new FormControl(prescription?.left?.sph),
      cyl: new FormControl(prescription?.left?.cyl),
      axis: new FormControl(prescription?.left?.axis),
      add: new FormControl(prescription?.left?.add),
      segHgt: new FormControl(prescription?.left?.segHgt),
      prism1: new FormControl(prescription?.left?.prism1),
      b1: new FormControl(prescription?.left?.b1),
      prism2: new FormControl(prescription?.left?.prism2),
      b2: new FormControl(prescription?.left?.b2),
      pdd: new FormControl(prescription?.left?.pdd),
      pdn: new FormControl(prescription?.left?.pdn),
      oc: new FormControl(prescription?.left?.oc),
    }),
    right: new FormGroup({
      sph: new FormControl(prescription?.right?.sph),
      cyl: new FormControl(prescription?.right?.cyl),
      axis: new FormControl(prescription?.right?.axis),
      add: new FormControl(prescription?.right?.add),
      segHgt: new FormControl(prescription?.right?.segHgt),
      prism1: new FormControl(prescription?.right?.prism1),
      b1: new FormControl(prescription?.right?.b1),
      prism2: new FormControl(prescription?.right?.prism2),
      b2: new FormControl(prescription?.right?.b2),
      pdd: new FormControl(prescription?.right?.pdd),
      pdn: new FormControl(prescription?.right?.pdn),
        oc: new FormControl(prescription?.right?.oc),
    }),
    eyeExamId: new FormControl(prescription?.eyeExamId),
    _logs: new FormArray((prescription?._logs ?? []).map(getLogFormGroup)),
  });
};


