import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { concatMap, Observable, of } from "rxjs";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Frame } from "src/app/types/frame";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./auth.service";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { Params } from "@angular/router";
import { SelectOptions } from "../models/select";
import { InventoryOptions } from "src/app/types/select";

export interface FrameOptions {
  types: Array<InventoryOptions>;
  colors: Array<InventoryOptions>;
  sizes: Array<InventoryOptions>;
  models: Array<InventoryOptions>;
}

@Injectable({
  providedIn: "root",
})
export class FramesService {
  private base_url = `${environment.apiDomain}`;

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  createFrame(frame: Partial<Frame>): Observable<Frame> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.post<Frame>(`${this.base_url}/frames`, {
          ...frame,
          storeId: store,
        })
      )
    );
  }

  getFrame(frameId: number): Observable<Frame> {
    return this.http.get<Frame>(`${this.base_url}/frames/${frameId}`);
  }

  getFrames(): Observable<Frame[]> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Array<Frame>>(`${this.base_url}/frames/store/${store}`)
      )
    );
  }

  getPatientFrames(patientId: number): Observable<Frame[]> {
    return this.http.get<Array<Frame>>(`${this.base_url}/frames/${patientId}`);
  }

  getVendorFrames(vendorId: number): Observable<Frame[]> {
    return this.http.get<Array<Frame>>(
      `${this.base_url}/frames/vendor/${vendorId}`
    );
  }

  searchFrames(term: string | number): Observable<Frame[]> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Array<Frame>>(
          `${this.base_url}/frames/search/${term}/${store}`
        )
      )
    );
  }

  searchPatientFrames(
    patientId: number,
    term: string | number
  ): Observable<Frame[]> {
    return this.http.get<Array<Frame>>(
      `${this.base_url}/frames/${patientId}/search/${term}`
    );
  }

  searchVendorFrames(vendorId: number, term: string): Observable<Frame[]> {
    return this.http.get<Array<Frame>>(
      `${this.base_url}/frames/vendor/${vendorId}/search/${term}`
    );
  }

  updateFrame(frame: Partial<Frame>): Observable<Frame> {
    return this.http.put<Frame>(`${this.base_url}/frames`, frame);
  }

  getFrameVendorOptions(): Observable<Array<SelectOptions>> {
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<Array<SelectOptions>>(
          `${this.base_url}/frames/vendors/${store}`
        )
      )
    );
  }

  getFrameSelectionOptions(params: Params): Observable<FrameOptions> {
    const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return {
        ...acc,
        [key]: value,
      };
    }, {});
    if (!params?.vendor)
      return of({
        types: [],
        colors: [],
        sizes: [],
        models: [],
      });
    return this.store.pipe(
      select(getStore),
      concatMap((store) =>
        this.http.get<FrameOptions>(`${this.base_url}/frames/options`, {
          params: { ...queryParams, store },
        })
      )
    );
  }
}
