import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { debounceTime, Observable, of, Subject, tap } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { PatientService } from "src/app/core/services/patient.service";
import { getPatientFormGroup } from "src/app/shared/utils/forms";
import { Pagination, PaginationParams } from "src/app/types/pagination";
import { PatientComponent } from "../patient/patient.component";
import { Patient } from "src/app/core/models";

@Component({
  selector: "app-patients",
  templateUrl: "./patients.component.html",
  styleUrls: ["./patients.component.css"],
})
export class PatientsComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @Input() child: boolean = false;
  @Input() searchOnly: boolean = false;
  @Output() selectPatient = new EventEmitter<Patient>();
  patientForm = getPatientFormGroup();
  patients: Array<Patient> = [];
  patientSub$: Observable<Pagination<Patient>> = of({
    items: [],
    offset: 0,
    limit: 10,
    total: 0,
  });
  expandedRows: Record<number, boolean> = {};
  page: number = 1;
  searchId: number;
  search = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;
  noSearch: boolean = false;
  loading$: boolean = true;
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Patients", url: "/patients" },
  ];

  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return {
      search: this.search,
      ...this.pagination,
    };
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private ps: PatientService,
    private ms: BsModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.child) this.pagination.take = 5;
    if (this.searchOnly) {
      this.loading$ = false;
      this.noSearch = true;
    } else {
      this.getPatients();
    }

    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.loading$ = true;
          this.page = 1;
          this.pagination.skip = 0;
          this.getPatients();
        })
      )
      .subscribe();
  }

  getPatients() {
    if (this.searchOnly && !this.search.length) {
      this.patients = [];
      this.pagination.total = 0;
      this.noResults = true;
      this.noSearch = true;
      return;
    }

    const { skip, take } = this.pagination;
    this.ps
      .getPatients({ skip, take, search: this.search })
      .subscribe(({ items, offset, limit, total }) => {
        this.patients = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
        this.noSearch = false;
        this.noResults = !items.length;
        this.loading$ = false;
      });
  }

  expandRow(index: number | string) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getPatients();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getPatients();
  }

  onEdit(patient: Patient) {
    const modalRef = this.ms.show(PatientComponent, {
      id: patient._id,
      initialState: { patientRef: patient, modal: true },
      class: "modal-xl",
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason === "save") {
        this.getPatients();
      }
    });
  }
}
