import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthenticationState } from "./authentication.reducer";

export const getLayoutState =
  createFeatureSelector<AuthenticationState>("auth");

export const getAuthentication = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.isLoggedIn
);

export const getUserStore = createSelector(
  getLayoutState,
  (state: AuthenticationState) => {
    const { store, user } = state;
    return { store, user };
  }
);

export const getUser = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.user
);

export const getIsLoggedIn = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.isLoggedIn
);

export const getError = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.error
);

export const getToken = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.token
);

export const getVendors = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.vendors
);

export const getStore = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.store
);
