import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SimplebarAngularModule as SimpleBarAngularModule } from "simplebar-angular";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { LayoutComponent } from "./layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopBarComponent } from "./topbar/topbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RightSideBarComponent } from "./sidebar-right/sidebar-right.component";
import { HorizontalComponent } from "./horizontal/horizontal.component";
import { VerticalComponent } from "./vertical/vertical.component";
import { LanguageService } from "../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PagesModule } from "../components/pages.module";
import { HorizontalTopBarComponent } from "./topbar-horizontal/horizontal-topbar.component";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopBarComponent,
    FooterComponent,
    RightSideBarComponent,
    HorizontalComponent,
    VerticalComponent,
    HorizontalTopBarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    SimpleBarAngularModule,
    BsDatepickerModule,
    PagesModule,
    ProgressbarModule,
  ],
  providers: [LanguageService],
  exports: [LayoutComponent],
})
export class LayoutsModule {}
