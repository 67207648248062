import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DropzoneComponent,
  DropzoneConfigInterface,
} from "ngx-dropzone-wrapper";
import { concatMap, Subject, takeUntil, tap, timer } from "rxjs";
import { FileService } from "src/app/core/services/file.service";
import { Image, ImageType } from "src/app/types/image";
export interface UploadResult {
  file: any;
  base64: string;
  blobUrl: string;
}

@Component({
  selector: "app-uploads",
  templateUrl: "./uploads.component.html",
  styleUrls: ["./uploads.component.scss"],
})
export class UploadsComponent implements OnInit {
  @ViewChild(DropzoneComponent, { static: false }) dz?: DropzoneComponent;
  @Input() standalone = false;
  @Input() refId: string;
  @Input() type: ImageType;
  @Output() onFilesSelected = new EventEmitter<Array<Image>>();
  // bread crumb items
  // breadCrumbItems: Array<{}>;
  files: File[] = [];
  dropzoneConfig: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: false,
    previewsContainer: false,
  };
  uploadedFiles: any[] = [];
  imageURL: any;
  loading = false;
  progress$ = 0;
  stopLoading$ = new Subject<void>();
  constructor(private fs: FileService) {}

  ngOnInit() {
    // this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Form File Upload', active: true }];
  }

  ngAfterViewInit() {
    this.dz.DZ_ADDEDFILE.pipe(
      tap(() => this.setLoading()),
      concatMap((addedFile: File) => this.uploadFile(addedFile))
    ).subscribe((files) => {
      this.onFilesSelected.emit(files);
      this.endLoading();
    });
  }

  endLoading() {
    this.stopLoading$.next();
    this.loading = false;
    this.progress$ = 0;
  }

  setLoading() {
    this.loading = true;
    timer(0, 10)
      .pipe(takeUntil(this.stopLoading$))
      .subscribe((time) => {
        this.progress$ = time;
      });
  }

  uploadFile(file: File) {
    const { name } = file;
    const extIdx = name.lastIndexOf(".");
    const ext = name.substring(extIdx);
    const tempName = `${this.refId}-temp.${ext}`.replace(/[^a-zA-Z0-9.-]/g, "-").toLowerCase().replace('..','.');
    const uploadFile = new File([file], tempName, {
      type: file.type
    });
    console.log(uploadFile)
    return this.fs.uploadFile(uploadFile, this.refId, this.type);
  }

  onFilesAdded(event: any) {
    console.log(event, "xc");
  }

  onThumbnailGenerated(event: any) {
    console.log(event);
  }

  onUploadSuccess(event: any) {}

  // File Remove
  removeFile(event: any) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(event), 1);
  }
}
