<div class="container-fluid">
  <!-- start page title -->

  <app-page-title
    title="Invoice"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>

  <!-- end page title -->

  <div  *ngIf="payable" class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="invoice-title">
            <h4 *ngIf="payable?.invoiceNumber" class="float-end font-size-16">
              <div class="d-flex flex-column align-items-end">
                <span>Invoice # {{ payable.invoiceNumber }}</span>
                <small class="text-muted">{{ storeName }}</small>
              </div>
            </h4>
            <div class="auth-logo mb-4">
              <a routerLink="/" class="logo logo-light">
                <span>
                  <img src="assets/images/logo-redraw.png" alt="" height="17" />
                </span>
              </a>
      
              <a routerLink="/" class="logo logo-dark">
  
                <span>
                  <img src="assets/images/logo-redraw-white.png" alt="" height="19" />
                </span>
              </a>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6">
              <img *ngIf="payable._vendor._images.length > 0" class="mb-3" [src]="payable._vendor._images[0].url" [alt]="payable._vendor.name" style="width: 100px">

              <address [innerHTML]="vendorAddress()"></address>
            </div>
          </div>
          <div class="py-2 mt-3">
            <h3 class="font-size-15 fw-bold">Order Summary</h3>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Model</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th>Tax</th>
                  <th>Shipping</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                @for (order of payable._orders; track order._id) {

                  @for (_product of order._products; track _product._id; let i = $index) {
                    <tr>
                      <td>@if(_product._images.length > 0) {
                        <a (click)="openLightbox(order, i)">
                          <img
                            [src]="_product._images[0].url"
                            alt="Product Image"
                            style="width: 100px"
                          />
                        </a>
                        }</td>
                      <td>{{ _product.model }}</td>
                      <td>{{ _product.color }}</td>
                      <td>{{ _product.size }}</td>
                      <td>{{ _product.type }}</td>
                      <td>{{ _product.tax || 0 | currency }}</td>
                      <td>{{ _product.shipping || 0 | currency }}</td>
                      <td>{{ _product.cost | currency }}</td>
                    </tr>
                    }

                }
                <tr>
                  <th colspan="5" class="text-end border-0">Subtotal</th>
                  <th class="border-0">{{ payable.tax || 0 | currency }}</th>
                  <th class="border-0">
                    {{ payable.shipping || 0 | currency }}
                  </th>
                  <th class="border-0">{{ cost | currency }}</th>
                </tr>
                <tr>
                  <th colspan="7" class="text-end border-0">Total</th>
                  <th class="border-0">
                    <h4 class="m-0">{{ payable.total | currency }}</h4>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-print-none">
            <div class="float-end">
              <a href="javascript:window.print()" class="btn btn-success"
                ><i class="fa fa-print"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
<!-- container-fluid -->
