import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { Order, Product } from "src/app/core/models";

export const getOrderFormGroup = (order?: Partial<Order>) => {
  return new FormGroup({
    _id: new FormControl(order?._id),
    archived: new FormControl(order?.archived),
    balance: new FormControl(order?.balance ?? 0),
    cost: new FormControl(order?.cost ?? 0),
    date: new FormControl(order?.date),
    dateAdded: new FormControl(order?.dateAdded),
    dateUpdated: new FormControl(order?.dateUpdated),
    deposit: new FormControl(order?.deposit ?? 0),
    duty: new FormControl(order?.duty ?? false),
    eta: new FormControl(order?.eta),
    images: new FormControl(order?.images),
    invoiceNumber: new FormControl(order?.invoiceNumber),
    inventory: new FormControl(order?.inventory),
    notes: new FormControl(order?.notes),
    notify: new FormControl(order?.notify ?? false),
    part: new FormControl(order?.part ?? false),
    quantity: new FormControl(order?.quantity ?? 1),
    retail: new FormControl(order?.retail ?? 0),
    repair: new FormControl(order?.repair ?? false),
    patient: new FormControl(order?.patient),
    patientId: new FormControl(order?.patientId),
    restockOrder: new FormControl(order?.restockOrder ?? false),
    specialOrder: new FormControl(order?.specialOrder ?? false),
    status: new FormControl(order?.status),
    store: new FormControl(order?.store),
    shipping: new FormControl(order?.shipping ?? 0),
    shippingMethod: new FormControl(order?.shippingMethod),
    tax: new FormControl(order?.tax ?? 0),
    todo: new FormControl(order?.todo),
    total: new FormControl(order?.total ?? 0),
    type: new FormControl(order?.type),
    user: new FormControl(order?.user),
    vendorOrder: new FormControl(order?.vendorOrder), 
    warranty: new FormControl(order?.warranty ?? false),
    _products: new FormArray((order?._products ?? [])?.map((p) => getOrderFrameFormGroup(p))),
  });
};

export const getOrderFrameFormGroup = (product?: Partial<Product>, opts?: {readOnly?: boolean, updateOn?: "change" | "blur" | "submit"}) => {
  return new FormGroup({
    _id: new FormControl(product?._id, {updateOn: opts?.updateOn}),
    vendor: new FormControl(product?.vendor, { validators: [Validators.required], updateOn: opts?.updateOn}),
    model: new FormControl(product?.model, { validators: [Validators.required], updateOn: opts?.updateOn}),
    color: new FormControl(product?.color, {updateOn: opts?.updateOn}),
    size: new FormControl(product?.size, {updateOn: opts?.updateOn}),
    type: new FormControl(product?.type, {updateOn: opts?.updateOn}),
    tax: new FormControl(product?.tax ?? 0, {updateOn: opts?.updateOn}),
    shipping: new FormControl(product?.shipping ?? 0, {updateOn: opts?.updateOn}),
    total: new FormControl(product?.total ?? 0, {updateOn: opts?.updateOn}),
    cost: new FormControl(product?.cost ?? 0, {updateOn: opts?.updateOn}),
    retail: new FormControl(product?.retail ?? 0, {updateOn: opts?.updateOn}),
    quantity: new FormControl(product?.quantity ?? 1, {updateOn: opts?.updateOn}),
    pricingFormula: new FormControl(product?.pricingFormula, {updateOn: opts?.updateOn}),
    _frameVendor: new FormControl(product?._frameVendor, {updateOn: opts?.updateOn}),
  });
};
