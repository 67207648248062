export const DEFAULT_PAGINATION = {
  skip: 0,
  take: 10,
  total: 0,
};

export const DEFAULT_SEARCH = {
  ...DEFAULT_PAGINATION,
  search: "",
};
