import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./auth.service";
import { Image, ImageType } from "src/app/types/image";

@Injectable({
  providedIn: "root",
})
export class FileService {
  private base_url = `${environment.apiDomain}`;

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  createThumbnail(id: string, width?: number, height?: number) {
    const query = width && height ? `?width=${width}&height=${height}` : "";
    return this.http.post<Image>(`${this.base_url}/files/thumbnail`, {
      id,
      width,
      height,
    });
  }

  deleteFile(id: string) {
    return this.http.delete(`${this.base_url}/files/${id}`);
  }

  getFile(id: string) {
    return this.http.get<Image>(`${this.base_url}/files/${id}`);
  }

  getRefFiles(refId: string, type?: ImageType) {
    const query = type ? `?type=${type}` : "";
    return this.http.get<Array<Image>>(
      `${this.base_url}/files/ref/${refId}${query}`
    );
  }

  uploadFile(file: File, refId: string, type: string, _id?: string) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("refId", refId);
    formData.append("type", type);
    _id && formData.append("_id", _id);
    return this.http.post<Array<Image>>(`${this.base_url}/files`, formData);
  }
}
