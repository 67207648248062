import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { getUserFormGroup } from 'src/app/shared/utils/forms/user';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, EMPTY, Observable, take, tap } from 'rxjs';
import { Image, User } from 'src/app/core/models';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Options } from 'src/app/types/select';
import { ConfigService } from 'src/app/core/services/config.service';
import { FormArray } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ImageCropperModalComponent } from '../../image-cropper/image-cropper-modal.component';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthenticationState } from 'src/app/store/Authentication/authentication.reducer';
import { Store } from '@ngrx/store';
import { getUser } from 'src/app/store/Authentication/authentication-selector';
import { removeEmptyParams } from 'src/app/shared/utils/queries/params';


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent {

  @Input() employeeRef: User;
  @Input() employeeId: string;
  @Input() modal: boolean = false;

  employee: User;
  employeeForm = getUserFormGroup();
  profileImage: string;

  storeMap: Record<string, string> = {};
  stores: Array<Options> = [];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;

  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Users", url: "/employees" },
  ];

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private ms: BsModalService,
    private cs: ConfigService,
    private store: Store<AuthenticationState>
  ) {
    this.stores$ = this.cs.storeOptions();
    this.roles$ = this.cs.roleOptions();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.getUser(params['employeeId']);
    });
    this.store.select(getUser).subscribe((user) => {
      console.log(user);
      if (user._id === this.route.snapshot.params['employeeId']) {
        this.authService.getUserActivity(user._id).subscribe((activity) => {
          console.log(activity);
        });
      }
    });
  }

  getUser(id?: string) {
    const userId = id || this.employeeId;
    if (!userId) return;
    this.userService.getUser(userId).pipe(take(1), tap((user) => {
      this.setEmployee(user);
    })).subscribe();
  }

  onStoresSelected(stores: Array<Options>) {
    const storesArray = this.employeeForm.get("stores") as FormArray;
    storesArray.clear();
    stores.forEach((store) => {
      storesArray.push(new FormControl(store.value));
    });
  }

  setEmployee(user: User) {
    this.employee = user;
    this.employeeForm = getUserFormGroup(user);
    this.profileImage = user._images?.[0]?.image;
  }

  saveEmployee() {
    const payload = removeEmptyParams(this.employeeForm.value);
    const obs$ = payload._id
      ? this.userService.updateUser(payload)
      : this.userService.createUser(payload);
    obs$
      .pipe(
        take(1),
        tap((employee) => {
          if (!this.employee) {
            this.router.navigate(["/employee", employee._id]);
            return;
          }
          if (this.modal) {
            this.closeModal("save");
          } else {
            this.setEmployee(employee);
          }
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  closeModal(reason: "cancel" | "save" = "cancel") {
    this.ms.setDismissReason(reason);
    this.ms.hide(this.employee?._id ?? this.employeeId);
  }

  openImageCropper() {
    const modalRef = this.ms.show(ImageCropperModalComponent, {
      id: `${this.employee._id}-image-cropper`,
      class: "modal-lg",
      ignoreBackdropClick: false,
      initialState: {
        refId: this.employee._id,
        type: "user",
        modalId: `${this.employee._id}-image-cropper`,
      },
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason !== "cancel") this.getUser(this.employee._id);
      modalRef.hide();
    });
  }
}
