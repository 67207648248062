import { ChartType } from "./saas.model";

const earningLineChart: ChartType = {
  series: [
    {
      name: "series1",
      data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
    },
  ],
  chart: {
    height: 288,
    type: "line",
    toolbar: "false",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 8,
      opacity: 0.2,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#556ee6"],
  stroke: {
    curve: "smooth",
    width: 3,
  },
};

const salesAnalyticsDonutChart: ChartType = {
  series: [56, 38, 26],
  chart: {
    type: "donut",
    height: 240,
  },
  labels: ["Series A", "Series B", "Series C"],
  colors: ["#556ee6", "#34c38f", "#f46a6a"],
  legend: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "70%",
      },
    },
  },
};

const defaultTimeChart = {
  data: {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Sales Analytics",
        fill: true,
        tension: 0.5,
        backgroundColor: "rgba(85, 110, 230, 0.2)",
        borderColor: "#556ee6",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#556ee6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#556ee6",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [1, 2, 3, 4, 4, 5, 6, 6, 45, 545, 54, 44],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    color: "#8791af",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
      y: {
        ticks: {
          max: 250000,
          min: 0,
          // stepSize: 12,
        },
        grid: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
    },
  },
};

const defaultVendorChart = {
  data: {
    labels: [],
    datasets: [
      {
        label: "Sales Analytics",
        fill: true,
        tension: 0.5,
        backgroundColor: "rgba(85, 110, 230, 0.2)",
        borderColor: "#556ee6",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#556ee6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#556ee6",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    color: "#8791af",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
      y: {
        ticks: {
          max: 250000,
          min: 0,
          // stepSize: 12,
        },
        grid: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
    },
  },
};

const defaultModelChart = {};

const defaultMonthlyChart = {
  chart: {
    height: 200,
    type: "radialBar",
    offsetY: -10,
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: "13px",
          color: undefined,
          offsetY: 60,
        },
        value: {
          offsetY: 22,
          fontSize: "16px",
          color: undefined,
          formatter: (val) => {
            return val + "%";
          },
        },
      },
    },
  },
  colors: ["#556ee6"],
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      shadeIntensity: 0.15,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 65, 91],
    },
  },
  stroke: {
    dashArray: 4,
  },
  series: [67],
  labels: ["Total Sales"],
};
export {
  earningLineChart,
  salesAnalyticsDonutChart,
  defaultTimeChart,
  defaultMonthlyChart,
  defaultVendorChart,
  defaultModelChart,
};
