<div class="container-fluid">
  <app-page-title
    *ngIf="!receivable"
    title="Unpaid Orders"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div
              class="row mb-md-2"
              [ngClass]="{
                'row mb-md-2': !receivable,
                'd-flex justify-content-between w-100': receivable
              }"
            >
              <div class="col-sm-12 col-md-4">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchOrders
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <div  *ngIf="!receivable" class="col-6 col-md-4">
                <div class="d-flex justify-content-center">
                  <div  style="width: 100px;">
                    <select class="form-select form-control-sm mx-2 heading" (change)="viewByStatus($event.target.value)">
                      <option selected>Unpaid</option>
                      <option>Paid</option>
                    </select>
                  </div>
                  
                </div>
              </div>
              <div  *ngIf="receivable" class="col-6 col-md-4">
                <div class="d-flex justify-content-center">
                  <div  style="width: 100px;">
                  <select class="form-select form-control-sm mx-2 heading" [(ngModel)]="store" (ngModelChange)="onStoreChange($event)">
                    @for (option of stores; track $index) {
                    <option [value]="option.value">{{ option.label }}</option>
                    }
                  </select>
                  </div>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive" data-cols="11">
              <table class="table  table-bordered mb-0">
                <thead>
                  <tr>
                    <th
                      *ngIf="!receivable"
                      class="sortable"
                      (click)="onSort('vendor')"
                    >
                      Vendor
                    </th>
                    <th class="sortable" (click)="onSort('status')">Status</th>
                    <th class="sortable" (click)="onSort('invoiceNumber')">
                      Invoice #
                    </th>
                    <th class="sortable" (click)="onSort('date')">
                      Invoice Date
                    </th>
                    <th class="sortable" (click)="onSort('tax')">
                      Tax
                    </th>
                    <th
                     
                      class="sortable"
                      (click)="onSort('shipping')"
                    >
                      Shipping
                    </th>
                    <th class="sortable" (click)="onSort('tax')">
                      Amount Paid
                    </th>
                    <th
                     
                      class="sortable"
                      (click)="onSort('total')"
                    >
                      Amount Due
                    </th>
                    <th
                     
                      class="sortable"
                      (click)="onSort('total')"
                    >
                      To Pay
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @if(loading$) {
                  <tr>
                    <td colspan="11">
                      <div
                        style="
                          width: 100%;
                          min-height: 200px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 15px;
                        "
                      >
                        <div
                          role="status"
                          class="spinner-border text-success m-1"
                          style="width: 75px; height: 75px"
                        >
                          <span class="sr-only">Loading Payables...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (payable of payables; track payable; let i = $index) {
                  <tr>
                    <td *ngIf="!receivable">
                      {{ payable.vendor?.name || "-" }}
                    </td>
                    <td class="editable" (click)="editField('status', i)" [ngClass]="{'editing': isEditing('status', i)}">
                      <ng-container *ngIf="!isEditing('status', i); else statusEdit">
                        {{ payable.status }}
                      </ng-container>
                      <ng-template #statusEdit>
                        <input type="text" 
                               [(ngModel)]="payable.status"
                               (blur)="saveField(payable, 'status')"
                               (keyup.enter)="saveField(payable, 'status')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td class="editable" (click)="editField('invoiceNumber', i)" [ngClass]="{'editing': isEditing('invoiceNumber', i)}">
                      <ng-container *ngIf="!isEditing('invoiceNumber', i); else invoiceNumberEdit">
                        {{ payable.invoiceNumber }}
                      </ng-container>
                      <ng-template #invoiceNumberEdit>
                        <input type="text" 
                               [(ngModel)]="payable.invoiceNumber"
                               (blur)="saveField(payable, 'invoiceNumber')"
                               (keyup.enter)="saveField(payable, 'invoiceNumber')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td>
                      {{ payable.date | date : "yyyy-MM-dd" }}

                    </td>
                    <td class="editable" (click)="editField('tax', i)" [ngClass]="{'editing': isEditing('tax', i)}">
                      <ng-container *ngIf="!isEditing('tax', i); else taxEdit">
                        {{ payable.tax | currency }}
                      </ng-container>
                      <ng-template #taxEdit>
                        <input type="number" 
                               [(ngModel)]="payable.tax"
                               (blur)="saveField(payable, 'tax')"
                               (keyup.enter)="saveField(payable, 'tax')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td class="editable" (click)="editField('shipping', i)" [ngClass]="{'editing': isEditing('shipping', i)}">
                      <ng-container *ngIf="!isEditing('shipping', i); else shippingEdit">
                        {{ payable.shipping | currency }}
                      </ng-container>
                      <ng-template #shippingEdit>
                        <input type="number" 
                               [(ngModel)]="payable.shipping"
                               (blur)="saveField(payable, 'shipping')"
                               (keyup.enter)="saveField(payable, 'shipping')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td class="editable" (click)="editField('total', i )" [ngClass]="{'editing': isEditing('total', i)}">
                      <ng-container *ngIf="!isEditing('total', i); else totalEdit">
                        {{ payable.total | currency }}
                      </ng-container>
                      <ng-template #totalEdit>
                        <input type="number" 
                               [(ngModel)]="payable.total"
                               (blur)="saveField(payable, 'total')"
                               (keyup.enter)="saveField(payable, 'total')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td class="editing">
                      {{ payable.amountDue | currency }}

                    </td>
                    <td class="editable" (click)="editField('payments', i )" [ngClass]="{'editing': isEditing('payments', i)}">
                      <ng-container *ngIf="!isEditing('payments', i); else paymentsEdit">
                        {{ payments[payable._id] | currency }}
                      </ng-container>
                      <ng-template #paymentsEdit>
                        <input type="number" 
                               [(ngModel)]="payments[payable._id]"
                               (blur)="saveField(payable, 'payments')"
                               (keyup.enter)="saveField(payable, 'payments')"
                               class="form-control form-control-sm">
                      </ng-template>
                    </td>
                    <td class="action">
                      <i class="fas fa-eye" [routerLink]="['/payables', payable._id]"></i>
                    </td>
                  </tr>
                  }}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  unpaid vendor orders
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [itemsPerPage]="pagination.take"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content> </ng-template>
