@if(!adding) {
<div [class.container-fluid]="!child">
  <app-page-title
    *ngIf="!child"
    title="Orders"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div [class.card]="!child">
      <div [class.card-body]="!child">
        <div [class.row]="!child">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchOrders
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading"
                    >
                      <option *ngIf="child" [ngValue]="5">5</option>
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th class="sortable" (click)="onSort('invoiceNumber')">
                      Invoice #
                    </th>
                    <th class="sortable" (click)="onSort('status')">Status</th>
                    <th class="sortable" (click)="onSort('dateAdded')">Date</th>

                    <th class="sortable" (click)="onSort('tax')">Tax</th>
                    <th class="sortable" (click)="onSort('shipping')">
                      Shipping
                    </th>
                    <th class="sortable" (click)="onSort('total')">Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @if(loading$) {
                  <tr>
                    <td align="center" colspan="8">
                      <div
                        style="
                          width: 100%;
                          min-height: 200px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 15px;
                        "
                      >
                        <div
                          role="status"
                          class="spinner-border text-success m-1"
                          style="width: 75px; height: 75px"
                        >
                          <span class="sr-only">Loading Payables...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  } @else { @if(noResults) {
                  <tr>
                    <td colspan="100%">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                        style="width: 100%; min-height: 100px; opacity: 0.3"
                      >
                        <h2 class="mt-3">
                          No orders found
                          {{ search ? "for the search " + search : "" }}
                        </h2>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (order of orders; track order) {

                  <tr>
                    <td>
                      <a [routerLink]="['/orders', order._id]">{{
                        order.invoiceNumber
                      }}</a>
                    </td>

                    <td>
                      {{ order.status }}
                    </td>
                    <td>
                      {{ order.dateAdded | date : "MM/dd/yyyy" }}
                    </td>
                    <td>{{ order.tax | currency }}</td>
                    <td>{{ order.shipping | currency }}</td>
                    <td>{{ order.total | currency }}</td>
                    <td class="action icon-cell">
                      <div class="dropdown" dropdown>
                        <i data-toggle="dropdown" dropdownToggle aria-expanded="false" class="mdi mdi-dots-vertical dropdown-toggle"></i>
                        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
                          <a
                          class="dropdown-item"
                          href="javascript: void(0);"
                          [routerLink]="['/orders', order._id, 'invoice']"
                          ><i class="fas fa-print"></i> Print</a
                        >
                          <a
                          class="dropdown-item"
                          href="javascript: void(0);"
                          (click)="onEdit(order)"
                          ><i class="fas fa-edit"></i> Update</a
                        >
                        <a
                            class="dropdown-item"
                            href="javascript: void(0);"
                            (click)="onDelete(order)"
                            ><i class="fas fa-trash-alt"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
<!-- 
                  <button
                  class="btn btn-secondary dropdown-toggle rounded-start"
                  type="button"
                  id="*dropdownMenuButton"
                  dropdownToggle
                >
                  {{ selectedMonth?.label || "Select Month" }}
                  <i class="mdi mdi-chevron-down"></i>
                </button> -->

                  @if(order._products?.length) {
                  <!-- <tr>
                    <th></th>
                    <th>Vendor</th>
                    <th>Model</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th></th>

                  </tr> -->

                  <tr *ngFor="let _product of order._products; let i = index">
                    <td>
                      @if(_product._images.length > 0) {
                      <a (click)="openLightbox(order, i)">
                        <img
                          [src]="_product._images[0].url"
                          alt="Product Image"
                          style="width: 100px"
                        />
                      </a>
                      }
                    </td>

                    <td class="bg-light">
                      <a [routerLink]="['/vendors', _product?.vendorId]">{{
                        _product?.vendorName
                      }}</a>
                    </td>
                    <td class="bg-light">{{ _product?.model }}</td>
                    <td class="bg-light">{{ _product?.color }}</td>
                    <td class="bg-light">{{ _product?.size }}</td>
                    <td class="bg-light">{{ _product?.type }}</td>
                    <td class="bg-light"></td>
                  </tr>
                  } } }}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              *ngIf="pagination.total"
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  orders
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      @if(patientId) {
      <button
        class="btn btn-primary"
        [class.mt-3]="child"
        (click)="adding = true"
      >
        Add Order
      </button>
      } @else if(!child) {
      <button
        class="btn btn-primary"
        [class.mt-3]="child"
        [routerLink]="['/orders/new']"
      >
        Add Order
      </button>
      } @else {
      <button class="btn btn-primary" [class.mt-3]="child" (click)="onAdd()">
        Add Order
      </button>
      }
    </div>
  </div>
</div>
} @else {
<app-new-patient-order
  (orderSaved)="onComplete($event)"
  [patientId]="patientId"
  [child]="child"
  [minimal]="true"
  [externalSave]="true"
></app-new-patient-order>
}
