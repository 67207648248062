<div class="container">
  <div *ngIf="loading" class="row">
    <div class="col-12">
      <div class="progress animated-progess mb-3">
        <div
          role="progressbar"
          [aria-valuenow]="progress$"
          aria-valuemin="0"
          aria-valuemax="100"
          class="progress-bar"
        ></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      @if(!standalone) {
      <div class="card">
        <div class="card-body">
          <div>
            <dropzone
              class="dropzone"
              [config]="dropzoneConfig"
              [message]="'Drop files here or click to upload.'"
              (thumbnail)="onThumbnailGenerated($event)"
            >
            </dropzone>
          </div>
        </div>
      </div>
      } @else {

      <dropzone
        class="dropzone"
        [config]="dropzoneConfig"
        [message]="'Drop files here or click to upload.'"
        (thumbnail)="onThumbnailGenerated($event)"
      >
      </dropzone>
      }
    </div>
  </div>
</div>
