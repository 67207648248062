import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Options } from "chartist";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { debounceTime, Observable, Subject } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { Vendor } from "src/app/core/models";
import { PayablesService } from "src/app/core/services/payables.service";
import { VendorService } from "src/app/core/services/vendor.service";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationParams } from "src/app/types/pagination";

@Component({
  selector: "app-payables",
  templateUrl: "./payables.component.html",
  styleUrls: ["./payables.component.css"],
})
export class PayablesComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;
  @Input() child: boolean = false;
  @Input() receivables: boolean = false;
  @Input() store: number;

  vendor: Vendor;
  items: Array<any> = [];
  _items: Array<any> = [];
  storeMap: Record<string, string> = {};
  stores: Array<Options> = [];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Payables", url: "/payables" },
  ];

  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "name";
  dir = "asc";
  search$ = new Subject<void>();
  loading$ = true;
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;
  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      col: this.col,
      dir: this.dir,
      ...(this.receivables ? { receivables: true } : {}),
      ...(this.store ? { store: this.store } : {}),
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private vS: VendorService,
    private payablesService: PayablesService,
    private route: ActivatedRoute,
    private ms: BsModalService
  ) {}

  ngOnInit() {
    this.getPayableGroups();
    this.search$
      .pipe(debounceTime(500))
      .subscribe(() => this.getPayableGroups());
  }

  getPayableGroups() {
    this.loading$ = true;
    this.payablesService
      .getPayableCounts(this.queryParams)
      .subscribe((items) => {
        this.items = items;
        this.pagination.total = items.length;
        this.noResults = !items.length;
        this.sortPageItems();
      });
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.sortPageItems();
  }

  onPageSizeChange(take: number) {
    this.loading$ = true;
    this.pagination.skip = 0;
    this.pagination.take = take;
    this.sortPageItems();
  }

  onSort(col: string) {
    this.loading$ = false;

    if (col === this.col) {
      this.dir = this.dir === "asc" ? "desc" : "asc";
    } else {
      this.dir = "asc";
    }
    this.col = col;
    this.sortPageItems();
  }

  sortPageItems() {
    this._items = this.items
      .reduce((acc, item) => {
        if (!this.search?.length) return [...acc, item];
        const { name, paid, unpaid } = item;
        const match = [name, paid, unpaid].some((v) =>
          v.toString().toLowerCase().includes(this.search?.toLowerCase())
        );
        if (match) return [...acc, item];
        return acc;
      }, [])
      .sort((a, b) => {
        return this.dir === "asc"
          ? a[this.col] > b[this.col]
            ? 1
            : -1
          : b[this.col] > a[this.col]
          ? 1
          : -1;
      })
      .slice(this.pagination.skip, this.pagination.skip + this.pagination.take);
    this.noResults = !this._items.length;
    this.loading$ = false;
    console.log(this.loading$);
  }
}
