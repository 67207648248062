@if((dataLayout$ | async) === 'vertical'){
<app-vertical></app-vertical>
} @if((dataLayout$ | async)==='horizontal'){
<app-horizontal></app-horizontal>
}
<!--
<div style="position: fixed; bottom: 0; right: 0; left: 250px; z-index: 1000">
  <progressbar
    [value]="75"
    [striped]="true"
    [animate]="true"
    class="progress-striped active"
  ></progressbar>
</div> -->
