import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) return null;

    const phone = value.toString();
    const cleaned = phone.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  }
}
