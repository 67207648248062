import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { tokenExpiration } from "src/app/store/Authentication/authentication.actions";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // tap((res) => {
      //   if (res instanceof HttpResponse) {
      //     // Modify the response here
      //     console.log("Response:", res);
      //   }
      // }),
      distinctUntilChanged(),
      catchError((err) => {
        if (err.status === 401) {
          this.store.dispatch(tokenExpiration(err.status));
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
