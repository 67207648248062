<div *ngIf="patient" class="mx-0 px-0 view-only">
  <div [formGroup]="patientForm" class="row">
    <div class="col-12">
      <div class="row">
        <div [class.col-md-4]="newPatient" [class.col-md-6]="!newPatient">
          <div class="form-floating mb-3">
            <input type="text" formControlName="firstName" class="form-control form-select disabled" id="firstName" placeholder="First Name" [class.readonly]="readonly" />
            <label for="firstName">First Name</label>
          </div>
        </div>
        <div [class.col-md-4]="newPatient" [class.col-md-6]="!newPatient">
          <div class="form-floating mb-3">
            <input type="text" formControlName="lastName" class="form-control form-select disabled" id="lastName" placeholder="Last Name" [class.readonly]="readonly" />
            <label for="lastName">Last Name</label>
          </div>
        </div>
        <div *ngIf="newPatient" class="col-md-2">
          <div class="form-floating mb-3">
            <input type="text" formControlName="dob" id="dob" class="form-control form-select disabled" [class.readonly]="readonly" />
            <label for="dob">DOB</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div>
          <div class="form-floating mb-3">
            <input type="text" formControlName="address" class="form-control form-select disabled" id="address" [class.readonly]="readonly" />
            <label for="address">Address</label>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-floating mb-3">
                <input type="text" formControlName="city" class="form-control form-select disabled" id="city" [class.readonly]="readonly" />
                <label for="city">City</label>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-floating mb-3">
                <input type="text" formControlName="state" class="form-control form-select disabled" id="state" [class.readonly]="readonly" />
                <label for="state">State</label>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-floating mb-3">
                <input type="text" formControlName="zip" class="form-control form-select disabled" id="zip" [class.readonly]="readonly" />
                <label for="zip">Zip</label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6 col-md-3">
              <div class="form-floating mb-3">
                <input type="tel" formControlName="phone" class="form-control form-select disabled" id="phone" [class.readonly]="readonly" />
                <label for="phone">Home Phone</label>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-floating mb-3">
                <input type="tel" formControlName="phone2" class="form-control form-select disabled" id="phone2" [class.readonly]="readonly" />
                <label for="phone2">Work Phone</label>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-floating mb-3">
                <input type="tel" formControlName="cell" class="form-control form-select disabled" id="cell" [class.readonly]="readonly" />
                <label for="cell">Cell Phone</label>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-floating mb-3">
                <input type="email" formControlName="email" class="form-control form-select disabled" id="email" [class.readonly]="readonly" />
                <label for="email">Email</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>