import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { Payable, PayableGroup, PayableResponse } from "../models/api.model";
import { Pagination } from "src/app/types/pagination";
import { Params } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { getStore } from "src/app/store/Authentication/authentication-selector";

@Injectable({
  providedIn: "root",
})
export class PayablesService {
  private base_url = `${environment.apiDomain}/payables`;

  constructor(
    private http: HttpClient,
    private store: Store<AuthenticationState>
  ) {}

  getPayableCounts(params?: Params): Observable<any> {
    return this.store.select(getStore).pipe(
      switchMap((store) => {
        return this.http.get<Array<any>>(
          `${this.base_url}/distributor/counts`,
          {
            params: { ...(params ?? {}), store },
          }
        );
      })
    );
  }

  getReceivablesCounts(params?: Params): Observable<any> {
    return this.store.select(getStore).pipe(
      switchMap((store) => {
        const activeStore = params?.store ?? store;
        const borrowingFromStore = activeStore === 1 ? 2 : 1;
        return this.http.get<Array<any>>(
          `${this.base_url}/receivables/counts`,
          {
            params: {
              ...(params ?? {}),
              store: activeStore,
              borrowingFromStore,
            },
          }
        );
      })
    );
  }

  getPayables(params?: Params): Observable<Pagination<PayableResponse>> {
    return this.store.select(getStore).pipe(
      switchMap((store) => {
        return this.http.get<Pagination<PayableResponse>>(
          `${this.base_url}/orders`,
          {
            params: { ...(params ?? {}), store: params?.store ?? store },
          }
        );
      })
    );
  }

  getPayable(id: string): Observable<Payable> {
    return this.http.get<Payable>(`${this.base_url}/orders/${id}`);
  }

  getPayableGroups(params?: Params): Observable<Pagination<PayableGroup>> {
    return this.store.select(getStore).pipe(
      switchMap((store) => {
        return this.http.post<Pagination<PayableGroup>>(
          `${this.base_url}/groups`,
          { ...(params ?? {}), store: params?.store ?? store }
        );
      })
    );
  }

  getPayableGroup(id: string): Observable<PayableGroup> {
    return this.http.get<PayableGroup>(`${this.base_url}/groups/${id}`);
  }
}
