import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Product } from "src/app/core/models";
import { getOrderFrameFormGroup } from "src/app/shared/utils/forms";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import {
  FrameOptions,
  FramesService,
} from "src/app/core/services/frames.service";
import { InventoryOptions, Options } from "src/app/types/select";
import { FormGroup } from "@angular/forms";
import { calculateProductPrice } from "src/app/core/utils/product";
import { CurrencyPipe } from "@angular/common";
import { isEqual } from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-order-minimal",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderMinimalComponent implements OnInit, OnDestroy {
  @ViewChild("manualEntryModal") manualEntryModal: TemplateRef<any>;
  @Output() productChange = new EventEmitter<Partial<Product>>();
  @Output() cancel = new EventEmitter<void>();
  @Input() formGroup: FormGroup;
  @Input() readOnly: boolean = false;
  @Input() manual: boolean = false;
  @Input() manualEntry: boolean = false;
  @Input() product: Partial<Product>;
  productForm: FormGroup;
  destroy$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();
  options: FrameOptions = {
    types: [],
    colors: [],
    sizes: [],
    models: [],
  };
  vOptions: Array<Options> = [];
  optionsSelect$ = new Subject<void>();
  defaultTypeOptions = ["RX", "SUN", "OTHER"].map((type) => ({
    _id: null,
    label: type,
    value: type,
    retail: undefined,
    pricingFormula: undefined,
    cost: undefined,
  }));

  vendorOptions$: Observable<Array<Options>> = of([]);
  modelOptions$: Observable<Array<InventoryOptions>> = of([]);
  colorOptions$: Observable<Array<InventoryOptions>> = of([]);
  sizeOptions$: Observable<Array<InventoryOptions>> = of([]);
  typeOptions$: Observable<Array<InventoryOptions>> = of([]);

  orderTypeOptions$: Observable<Array<Options>> = of(
    ["O", "N", "A", "F"].map((type, idx) => ({
      _id: idx,
      label: type,
      value: type,
    }))
  );

  frameVendor: string;
  frameId: string | number;
  selectedInventoryOption: InventoryOptions;

  cp = new CurrencyPipe("en-US");

  previousPayload: any;
  modal: boolean = false;
  modalId: string;
  patientId: string;
  orderId: string;
  previousValue: Partial<Product>;
  modalRef: BsModalRef;
  frameId$: Subject<string> = new Subject();
  notified: boolean = false;

  get vendorControl() {
    return this.productForm?.get("vendor");
  }
  get modelControl() {
    return this.productForm?.get("model");
  }
  get colorControl() {
    return this.productForm?.get("color");
  }
  get sizeControl() {
    return this.productForm?.get("size");
  }
  get typeControl() {
    return this.productForm?.get("type");
  }
  get quantityControl() {
    return this.productForm?.get("quantity");
  }
  get taxControl() {
    return this.productForm?.get("tax");
  }
  get shippingControl() {
    return this.productForm?.get("shipping");
  }

  get modalVendorText() {
    const { colors, sizes, models } = this.options;
    const { vendor, model, color, size, type } = this.productForm.getRawValue();
    if (vendor && model && color && !size) {
      const vendorName = this.vOptions.find(
        ({ value }) => value === vendor
      )?.label;
      const modelName = models.find(({ value }) => value === model)?.label;
      const colorName = colors.find(({ value }) => value === color)?.label;
      return `${vendorName} model ${modelName} in ${colorName} has no known size options in inventory.`;
    }
    if (vendor && model && !color) {
      const vendorName = this.vOptions.find(
        ({ value }) => value === vendor
      )?.label;
      const modelName = models.find(({ value }) => value === model)?.label;
      return `${vendorName} model ${modelName} has no known color options in inventory.`;
    }
    if (vendor && !model) {
      const vendorName = this.vOptions.find(
        ({ value }) => value === vendor
      )?.label;
      return `${vendorName} has no known model options in inventory.`;
    }
    return "This product does not exist in inventory or there are no additional frame options.";
  }

  get value() {
    const { tax, shipping, total } = this.productForm.value;
    const productTotal = this.getNumberValue(total);
    const productTax = this.getNumberValue(tax);
    const productShipping = this.getNumberValue(shipping);
    return {
      ...this.productForm.getRawValue(),
      tax: productTax,
      shipping: productShipping,
      total: productTotal,
    };
  }

  constructor(
    private fS: FramesService,
    private route: ActivatedRoute,
    private ms: BsModalService
  ) {
    this.route.params.subscribe((params) => {
      this.patientId = params.patientId;
      this.orderId = params.orderId;
    });
    this.optionsSelect$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {})
      )
      .subscribe(() =>
        this.getOptions({
          vendor: this.vendorControl.value,
          model: this.modelControl.value,
          size: this.sizeControl.value,
          color: this.colorControl.value,
        })
      );
  }

  ngOnInit() {
    this.productForm = this.formGroup ?? getOrderFrameFormGroup(this.product);
    if (this.product) {
      this.getOptions({
        vendor: this.vendorControl.value,
        model: this.modelControl.value,
        size: this.sizeControl.value,
        color: this.colorControl.value,
      });
    }
    this.productForm.valueChanges
      .pipe(debounceTime(1000), takeUntil(this.destroy$))
      .subscribe((value) => {
        const hasAnyChanges = !isEqual(value, this.previousValue);
        console.log(value, hasAnyChanges);
        if (!this.formGroup && hasAnyChanges) {
          this.productChange.emit({ ...value, key: this.getProductKey(value) });
        }
        this.previousValue = value;
      });
    this.fS
      .getFrameVendorOptions()
      .pipe(take(1))
      .subscribe((vendors) => {
        this.vOptions = vendors;
        this.vendorOptions$ = of(vendors);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getProductKey(product: Partial<Product>) {
    const { model, color, size, store } = product;
    let keyValues = [model, color, size, store].join(' ');
    const key = keyValues
      .split('')
      .reduce((acc, value) => {
        if (!/[a-zA-Z0-9 ]/gi.test(value)) return acc;
        return acc + value;
      }, '')
      .replace(/\s+/g, '_');
    return key;
  }

  getOptions(params: Params) {
    console.log(this.manualEntry)
    if (this.manualEntry) return;
    this.fS
      .getFrameSelectionOptions(params)
      .pipe(
        take(1),
        tap(({ types, colors, sizes, models }) => {
          this.options = {
            types,
            colors,
            sizes,
            models,
          };
          const opts = [...types, ...this.defaultTypeOptions].reduce(
            (acc, v) => {
              if (!acc.find((o) => o.value === v.value)) {
                acc.push(v);
              }
              return acc;
            },
            []
          );
          const tps = this.sizeControl.value ? opts : [];
          this.typeOptions$ = of(tps);
          this.colorOptions$ = of(colors);
          this.sizeOptions$ = of(sizes);
          this.modelOptions$ = of(models);
          this.checkForNoOptions();
        })
      )
      .subscribe();
  }

  getNumberValue(value: string | number) {
    if (typeof value === "number") return value;
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }

  checkForNoOptions() {
    if (this.manualEntry) return;
    const { colors, sizes, models } = this.options;
    const { color, model, vendor } = this.productForm.getRawValue();
    if (
      (color && !sizes.length) ||
      (model && !colors.length) ||
      (vendor && !models.length)
    ) {
      this.openManualEntryModal();
    }
  }

  calculateTotal(option?: InventoryOptions) {
    // Clear both acc areas
    if (!option) {
      this.productForm.setValue({
        ...this.productForm.getRawValue(),
        cost: 0,
        total: 0,
        retail: 0,
      } as any);
      return;
    }
    this.selectedInventoryOption = option;
    const { cost, pricingFormula } = option;
    if (!cost || !pricingFormula) return;
    const retail = calculateProductPrice({ cost, pricingFormula });
    this.setTotal(retail, cost, pricingFormula);
  }

  calculateTotalFromForm() {
    const { cost, pricingFormula } = this.productForm.getRawValue();
    if (!cost || !pricingFormula) return;
    const retail = calculateProductPrice({ cost, pricingFormula });
    this.setTotal(retail, cost, pricingFormula);
  }

  setTotal(retail: number, cost: number, pricingFormula: string) {
    const quantity = this.quantityControl.value;
    const price = retail * quantity;
    const { shipping: orderShipping, tax: orderTax } =
      this.productForm.getRawValue();
    const tax = this.getNumberValue(orderTax);
    const shipping = this.getNumberValue(orderShipping);
    const p = {
      ...this.productForm.getRawValue(),
      quantity,
      cost,
      pricingFormula,
      retail,
      total: Number((price + shipping + tax).toFixed(2)),
      tax: Number(tax.toFixed(2)),
      shipping: Number(shipping.toFixed(2)),
    };
    if (isEqual(p, this.productForm.getRawValue())) return;
    this.productForm.setValue(p);
  }

  onVendorSelect(vendorId: string) {
    console.log(vendorId);
    this.frameVendor = vendorId;
    this.modelControl.reset();
    this.colorControl.reset();
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onModelSelect(model: string) {
    console.log(model);
    const o = this.options.models.find(({ value }) => value === model);
    this.frameId = o?._id;
    if(this.frameId && this.notified) this.notified = false;
    o && this.calculateTotal(o);
    this.colorControl.reset();
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onColorSelect(color: string) {
    const o = this.options.colors.find(({ value }) => value === color);
    this.frameId = o?._id;
    if(this.frameId && this.notified) this.notified = false;
    o && this.calculateTotal(o);
    this.sizeControl.reset();
    this.typeControl.reset();
    this.optionsSelect$.next();
  }

  onSizeSelect(size: string) {
    const o = this.options.sizes.find(({ value }) => value === size);
    this.frameId = o?._id;
    if(this.frameId && this.notified) this.notified = false;
    o && this.calculateTotal(o);
    this.optionsSelect$.next();
  }

  onTypeSelect(type: string) {
    if(type === 'RX') {
      this.productForm.get('tax').setValue(0);
    }
    this.calculateTotalFromForm();
  }

  openManualEntryModal() {
    if(this.notified) return;
    this.notified = true;
    this.modalRef = this.ms.show(this.manualEntryModal, { class: "modal-sm" });
  }

  onCancelManualEntry() {
    this.cancel.emit();
    this.modalRef.hide();
  }

  obj(i: any): any {
    return Object.keys(i).reduce((acc, v) => {
      if (i[v]) {
        acc[v] = i[v];
      }
      return acc;
    }, {});
  }

}
