import { Component, ViewChild } from "@angular/core";
import { VendorComponent } from "../vendor/vendor.component";
import { Vendor } from "src/app/core/models";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Observable, of, Subject, debounceTime, tap, take } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { VendorService } from "src/app/core/services/vendor.service";
import { getOrderFormGroup } from "src/app/shared/utils/forms";
import { Pagination, PaginationParams } from "src/app/types/pagination";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";

@Component({
  selector: "app-vendors",
  templateUrl: "./vendors.component.html",
  styleUrls: ["./vendors.component.css"],
})
export class VendorsComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  vendorForm = getOrderFormGroup();
  vendors: Array<Vendor & { distributors: Array<Vendor> }> = [];
  vendorSub$: Observable<Pagination<Vendor>> = of({
    items: [],
    offset: 0,
    limit: 100,
    total: 0,
  });
  col = "name";
  dir = "asc";
  expandedRows: Record<number, boolean> = {};
  page: number = 1;
  searchId: number;
  modalId: string | number;
  search = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;

  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Vendors", url: "/vendors" },
  ];

  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return {
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
    };
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private vs: VendorService,
    private ms: BsModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getVendors();

    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.page = 1;
          this.pagination.skip = 0;
          this.getVendors();
        })
      )
      .subscribe();
  }

  getVendors() {
    this.vs
      .getVendorGroups(removeEmptyParams(this.queryParams))
      .subscribe(({ items, offset, limit, total }) => {
        this.vendors = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
        this.noResults = !items.length;
      });
  }

  expandRow(index: number | string) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getVendors();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getVendors();
  }

  onSort(col: string) {
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getVendors();
  }

  openModal(vendor: Vendor) {
    this.modalId = vendor._id;
    const modalRef = this.ms.show(VendorComponent, {
      id: this.modalId,
      class: "modal-lg",
      initialState: {
        vendor,
      },
    });
    modalRef.onHide.pipe(take(1)).subscribe();
  }

  onEdit(vendor: Vendor) {
    const modalRef = this.ms.show(VendorComponent, {
      id: vendor._id,
      initialState: { vendorRef: vendor, modal: true },
      class: "modal-xl",
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason === "save") {
        this.getVendors();
      }
    });
  }
}
