import { Pipe, PipeTransform } from "@angular/core";
import { Prescription } from "../models";

@Pipe({
  name: "rxExpired",
  standalone: true,
})
export class RxExpiredPipe implements PipeTransform {
  transform(value: Prescription, ...args: unknown[]): unknown {
    const { oneYearExpiration, examDate } = value;
    const expireYears = oneYearExpiration ? 1 : 2;
    const xDate = new Date(examDate);
    const yDate = new Date(
      xDate.setFullYear(xDate.getFullYear() + expireYears)
    );
    return yDate < new Date() ? "Expired" : "Active";
  }
}
