import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { concatMap, debounceTime, Subject, tap, take, Observable } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { User } from "src/app/core/models";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { ConfigService } from "src/app/core/services/config.service";
import { UserService } from "src/app/core/services/user.service";
import { atLeastOne } from "src/app/core/utils/validators";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationParams } from "src/app/types/pagination";
import { Options } from "src/app/types/select";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.css"],
})
export class EmployeesComponent implements OnInit {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;

  users: Array<User> = [];
  storeMap: Record<string, string> = {};
  stores: Array<Options> = [];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Users", url: "/employees" },
  ];

  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "";
  dir = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;

  employeeForm = new FormGroup({
    _id: new FormControl(null),
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    role: new FormControl(null, Validators.required),
    stores: new FormArray([], [atLeastOne]),
    disabled: new FormControl(false),
    anyIp: new FormControl(false),
    password: new FormControl(null, Validators.required),
    initials: new FormControl(null, Validators.required),
  });

  loading$: boolean = true;

  get startIndex() {
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private cs: ConfigService,
    private ms: BsModalService
  ) {
    this.stores$ = this.cs.storeOptions();
    this.roles$ = this.cs.roleOptions();
  }

  ngOnInit() {
    this.authService
      .getStores()
      .pipe(
        take(1),
        tap((stores) => {
          console.log(stores);
          this.stores = stores.map((store) => ({
            id: store._id,
            label: store.label,
            value: store._id,
          }));
          this.storeMap = stores.reduce(
            (acc, curr) => ({ ...acc, [curr._id]: curr.label }),
            {}
          );
        }),
        concatMap(() => this.getUsers())
      )
      .subscribe();
    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.loading$ = true;
          this.page = 1;
          this.pagination.skip = 0;
        }),
        concatMap(() => this.getUsers())
      )
      .subscribe();

    this.employeeForm.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  getStoreNames(user: User) {
    return user.stores.map((store) => this.storeMap[store]).join(", ");
  }

  getUsers() {
    this.loading$ = true;
    return this.userService.getUsers(this.queryParams).pipe(
      take(1),
      tap((res) => {
        console.log(res);
        if (!res.items) return;
        const { items, offset, limit, total } = res;
        this.users = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
        this.loading$ = false;
      })
    );
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getUsers().subscribe();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getUsers().subscribe();
  }

  openModal(user?: User) {
    this.modalId = new Date().getTime();
    if (user?._id) {
      this.employeeForm.patchValue(user);
      const storesArray = this.employeeForm.get("stores") as FormArray;
      storesArray.clear();
      user.stores.forEach((store) => {
        storesArray.push(new FormControl(store));
      });
      this.employeeForm.get("password").removeValidators(Validators.required);
    } else {
      this.employeeForm.get("password").setValidators([Validators.required]);
    }
    const modalRef = this.ms.show(this.content, {
      id: this.modalId,
      class: "modal-lg",
    });
    modalRef.onHide
      .pipe(
        take(1),
        tap(() => this.employeeForm.reset())
      )
      .subscribe();
  }

  onCancel() {
    this.ms.hide(this.modalId);
  }

  onUpdate() {
    console.log(this.employeeForm.value);
    this.userService
      .updateUser(this.employeeForm.value as User)
      .pipe(
        take(1),
        concatMap(() => this.getUsers())
      )
      .subscribe(() => this.ms.hide(this.modalId));
  }

  onCreate() {
    console.log(this.employeeForm.value);
    this.userService
      .createUser(this.employeeForm.value)
      .pipe(
        take(1),
        concatMap(() => this.getUsers())
      )
      .subscribe(() => this.ms.hide(this.modalId));
  }

  onSort(col: string) {
    console.log(col);
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getUsers().subscribe();
  }

  onStoresSelected(stores: Array<Options>) {
    const storesArray = this.employeeForm.get("stores") as FormArray;
    storesArray.clear();
    stores.forEach((store) => {
      storesArray.push(new FormControl(store.value));
    });
  }
}
