import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { FavoriteLine } from "../models/favorite-line";
import { Pagination } from "src/app/types/pagination";
import { Params } from "@angular/router";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Store } from "@ngrx/store";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { concatMap } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  baseUrl = `${environment.apiDomain}/favorite-lines`;

  constructor(
    private http: HttpClient,
    private store: Store<AuthenticationState>
  ) {}

  getFavorites(params?: Params) {
    return this.store.select(getStore).pipe(
      concatMap((store) =>
        this.http.get<Pagination<FavoriteLine>>(`${this.baseUrl}/${store}`, {
          params,
        })
      )
    );
  }

  getFavorite(id: string) {
    return this.http.get<FavoriteLine>(`${this.baseUrl}/${id}`);
  }

  createFavorite(favorite: Partial<FavoriteLine>) {
    return this.http.post<FavoriteLine>(`${this.baseUrl}`, favorite);
  }

  updateFavorite(id: string, favorite: Partial<FavoriteLine>) {
    return this.http.put<FavoriteLine>(`${this.baseUrl}/${id}`, favorite);
  }

  deleteFavorite(id: string) {
    return this.http.delete<FavoriteLine>(`${this.baseUrl}/${id}`);
  }
}
