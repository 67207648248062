import { Component } from '@angular/core';

@Component({
  selector: 'app-payable',
  templateUrl: './payable.component.html',
  styleUrls: ['./payable.component.css']
})
export class PayableComponent {

}
