<div class="container-fluid">
  <!-- Row -->
  <div class="row">
    <div class="col-12">
      <!-- col -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="img-container">
                @if (img?.image) {
                <image-cropper
                  [imageURL]="img.image"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="aspectRatio"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  (cropperReady)="ready($event)"
                >
                </image-cropper>
                }
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center my-3">
                <button
                  (click)="rotateRight()"
                  class="btn btn-secondary btn-sm ms-1"
                >
                  <i class="dripicons-clockwise"></i>
                </button>
                <button
                  (click)="useImage()"
                  class="btn btn-primary btn-sm ms-1"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div *ngIf="croppedImage" class="text-center">
                      <img
                        [src]="croppedImage"
                        alt="Cropped image"
                        style="max-width: 60%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</div>
