import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, switchMap, take } from "rxjs";

import { AuthenticationService } from "../services/auth.service";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Store } from "@ngrx/store";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  getContentTypes(url: string, method: string) {
    if (url.includes("files") && ["PUT", "POST"].includes(method)) {
      return {};
    }

    if (url.includes("s3") && method === "GET") {
      return {
        "Content-Type": "image/png",
      };
    }

    return { "Content-Type": "application/json" };
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const { url, method } = request;
    const contentType = this.getContentTypes(url, method);

    // console.log(contentType);

    return this.store
      .select(({ user, token }) => ({ user, token }))
      .pipe(
        take(1),
        switchMap(({ user, token }) => {
          const tk = token ?? localStorage.getItem("authToken");
          if (tk && !url.includes("s3")) {
            const clonedRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${tk}`,
                ...contentType,
              },
            });
            return next.handle(clonedRequest);
          }
          return next.handle(request);
        })
      );
  }
}
