import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { Log, StockOrderPayment } from "src/app/core/models";

export const getControl = <T = unknown>(
  control: FormGroup | FormControl,
  controlName: string
): T | undefined => {
  return control.get(controlName) as T;
};

export const getControlValue = (value: string | number) => {
  return value !== null ? value : null;
};

export const getDirtyValues = (form: FormGroup, isArray?: boolean) => {
  let dirtyValues = {};
  Object.keys(form.controls).forEach((key) => {
    const currentControl = form.controls[key];
    if (currentControl.dirty) {
      if (currentControl instanceof FormArray) {
        dirtyValues[key] = currentControl.controls
          .filter((control) => control.dirty)
          .map((control) => control.value);
      } else {
        dirtyValues[key] = currentControl.value;
      }
    }
  });
  return dirtyValues;
};

export const getLogFormGroup = (log?: Log) => {
  return new FormGroup({
    _id: new FormControl(log?._id),
    type: new FormControl(log?.type),
    initials: new FormControl(log?.initials),
    user: new FormControl(log?.user),
    item: new FormControl(log?.item),
    notes: new FormControl(log?.notes),
    date: new FormControl(log?.date),
    refId: new FormControl(log?.refId),
    order: new FormControl(log?.order),
    patient: new FormControl(log?.patient),
    vendorOrder: new FormControl(log?.vendorOrder),
    image: new FormControl(log?.image),
    product: new FormControl(log?.product),
    prescription: new FormControl(log?.prescription),
    exam: new FormControl(log?.exam),
    employee: new FormControl(log?.employee),
    store: new FormControl(log?.store),
  });
};

