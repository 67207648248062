<div class="container-fluid">
  <app-page-title title="Users"
    [breadcrumbItems]="breadCrumbs"></app-page-title>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center">Show
                    <select #searchOrders name="tickets-table_length"
                      aria-controls="tickets-table" name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading">
                      <option [ngValue]="5">5</option>
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">Search:
                    <input type="text" name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table" [(ngModel)]="search"
                      (ngModelChange)="search$.next()" />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th class="sortable" (click)="onSort('name')">Name</th>
                    <th class="sortable" (click)="onSort('initials')">
                      Initials
                    </th>
                    <th class="sortable" (click)="onSort('email')">Email</th>
                    <th class="sortable" (click)="onSort('role')">Role</th>
                    <th>Stores</th>
                    <th>Any IP</th>
                    <th>Disabled</th>
                  </tr>
                </thead>
                <tbody>
                  @if(loading$) {
                  <tr>
                    <td align="center" colspan="8">
                      <div style="
                          width: 100%;
                          min-height: 200px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 15px;
                        ">
                        <div role="status"
                          class="spinner-border text-success m-1"
                          style="width: 75px; height: 75px">
                          <span class="sr-only">Loading Payables...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (user of users; track user) {
                  <tr>
                    <td scope="row"><a
                        [routerLink]="['/employees', user._id]">{{ user.name ||
                        "-" }}</a></td>
                    <td>{{ user.initials || 0 }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.role }}</td>
                    <td>{{ getStoreNames(user) }}</td>
                    <td class="text-center">
                      <i *ngIf="user.anyIp" class="fa fa-check"
                        ></i>
                    </td>
                    <td class="text-center">
                      <i *ngIf="user.disabled" class="fa fa-check"
                        ></i>
                    </td>

                    <td class="action icon-cell">
                      <div class="dropdown" dropdown>
                        <i data-toggle="dropdown" dropdownToggle
                          aria-expanded="false"
                          class="mdi mdi-dots-vertical dropdown-toggle"></i>
                        <div class="dropdown-menu dropdown-menu-end"
                          *dropdownMenu>
                          <a class="dropdown-item" href="javascript: void(0);"
                            (click)="openModal(user)"
                          ><i class=" fas fa-edit"></i> Update</a>
                          <a class="dropdown-item" href="javascript: void(0);"
                            (click)="onDelete(user)"><i
                              class="fas fa-trash-alt"></i> Delete</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  }}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              class="row justify-content-md-between align-items-md-center mt-2">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info mb-2" id="tickets-table_info"
                  role="status" aria-live="polite">
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  users
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination [maxSize]="4" [totalItems]="pagination.total"
                    [itemsPerPage]="pagination.take" [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)">
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" (click)="openModal()">Add User</button>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="container">
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel">
        {{ employeeForm.value._id ? "Update User" : "Add User" }}
      </h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal"
        aria-label="Close" (click)="onCancel()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="employeeForm">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-floating mb-3">
              <input type="text" formControlName="name"
                class="form-control form-select" id="name" placeholder="Name" />
              <label for="name">Name</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-floating mb-3">
              <input type="text" formControlName="initials"
                class="form-control form-select" />
              <label for="initials">Initials</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-floating mb-3">
              <input type="email" formControlName="email"
                class="form-control form-select" />
              <label for="email">Email</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-floating mb-3">
              <select formControlName="role" class="form-select" id="role"
                aria-label="Role">
                @for (option of roles$ | async; track option) {
                <option value="{{ option.value }}">
                  {{ option.label }}
                </option>
                }
              </select>
              <label for="role">Role</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <app-multi-select class="col-12 col-sm-6 col-md-4" type="store"
              [values]="employeeForm.value.stores || []" [label]="'Stores'"
              (selected)="onStoresSelected($event)"></app-multi-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-floating mb-3">
              <input type="password" formControlName="password"
                class="form-control form-select" autocomplete="off" />
              <label for="password">Password</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <div class="form-check form-switch form-switch-md mb-3">
              <input type="checkbox" formControlName="disabled"
                class="form-check-input" />
              <label for="disabled" class="form-check-label">Disabled</label>
            </div>
            <div class="form-check form-switch form-switch-md mb-3">
              <input type="checkbox" formControlName="anyIp"
                class="form-check-input" />
              <label for="anyIp" class="form-check-label">Any IP</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-actions px-3 py-2">
      <button class="btn btn-secondary me-3" (click)="onCancel()">
        Cancel
      </button>
      @if (employeeForm.value._id) {
      <button class="btn btn-primary" (click)="onUpdate()"
        [disabled]="employeeForm.invalid">
        Update User
      </button>
      } @else {
      <button class="btn btn-primary" (click)="onCreate()"
        [disabled]="employeeForm.invalid">
        Add User
      </button>
      }
    </div>
  </div>
</ng-template>