<div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <app-horizontal-top-bar></app-horizontal-top-bar>

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
  <app-sidebar-right></app-sidebar-right>
</div>