import { Injectable, Inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  map,
  exhaustMap,
  tap,
  delay,
  distinctUntilKeyChanged,
  catchError,
} from "rxjs/operators";
import { of } from "rxjs";
import { AuthenticationService } from "../../core/services/auth.service";
import {
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutSuccess,
  tokenExpiration,
  vendorsSuccess,
} from "./authentication.actions";
import { Router } from "@angular/router";

@Injectable()
export class AuthenticationEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap((credentials) => {
        return this.AuthenticationService.login(credentials).pipe(
          map(({ user, access_token }) => {
            return loginSuccess({
              user,
              token: access_token,
              store: credentials.store,
            });
          })
        );
      }),
      catchError((error) => {
        return of(loginFailure({ error }));
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => {
        localStorage.removeItem("auth");
        localStorage.removeItem("authToken");
        localStorage.removeItem("store");
        this.AuthenticationService.logout();
      }),
      exhaustMap(() => of(logoutSuccess()))
    )
  );

  tokenExpires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenExpiration),
      distinctUntilKeyChanged("status"),
      tap(() => {
        localStorage.removeItem("auth");
        this.AuthenticationService.logout();
      })
    )
  );

  vendors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      delay(2000),
      exhaustMap(({ store }) => {
        this.router.navigateByUrl("/dashboard");
        return this.AuthenticationService.getVendors(store).pipe(
          map((res) => {
            return vendorsSuccess({ vendors: res });
          })
        );
      })
    )
  );

  constructor(
    @Inject(Actions) private actions$: Actions,
    private AuthenticationService: AuthenticationService,
    private router: Router
  ) {}
}
