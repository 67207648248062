import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { take, tap, EMPTY, catchError } from "rxjs";
import { Patient } from "src/app/core/models";
import { ConfigService } from "src/app/core/services/config.service";
import { PatientService } from "src/app/core/services/patient.service";

@Component({
  selector: "app-patient-detail",
  templateUrl: "./patient-detail.component.html",
  styleUrls: ["./patient-detail.component.css"],
})
export class PatientDetailComponent {
  @Input() patientId: string;
  @Input() patient: Patient;
  _patient: Patient;

  get name() {
    return this._patient.firstName + " " + this._patient.lastName;
  }

  constructor(
    public cs: ConfigService,
    protected ps: PatientService,
    private ms: BsModalService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.patientId && !this.patient) {
      this.getPatient(this.patientId);
    } else if (this.patient) {
      this._patient = this.patient;
    }
  }

  getPatient(patientId: string) {
    this.ps
      .getPatient(patientId)
      .pipe(
        take(1),
        tap((patient) => (this._patient = patient)),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
