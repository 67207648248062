import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { Patient } from "src/app/core/models";

export const getPatientFormGroup = (patient?: Partial<Patient>) => {
  return new FormGroup({
    _doctorId: new FormControl(patient?._doctorId),
    _id: new FormControl(patient?._id),
    _logs: new FormArray(
      (patient?._logs ?? []).map((log) => new FormControl(log))
    ),
    address: new FormControl(patient?.address),
    address2: new FormControl(patient?.address2),
    alertNotes: new FormControl(patient?.alertNotes),
    badAddress: new FormControl(patient?.badAddress),
    beBackList: new FormControl(patient?.beBackList),
    callForRx: new FormControl(patient?.callForRx),
    cell: new FormControl(patient?.cell),
    city: new FormControl(patient?.city),
    dateAdded: new FormControl(patient?.dateAdded),
    dateUpdated: new FormControl(patient?.dateUpdated),
    discountStatus: new FormControl(patient?.discountStatus),
    dob: new FormControl(patient?.dob),
    doctorEmail: new FormControl(patient?.doctorEmail),
    doctorFax: new FormControl(patient?.doctorFax),
    doctorName: new FormControl(patient?.doctorName),
    doctorPhone: new FormControl(patient?.doctorPhone),
    email: new FormControl(patient?.email),
    familyMembers: new FormControl(patient?.familyMembers),
    favoriteLines: new FormArray(
      (patient?.favoriteLines ?? []).map((line) => new FormControl(line))
    ),
    firstName: new FormControl(patient?.firstName),
    from2020Now: new FormControl(patient?.from2020Now),
    howDidYouFindUs: new FormControl(patient?.howDidYouFindUs),
    howDidYouFindUsOther: new FormControl(patient?.howDidYouFindUsOther),
    images: new FormControl(patient?.images),
    images2: new FormControl(patient?.images2),
    interests: new FormControl(patient?.interests),
    internalPatientId: new FormControl(patient?.internalPatientId),
    lastName: new FormControl(patient?.lastName),
    lastExam: new FormControl(patient?.lastExam),
    occupation: new FormControl(patient?.occupation),
    optOut: new FormControl(patient?.optOut),
    orders: new FormControl(patient?.orders),
    patientNotes: new FormControl(patient?.patientNotes),
    patientNumber: new FormControl(patient?.patientNumber),
    phone: new FormControl(patient?.phone),
    phone2: new FormControl(patient?.phone2),
    prefix: new FormControl(patient?.prefix),
    prescriptionNotes: new FormControl(patient?.prescriptionNotes),
    prescriptions: new FormArray(
      (patient?.prescriptions ?? []).map(
        (prescription) => new FormControl(prescription)
      )
    ),
    since: new FormControl(patient?.since),
    sleep: new FormControl(patient?.sleep),
    state: new FormControl(patient?.state),
    store: new FormControl(patient?.store),
    storeCredit: new FormControl(patient?.storeCredit),
    zip: new FormControl(patient?.zip),
  });
};
