import { FormGroup, FormControl } from "@angular/forms";
import { Frame } from "src/app/types/frame";

export const getFrameFormGroup = (frame?: Frame) => {
  const frameFormGroup = new FormGroup({
    id: new FormControl(),
    model: new FormControl(),
    color: new FormControl(),
    size: new FormControl(),
    type: new FormControl(),
    vendorId: new FormControl(),
    commission: new FormControl(),
    cost: new FormControl(),
    pricingFormula: new FormControl(),
    retail: new FormControl(),
    storeId: new FormControl(),
    rush: new FormControl(),
    send: new FormControl(),
    orderedBy: new FormControl(),
    backOrdered: new FormControl(),
    wishlist: new FormControl(),
    orderId: new FormControl(),
    return: new FormControl(),
    clipOn: new FormGroup({
      frameId: new FormControl(),
      lab: new FormControl(),
      topBar: new FormControl(),
      color: new FormControl(),
      material: new FormControl(),
      tint: new FormControl(),
      coating: new FormControl(),
      polar: new FormControl(),
      mirror: new FormControl(),
      mirrorDetails: new FormControl(),
      rush: new FormControl(),
      other: new FormControl(),
      otherDetails: new FormControl(),
      otherPrice: new FormControl(),
      price: new FormControl(),
      notes: new FormControl(),
      labReferenceNumber: new FormControl(),
      orderDate: new FormControl(),
      failedInspection: new FormControl(),
    }),
    lens: new FormGroup({
      frameId: new FormControl(),
      bc: new FormControl(),
      et: new FormControl(),
      ct: new FormControl(),
      lab: new FormControl(),
      type: new FormControl(),
      material: new FormControl(),
      transition: new FormControl(),
      polar: new FormControl(),
      design: new FormControl(),
      tint: new FormControl(),
      coating: new FormControl(),
      overPowerSph: new FormControl(),
      edgePolish: new FormControl(),
      mountingEdging: new FormControl(),
      prism: new FormControl(),
      overPowerCyl: new FormControl(),
      bevel: new FormControl(),
      drill: new FormControl(),
      backsideAR: new FormControl(),
      specialBC: new FormControl(),
      specialTool: new FormControl(),
      groove: new FormControl(),
      sendForAR: new FormControl(),
      UVcoating: new FormControl(),
      slabOff: new FormControl(),
      mirror: new FormControl(),
      mirrorDetails: new FormControl(),
      customShape: new FormControl(),
      other: new FormControl(),
      otherDetails: new FormControl(),
      otherPrice: new FormControl(),
      price: new FormControl(),
      pol: new FormControl(),
      polLabReferenceNumber: new FormControl(),
      polPrice: new FormControl(),
      taxable: new FormControl(),
      lensAndShapeNotes: new FormControl(),
      machine: new FormControl(),
      grooveSize: new FormControl(),
      lensSize: new FormControl(),
      depth: new FormControl(),
      bevel2: new FormControl(),
      width: new FormControl(),
      placement: new FormControl(),
      technicalNotes: new FormControl(),
      date: new FormControl(),
      labReferenceNumber: new FormControl(),
      failedInspection: new FormControl(),
    }),
    measurements: new FormGroup({
      frameId: new FormControl(),
      a: new FormControl(),
      b: new FormControl(),
      ed: new FormControl(),
      dbl: new FormControl(),
    }),
    instructions: new FormGroup({
      frameId: new FormControl(),
      rush: new FormControl(),
      opticianReview: new FormControl(),
      approveFrame: new FormControl(),
      shipOrder: new FormControl(),
      doNotMount: new FormControl(),
      approveTint: new FormControl(),
      callAsReady: new FormControl(),
      doNotNotify: new FormControl(),
      tintBeforeCE: new FormControl(),
      neededBy: new FormControl(),
    }),
    ref_id: new FormControl(),
    frameSource: new FormControl(),
    enclosed: new FormControl(),
    pof: new FormControl(),
    patientToSupply: new FormControl(),
    duty: new FormControl(),
    hold: new FormControl(),
    mod: new FormControl(),
    modPrice: new FormControl(),
    modDetail: new FormControl(),
    estimate: new FormControl(),
    callPatient: new FormControl(),
    price: new FormControl(),
    ordered: new FormControl(),
    due: new FormControl(),
    failedInspection: new FormControl(),
    approved: new FormControl(),
    nosePadInstall: new FormControl(),
    status: new FormControl(),
    compositeKey: new FormControl(),
  });
  if (frame) {
    frameFormGroup.patchValue(frame);
  }
  return frameFormGroup;
};
