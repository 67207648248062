import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";

import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { ImageCropperComponent as NgxImageCropper } from "ngx-image-cropper";
import { tap } from "rxjs";
import { FileService } from "src/app/core/services/file.service";
import { Image } from "src/app/core/models/image";

@Component({
  selector: "app-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperComponent implements OnInit {
  @ViewChild(NgxImageCropper) imageCropper: NgxImageCropper;
  @Input() img: Image;
  @Output() cropped = new EventEmitter<any>();

  dataUrl: string;
  imageFile: File;
  transform: ImageTransform = {};
  imageChangedEvent: ImageCroppedEvent;
  croppedImage: any = "";
  canvasRotation: number = 0;
  rotation: number = 0;
  scale: number = 1;
  aspectRatio: number | undefined;
  resizeToWidth: number | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    private fs: FileService,
    private ms: BsModalService
  ) {}

  ngOnInit(): void {
    if (this.img) {
      const { type, image } = this.img;
      const isProfile = ["patient", "user", "profile"].includes(type);
      const isFrame = ["frame", "inventory"].includes(type);
      this.aspectRatio = isProfile ? 1 : isFrame ? 3 / 1 : 8.5 / 11;
      isProfile && (this.resizeToWidth = 100);
      isFrame && (this.resizeToWidth = 150);
      //this.dataUrl = URL.createObjectURL(image);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageChangedEvent = event;
    this.croppedImage = event.objectUrl;
    console.log(event);
  }

  useImage() {
    const { img, refId, type, _id } = this.img;
    const { blob } = this.imageChangedEvent;
    const file = new File([blob], img.name, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
    this.fs
      .uploadFile(file, refId, type, _id)
      .pipe(
        tap((res) => {
          this.cropped.emit(res?.[0]);
        })
      )
      .subscribe();
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  /**
   * Flip image vertical
   */
  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  ready(event: any) {}
}
