<div [class.container-fluid]="!modalId" [class.container]="!!modalId">
  <app-page-title
    *ngIf="!modal"
    title="Orders"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>

  <div class="row" [class.m-3]="modal">
    <div [class.col-md-8]="!modal" [class.col-md-12]="modal">
      <ng-container *ngIf="findPatient">
        <app-patient-fill></app-patient-fill>
      </ng-container>
      <div [formGroup]="orderForm" [class.card]="!modal">
        <div class="card-body">
          <div class="row">
            <div formGroupName="_product" class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <select
                  formControlName="vendor"
                  class="form-select"
                  id="vendor"
                  aria-label="Vendor"
                  (change)="onVendorSelect($event.target.value)"
                >
                  @for (option of vendorOptions$ | async; track option) {
                  <option value="{{ option.value }}">
                    {{ option.label }}
                  </option>
                  }
                </select>
                <label for="vendor">Vendor</label>
              </div>
            </div>
            <div formGroupName="_product" class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <ng-container
                  *ngIf="modelOptions$ | async as options; else noModelOptions"
                >
                  <select
                    *ngIf="options.length; else noModelOptions"
                    formControlName="model"
                    class="form-select"
                    id="model"
                    aria-label="Model"
                  >
                    <option *ngIf="!modelControl?.value" [value]="null">
                      Select Model
                    </option>
                    @for (option of options; track option) {
                    <option
                      value="{{ option.value }}"
                      [selected]="option.value === modelControl?.value"
                    >
                      {{ option.label }}
                    </option>
                    }
                  </select>
                </ng-container>
                <ng-template #noModelOptions>
                  <input
                    type="text"
                    formControlName="model"
                    class="form-control form-select"
                    id="model"
                    placeholder="Model"
                    [disabled]="!vendorControl?.value?.length"
                    [class.disabled]="!vendorControl?.value?.length"
                  />
                </ng-template>
                <label for="model">Model</label>
              </div>
            </div>
            <div formGroupName="_product" class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <ng-container
                  *ngIf="colorOptions$ | async as options; else noColorOptions"
                >
                  <select
                    *ngIf="options.length; else noColorOptions"
                    formControlName="color"
                    class="form-select"
                    id="color"
                    aria-label="Color"
                  >
                    <option *ngIf="!colorControl?.value" [value]="null">
                      Select Color
                    </option>
                    @for (option of options; track option) {
                    <option
                      value="{{ option.value }}"
                      [selected]="option.value === colorControl?.value"
                    >
                      {{ option.label }}
                    </option>
                    }
                  </select>
                </ng-container>
                <ng-template #noColorOptions>
                  <input
                    type="text"
                    formControlName="color"
                    class="form-control form-select"
                    id="color"
                    placeholder="Color"
                    [class.disabled]="!modelControl?.value?.length"
                  />
                </ng-template>
                <label for="color">Color</label>
              </div>
            </div>
            <div formGroupName="_product" class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <ng-container
                  *ngIf="sizeOptions$ | async as options; else noSizeOptions"
                >
                  <select
                    *ngIf="options.length; else noSizeOptions"
                    formControlName="size"
                    class="form-select"
                    id="size"
                    aria-label="Size"
                  >
                    <option *ngIf="!sizeControl?.value" [value]="null">
                      Select Size
                    </option>
                    @for (option of options; track option) {
                    <option
                      value="{{ option.value }}"
                      [selected]="option.value === sizeControl?.value?.length"
                    >
                      {{ option.label }}
                    </option>
                    }
                  </select>
                </ng-container>
                <ng-template #noSizeOptions>
                  <input
                    type="text"
                    formControlName="size"
                    class="form-control form-select"
                    id="size"
                    placeholder="Size"
                    [class.disabled]="!colorControl?.value?.length"
                  />
                </ng-template>
                <label for="size">Size</label>
              </div>
            </div>
            <div formGroupName="_product" class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <ng-container
                  *ngIf="typeOptions$ | async as options; else noTypeOptions"
                >
                  <select
                    *ngIf="options.length; else noTypeOptions"
                    formControlName="type"
                    class="form-select"
                    id="type"
                    aria-label="Type"
                    [class.disabled]="!sizeControl?.value?.length"
                  >
                    <option *ngIf="!typeControl?.value" [value]="null">
                      Select Type
                    </option>
                    @for (option of options; track option) {
                    <option
                      value="{{ option.value }}"
                      [selected]="option.value === typeControl?.value"
                    >
                      {{ option.label }}
                    </option>
                    }
                  </select>
                </ng-container>
                <ng-template #noTypeOptions>
                  <input
                    type="text"
                    formControlName="type"
                    class="form-control form-select"
                    id="type"
                    placeholder="Type"
                    [class.disabled]="!sizeControl?.value?.length"
                  />
                </ng-template>
                <label for="type">Type</label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  formControlName="invoiceNumber"
                  class="form-control form-select"
                  id="invoiceNumber"
                  placeholder="Invoice Number"
                />
                <label for="invoiceNumber">Invoice Number</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="col-12 mb-3" style="height: 150px">
                <ckeditor [editor]="editor" [config]="editorConfig"></ckeditor>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-floating mb-3">
                    <input
                      type="number"
                      formControlName="quantity"
                      class="form-control form-select"
                      id="quantity"
                      placeholder="Quantity"
                    />
                    <label for="quantity">Quantity</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check form-switch form-switch-md mb-1">
                    <input
                      type="checkbox"
                      formControlName="part"
                      class="form-check-input"
                    />
                    <label for="part" class="form-check-label">Part</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch form-switch-md mb-1">
                    <input
                      type="checkbox"
                      formControlName="specialOrder"
                      class="form-check-input"
                    />
                    <label for="specialOrder" class="form-check-label"
                      >Special Order</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch form-switch-md mb-1">
                    <input
                      type="checkbox"
                      formControlName="warranty"
                      class="form-check-input"
                    />
                    <label for="warranty" class="form-check-label"
                      >Warranty</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch form-switch-md mb-1">
                    <input
                      type="checkbox"
                      formControlName="duty"
                      class="form-check-input"
                    />
                    <label for="duty" class="form-check-label">Duty</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  formControlName="tax"
                  class="form-control form-select"
                  id="tax"
                  placeholder="Tax"
                />
                <label for="tax">Tax</label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  formControlName="shipping"
                  class="form-control form-select"
                  id="shipping"
                  placeholder="Shipping"
                />
                <label for="shipping">Shipping</label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  formControlName="total"
                  class="form-control form-select"
                  id="total"
                  placeholder="Total"
                />
                <label for="total">Total</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!modal" class="col-md-4">
      <div>
        <div [class.card]="!modal">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex flex-column align-items-center justify-content-between gap-4"
                >
                  <div class="image-upload" (click)="openImageCropper()">
                    <i class="bx bx-upload"></i>
                    <span>Upload</span>
                    <span>Image</span>
                  </div>
                  <ng-container *ngIf="order?._images">
                    <div class="table-responsive w-100">
                      <table class="table table-striped mb-0 w-100">
                        <tbody>
                          @for (image of order._images; track index) {
                          <tr>
                            <td>
                              <div class="row">
                              <div class="col-md-6">
                                <img
                                [src]="image.image"
                                [alt]="image.name"
                                class="avatar-md"
                                (click)="openImages(index)"
                              />
                              </div>
                              <div class="col-md-6">
                                <small>{{ image.date | date }} <br />
                                {{ image.date | date : "h:mm" }} <br />
                                {{ image.img.contentType }}</small>

                                <div class="mt-2">
                                  <i
                                    class="fas fa-eye"
                                    (click)="openImages(index)"
                                  ></i>
                                  <i class="fas fa-trash-alt ms-4"></i>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="row">
      <div class="modal-actions px-3 py-2">
        @if (order) {
        <button
          class="btn btn-primary"
          (click)="saveOrder()"
          [disabled]="orderForm.invalid"
        >
          Update Order
        </button>
        } @else {
        <button
          class="btn btn-primary"
          (click)="saveOrder()"
          [disabled]="orderForm.invalid"
        >
          Add Order
        </button>
        }
        <button
          *ngIf="modalId || patientId"
          class="btn btn-secondary ms-3"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
