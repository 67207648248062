<div class="container-fluid">
  <app-page-title
    title="Order Summary"
    [breadcrumbItems]="breadCrumbItems"
  ></app-page-title>

  @if(loading$) {
  <div
    style="
      width: 100%;
      min-height: 75vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div
      role="status"
      class="spinner-border text-success m-1"
      style="width: 100px; height: 100px"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  } @else {

  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Monthly Earning</h4>
              <div class="row">
                <div class="col-sm-6">
                  <p class="text-muted">
                    {{ monthData.key | date : "MMMM YYYY" }}
                  </p>
                  <h3>{{ monthData.total | currency }}</h3>
                  <p class="text-muted">
                    <span class="text-success me-2">
                      {{ monthPercentage | percent }}

                      @if(prevMonthData.total < monthData.total) {
                      <i class="mdi mdi-arrow-up"></i>
                      } @else {
                      <i class="mdi mdi-arrow-down"></i>
                      }
                    </span>
                  </p>
                  <h6>
                    <span class="text-muted"
                      >From {{ prevMonthData.key | date : "MMMM YYYY" }}</span
                    >
                  </h6>
                  <h5>{{ prevMonthData.total | currency }}</h5>

                  <!-- <div class="mt-4">
                      <button
                        href=""
                        class="btn btn-primary btn-sm rounded-start"
                      >
                        <i class="mdi mdi-arrow-left me-1"></i>Prev
                      </button>
                    </div> -->
                </div>
                <div class="col-sm-6">
                  <apx-chart
                    dir="ltr"
                    class="apex-charts"
                    [series]="monthChart.series"
                    [chart]="monthChart.chart"
                    [legend]="monthChart.legend"
                    [colors]="monthChart.colors"
                    [labels]="monthChart.labels"
                    [stroke]="monthChart.stroke"
                    [plotOptions]="monthChart.plotOptions"
                  >
                  </apx-chart>
                </div>
              </div>
              <p class="text-muted mb-0">
                {{ daysLeft }} days remaining in the month
              </p>
              <div clas="row">
                <div class="d-flex justify-content-center w-100 mt-4">
                  <div style="width: 100px">
                    <select
                      class="form-select form-control-sm mx-2 heading rounded-start"
                      (change)="selectMonth($event.target.value)"
                    >
                      @for (option of monthOptions; track $index) {
                      <option [value]="option.value">
                        {{ option.label }}
                      </option>
                      }
                    </select>
                  </div>
                  <div style="width: 75px">
                    <select
                      class="form-select form-control-sm mx-2 heading rounded-end"
                      (change)="selectYear($event.target.value)"
                    >
                      @for (option of yearOptions; track $index) {
                      <option [value]="option.value">{{ option.label }}</option>
                      }
                    </select>
                  </div>
                </div>
                <div class="d-flex justify-content-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              @for (data of sassTopSelling; track $index) {
              <div>
                <div class="text-muted text-center">
                  <p class="mb-2">{{ data.title }}</p>
                  <h4>{{ data.amount }}</h4>
                  <!-- <p class="mt-4 mb-0">
                <span class="badge badge-soft-success font-size-11 me-2">
                  {{ data.revenue }}% <i class="mdi mdi-arrow-up"></i>
                </span>
                From previous period
              </p> -->
                </div>
    
                <div class="table-responsive mt-4">
                  <table class="table align-middle mb-0">
                    <tbody>
                      @for (item of data.list; track $index) {
                      <tr>
                        <td>
                          <h5 class="font-size-14 mb-1">{{ item.name }}</h5>
                          <p class="text-muted mb-0">{{ item.text }}</p>
                        </td>
    
                        <!-- <td>
                          <app-sellingchart
                            :value="{{ item.sales }}"
                            :Chartcolor="{{ item.chartVariant }}"
                          >
                          </app-sellingchart>
                        </td> -->
                        <td>
                          <p class="text-muted mb-1">Sales</p>
                          <h5 class="mb-0">{{ item.sales | currency }}</h5>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
       <div class="col-12">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">All Months/Years</h4>
              <h5 class="mb-2 text-muted">2019 - 2025</h5>

              <div *ngIf="lineChart" class="col-12" data-show="true">
                <div class="chartjs-chart">
                  <canvas
                    class="chartjs-chart"
                    height="500"
                    baseChart
                    [data]="lineChart.data"
                    [options]="lineChart.options"
                    [type]="'bar'"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>

     
      </div>
    </div>
  </div>


  <!-- end row -->

  }
</div>
