import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./auth.service";
import { concatMap, Observable, tap } from "rxjs";
import { Pagination } from "src/app/types/pagination";
import { Params } from "@angular/router";
import { Order } from "../models";
import { getStore } from "src/app/store/Authentication/authentication-selector";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  private base_url = `${environment.apiDomain}`;

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private store: Store<AuthenticationState>
  ) {}

  createOrder(order: Partial<Order>): Observable<Order> {
    return this.http.post<Order>(`${this.base_url}/orders`, order);
  }

  getOrder(orderId: string): Observable<any> {
    return this.http
      .get<any>(`${this.base_url}/orders/${orderId}`)
      .pipe(tap((res) => console.log(res)));
  }

  getOrders(params?: Params): Observable<Pagination<Order>> {
    return this.store.select(getStore).pipe(
      concatMap((store) =>
        this.http.get<Pagination<Order>>(`${this.base_url}/orders`, {
          params: { ...(params ?? {}), store },
        })
      )
    );
  }

  getPatientOrders(
    patientId: number,
    params?: Params
  ): Observable<Pagination<Order>> {
    return this.http.get<Pagination<Order>>(
      `${this.base_url}/patients/${patientId}/orders`,
      { params }
    );
  }

  getVendorOrders(
    vendorId: number,
    params?: Params
  ): Observable<Pagination<Order>> {
    return this.http.get<Pagination<Order>>(
      `${this.base_url}/vendors/${vendorId}/orders`,
      { params }
    );
  }

  searchOrders(params?: Params): Observable<Pagination<Order>> {
    return this.http.get<Pagination<Order>>(`${this.base_url}/orders/search`, {
      params,
    });
  }

  searchPatientOrders(patientId: number, params?: Params): Observable<Order[]> {
    return this.http.get<Array<Order>>(
      `${this.base_url}/patients/${patientId}/orders/search`,
      { params }
    );
  }

  searchVendorOrders(vendorId: number, params?: Params): Observable<Order[]> {
    return this.http.get<Array<Order>>(
      `${this.base_url}/vendors/${vendorId}/orders/search`,
      { params }
    );
  }

  updateOrder(order: Partial<Order>): Observable<Order> {
    return this.http.put<Order>(`${this.base_url}/orders`, order);
  }
}
