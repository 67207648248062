export const calculateProductPrice = ({
  cost,
  pricingFormula,
}: any): number => {
  const rx_validate = /^x.+p.+$/;
  const rx_extract = /^x(.+)p(.+)$/;
  let result = [];
  let multiplier = 1;
  let adder = 0;
  if (rx_validate.test(pricingFormula)) {
    result = rx_extract.exec(pricingFormula);
    multiplier = parseFloat(result[1]);
    adder = parseFloat(result[2]);
  }
  if (parseFloat(`${cost}`)) {
    return (
      Math.ceil(
        Math.round((parseFloat(`${cost}`) * multiplier + adder) * 100) / 100 / 5
      ) * 5
    );
  }
  return 0;
};
