import { Component } from "@angular/core";

@Component({
  selector: "app-find-patient",
  templateUrl: "./find-patient.component.html",
  styleUrls: ["./find-patient.component.css"],
})
export class FindPatientComponent {
  ngOnInit(): void {}
}
