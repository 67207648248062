import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { getLayoutMode } from "src/app/store/layouts/layout.selector";
import { LayoutState } from "src/app/store/layouts/layouts.reducer";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();
  layout: any;

  constructor(private store: Store<LayoutState>) {}

  ngOnInit() {
    this.store.select(getLayoutMode).subscribe((mode) => {
      this.layout = mode;
      console.log(this.layout);
    });
  }
}
