<div class="row" [class.m-3]="modal">
  <div class="col-12">
    <div [formGroup]="productForm">
      <div class="row g-1">
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="form-floating">
            <ng-container *ngIf="!manualEntry; else manualVendor">
            <select formControlName="vendor" class="form-select" id="vendor" aria-label="Vendor" (change)="onVendorSelect($event.target.value)"
              [class.disabled]="readOnly">
              @for (option of vendorOptions$ | async; track option) {
              <option value="{{ option.value }}">
                {{ option.label }}
              </option>
              }
            </select>
          </ng-container>
          <ng-template #manualVendor>
            <input type="text" formControlName="_frameVendor" class="form-control form-select" id="vendor" placeholder="Vendor"
              [class.disabled]="readOnly" />
          </ng-template>
            <label for="vendor">Vendor</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="form-floating">
            <ng-container *ngIf="modelOptions$ | async as options; else noModelOptions">
              <select *ngIf="options.length; else noModelOptions" formControlName="model" class="form-select" id="model" aria-label="Model"
                [class.disabled]="readOnly" (change)="onModelSelect($event.target.value)">
                <option *ngIf="!modelControl.value" [value]="null"></option>
                @for (option of options; track option) {
                <option value="{{ option.value }}" [selected]="option.value === modelControl.value">
                  {{ option.label }}
                </option>
                }
              </select>
            </ng-container>
            <ng-template #noModelOptions>
              <input type="text" formControlName="model" class="form-control form-select" id="model" placeholder="Model"
                [disabled]="readOnly" [class.disabled]="readOnly" (change)="onModelSelect($event.target.value)" />
            </ng-template>
            <label for="model">Model</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="form-floating">
            <ng-container *ngIf="colorOptions$ | async as options; else noColorOptions">
              <select *ngIf="options.length; else noColorOptions" formControlName="color" class="form-select" id="color" aria-label="Color"
                [class.disabled]="readOnly" (change)="onColorSelect($event.target.value)">
                <option *ngIf="!colorControl.value" [value]="null"></option>
                @for (option of options; track option) {
                <option value="{{ option.value }}" [selected]="option.value === colorControl.value">
                  {{ option.label }}
                </option>
                }
              </select>
            </ng-container>
            <ng-template #noColorOptions>
              <input type="text" formControlName="color" class="form-control form-select" id="color" placeholder="Color"
                [class.disabled]="readonly" (change)="onColorSelect($event.target.value)" />
            </ng-template>
            <label for="color">Color</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="form-floating">
            <ng-container *ngIf="sizeOptions$ | async as options; else noSizeOptions">
              <select *ngIf="options.length; else noSizeOptions" formControlName="size" class="form-select" id="size" aria-label="Size"
                [class.disabled]="readOnly" (change)="onSizeSelect($event.target.value)">
                <option *ngIf="!sizeControl.value" [value]="null"></option>
                @for (option of options; track option) {
                <option value="{{ option.value }}" [selected]="option.value === sizeControl.value?.length">
                  {{ option.label }}
                </option>
                }
              </select>
            </ng-container>
            <ng-template #noSizeOptions>
              <input type="text" formControlName="size" class="form-control form-select" id="size" placeholder="Size"
                [class.disabled]="readonly" (change)="onSizeSelect($event.target.value)" />
            </ng-template>
            <label for="size">Size</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-1">
          <div class="form-floating">
            <ng-container *ngIf="typeOptions$ | async as options; else noTypeOptions">
              <select *ngIf="options.length; else noTypeOptions" formControlName="type" class="form-select" id="type" aria-label="Type"
                [class.disabled]="!sizeControl.value?.length || readOnly" (change)="onTypeSelect($event.target.value)">
                <option *ngIf="!typeControl.value" [value]="null"></option>
                @for (option of options; track option) {
                <option value="{{ option.value }}" [selected]="option.value === typeControl.value">
                  {{ option.label }}
                </option>
                }
              </select>
            </ng-container>
            <ng-template #noTypeOptions>
              <input type="text" formControlName="type" class="form-control form-select" id="type" placeholder="Type"
                [class.disabled]="readonly" (change)="onTypeSelect($event.target.value)" />
            </ng-template>
            <label for="type">Type</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-2 col-lg-1">
          <div class="form-floating">
            <input type="number" formControlName="tax" class="form-control form-select" id="tax" placeholder="Tax" (change)="calculateTotalFromForm()" />
            <label for="tax">Tax</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-2 col-lg-1">
          <div class="form-floating">
            <input type="number" formControlName="shipping" class="form-control form-select" id="shipping"
              placeholder="Shipping" (change)="calculateTotalFromForm()" />
            <label for="shipping">Shipping</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-1">
          <div class="form-floating">
            <input type="number" formControlName="total" class="form-control form-select" id="total" placeholder="Total"
              [class.disabled]="readOnly" />
            <label for="total">Total</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #manualEntryModal let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="mySmallModalLabel"><i class="fas fa-exclamation-triangle text-warning"></i> Manual Entry Required</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()"></button>
  </div>
  <div class="modal-body">
      <p>{{ modalVendorText }}
        Manual entry is required and will add the additional frame options entered to inventory You may also change existing options to search for another frame. Do you wish to proceed?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modalRef?.hide()">Proceed</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="onCancelManualEntry()">Cancel</button>
  </div>
</ng-template>