import { Injectable } from "@angular/core";
import { catchError, EMPTY, map, Observable, of, take, tap } from "rxjs";
import { AuthCredentials } from "../models/auth";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthLoginResponse } from "../models/api.model";
import { Vendor } from "../models/vendor";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Store } from "@ngrx/store";
import { SelectOptions } from "../models/select";
import { BsModalService } from "ngx-bootstrap/modal";
import { Log } from "../models";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  stores: Array<SelectOptions> = [];
  vendors: Array<Vendor> = [];

  constructor(
    protected http: HttpClient,
    protected router: Router,
    private bs: BsModalService,
    protected store: Store<AuthenticationState>
  ) {
    this.getStores().subscribe();
    this.getVendors().subscribe();
  }

  private store$ = this.store.select((state) => state.store);

  login(credentials: AuthCredentials): Observable<AuthLoginResponse> {
    return this.http
      .post<AuthLoginResponse>(`${environment.apiDomain}/login`, credentials)
      .pipe(
        tap((res) => {
          localStorage.setItem("authToken", res.access_token);
          return res;
        })
      );
  }

  logout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("authToken");
    localStorage.removeItem("store");
    this.bs.hide();
    this.router.navigateByUrl("/auth/login");
  }

  getUserActivity(userId: string) {
    return this.http
      .get<Array<Log>>(`${environment.apiDomain}/activity/${userId}`)
      .pipe(
        tap((res) => {
          console.log(res);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getVendors(store: number = null) {
    const storeId = store || this.store$;
    return this.http
      .get<Array<Vendor>>(`${environment.apiDomain}/vendors`)
      .pipe(
        tap((res) => {
          console.log(res);
          this.vendors = res;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      );
  }

  getStores() {
    if (this.stores.length) return of(this.stores);
    return this.http
      .get<Array<SelectOptions>>(`${environment.apiDomain}/stores`)
      .pipe(
        take(1),
        tap((stores) => {
          this.stores = stores;
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      );
  }

  getVendorsMap(): Observable<Record<string, Vendor>> {
    if (this.vendors.length) {
      return of(
        this.vendors.reduce((acc: Record<string, Vendor>, vendor: Vendor) => {
          acc[vendor._id] = vendor;
          return acc;
        }, {} as Record<string, Vendor>)
      );
    }
    return this.getVendors().pipe(
      map((vendors) =>
        vendors.reduce((acc: Record<string, Vendor>, vendor: Vendor) => {
          acc[vendor._id] = vendor;
          return acc;
        }, {} as Record<string, Vendor>)
      )
    );
  }
}
