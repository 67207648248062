<div class="container-fluid">
    <app-page-title
      title="User"
      [breadcrumbItems]="breadCrumbs"
    ></app-page-title>
    <div class="row">


      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 col-md-1">
     
                <a href="javascript:void(0)"
                  class="d-flex flex-column align-items-center justify-content-center avatar-lg rounded-circle bg-secondary-subtle bg-opacity-10"
                  (click)="openImageCropper()"
                  *ngIf="!profileImage; else newFile"
                >
                    
                    <i class="bx bx-upload"></i
                    ><span>Upload</span><span>Image</span>
                  
                </a>
                <ng-template #newFile>
                  <a class="d-flex align-items-center justify-content-center" href="javascript:void(0)" (click)="openImageCropper()">
                    <img
                      [src]="profileImage"
                      alt="Image"
                      class="avatar-lg rounded-circle"
                    />
                  </a>
                </ng-template>
              
          </div>
            <form class="col-sm-10 col-md-11" [formGroup]="employeeForm">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        formControlName="name"
                        class="form-control form-select"
                        id="name"
                        placeholder="Name"
                      />
                      <label for="name">Name</label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        formControlName="initials"
                        class="form-control form-select"
                      />
                      <label for="initials">Initials</label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-floating mb-3">
                      <input
                        type="email"
                        formControlName="email"
                        class="form-control form-select"
                      />
                      <label for="email">Email</label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-floating mb-3">
                      <select
                        formControlName="role"
                        class="form-select"
                        id="role"
                        aria-label="Role"
                      >
                        @for (option of roles$ | async; track option) {
                        <option value="{{ option.value }}">
                          {{ option.label }}
                        </option>
                        }
                      </select>
                      <label for="role">Role</label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <app-multi-select
                      class="col-12 col-sm-6 col-md-4"
                      type="store"
                      [values]="employeeForm.value.stores || []"
                      [label]="'Stores'"
                      (selected)="onStoresSelected($event)"
                    ></app-multi-select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-floating mb-3">
                      <input
                        type="password"
                        formControlName="password"
                        class="form-control form-select"
                        autocomplete="off"
                      />
                      <label for="password">Password</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        formControlName="disabled"
                        class="form-check-input"
                      />
                      <label for="disabled" class="form-check-label">Disabled</label>
                    </div>
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        formControlName="anyIp"
                        class="form-check-input"
                      />
                      <label for="anyIp" class="form-check-label">Any IP</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
    <div class="modal-actions px-3 py-2">
      @if (employeeForm.value._id) {
      <button
        class="btn btn-primary"
        (click)="saveEmployee()"
        [disabled]="employeeForm.invalid"
      >
        Update User
      </button>
      } @else {
      <button
        class="btn btn-primary"
        (click)="saveEmployee()"
        [disabled]="employeeForm.invalid"
      >
        Add User
      </button>
      }
    </div>
    </div>
  </div>
