import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { EMPTY } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Patient } from 'src/app/core/models';
import { Order } from 'src/app/core/models/order';
import { OrdersService } from 'src/app/core/services/orders.service';
import { PatientService } from 'src/app/core/services/patient.service';
@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.css']
})
export class OrderInvoiceComponent {
  @Input() order: Order;
  patient: Patient;
  orderId: string;
  breadCrumbs = [];
  isModal: boolean = false;
  cost: number = 0;
  isVendor: boolean = false;

  get storeName() {
    const { store = 1 } = this.order?._vendor ?? {};
    if(store === 2) return 'Berkeley';
    return 'Mill Valley';
  }

  constructor(
    private route: ActivatedRoute,
    private oS: OrdersService,
    private lightbox: Lightbox,
    private pS: PatientService
  ) {
      this.breadCrumbs.push({ label: "Orders", url: "/orders" });
  }

  ngOnInit() {
    if (this.order) return;
    this.route.params.subscribe((params) => {
      if (params["orderId"]) {
        this.orderId = params["orderId"];
        this.getOrder();
      }
    });
  }

  getOrder() {
    this.oS
      .getOrder(this.orderId)
      .pipe(
        take(1),
        tap((order) => {
          this.order = order;
          order.invoiceNumber && this.breadCrumbs.push(
            {
              label: order.invoiceNumber,
              url: `/orders/${this.orderId}/invoice`,
            },
          );
        }),
        concatMap((order) =>  order.patient ? this.pS.getPatient(order.patient) : EMPTY)
      )
      .subscribe((patient) => {
        if(!patient) return;
        this.patient = patient;
      });
  }

  openLightbox(order: Order, index: number) {
    let album = order._products.map((product) => ({ 
      src: product._images[0].image,
      caption: `Invoice #${order.invoiceNumber} | ${product.vendorName} | ${product.model}`,
      thumb: product._images[0].image,
    }));

    if(order._images.length > 0) {
      const orderImages = order._images.map((image) => ({
        src: image.image,
        caption: `Invoice #${order.invoiceNumber}`,
        thumb: image.image,
      }));

      album = [...orderImages, ...album];
    } 

    this.lightbox.open(album, index);
  }

}
