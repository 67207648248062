import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { Vendor } from "src/app/core/models";
import { getLogFormGroup } from "./form";

export const getVendorFormGroup = (vendor?: Vendor) => {
  return new FormGroup({
    _id: new FormControl(vendor?._id),
    accountNumber: new FormControl(vendor?.accountNumber),
    accountStatus: new FormControl(vendor?.accountStatus),
    borrowingStores: new FormArray((vendor?.borrowingStores ?? []).map((store) => new FormControl(store))),
    borrowingFromVendors: new FormArray((vendor?.borrowingFromVendors ?? []).map((vendor) => new FormControl(vendor))),
    borrowingVendors: new FormArray((vendor?.borrowingVendors ?? []).map((vendor) => new FormControl(vendor))),
    differentStore: new FormControl(vendor?.differentStore),
    dateAdded: new FormControl(vendor?.dateAdded),
    dateUpdated: new FormControl(vendor?.dateUpdated),
    defaultManagedBoardStatus: new FormControl(vendor?.defaultManagedBoardStatus),
    distributor: new FormControl(vendor?.distributor),
    distributorVendors: new FormArray((vendor?.distributorVendors ?? []).map((vendor) => new FormControl(vendor))),
    fax: new FormControl(vendor?.fax),
    images: new FormArray((vendor?.images ?? []).map((image) => new FormControl(image))),
    idealInventory: new FormControl(vendor?.idealInventory),
    name: new FormControl(vendor?.name),
    namingKey: new FormControl(vendor?.namingKey),
    orderProtocol: new FormControl(vendor?.orderProtocol),
    orderInfo: new FormControl(vendor?.orderInfo),
    password: new FormControl(vendor?.password),
    phone: new FormControl(vendor?.phone),
    phoneExt: new FormControl(vendor?.phoneExt),
    pricingFormula: new FormControl(vendor?.pricingFormula),
    pricingFormulaOptical: new FormControl(vendor?.pricingFormulaOptical),
    pricingFormulaSun: new FormControl(vendor?.pricingFormulaSun),
    repName: new FormControl(vendor?.repName),
    repPhone: new FormControl(vendor?.repPhone),
    repPhoneExt: new FormControl(vendor?.repPhoneExt),
    repEmail: new FormControl(vendor?.repEmail),
    returnAddress: new FormControl(vendor?.returnAddress),
    returnDetails: new FormControl(vendor?.returnDetails),
    returnNotes: new FormControl(vendor?.returnNotes),
    shippingFee: new FormControl(vendor?.shippingFee),
    shippingMin: new FormControl(vendor?.shippingMin),
    store: new FormControl(vendor?.store),
    taxBothRxAndSun: new FormControl(vendor?.taxBothRxAndSun),
    updateBoard: new FormControl(vendor?.updateBoard),
    username: new FormControl(vendor?.username),
    website: new FormControl(vendor?.website),
    _logs: new FormArray((vendor?._logs ?? []).map(getLogFormGroup)),
  });
};
