import { createReducer, on } from "@ngrx/store";
import {
  login,
  loginFailure,
  loginSuccess,
  logout,
  selectStore,
  tokenExpiration,
  vendorsSuccess,
} from "./authentication.actions";
import { User } from "./auth.models";
import { Vendor } from "src/app/core/models/vendor";
import { Payable } from "src/app/core/models";

export interface AuthenticationState {
  isLoggedIn: boolean;
  user: User | null;
  error: string | null;
  token: string | null;
  store: number | null;
  vendors: Array<Vendor> | null;
  payables: Array<Payable> | null;
}

const logoutState: AuthenticationState = {
  isLoggedIn: false,
  user: null,
  error: null,
  token: null,
  store: null,
  vendors: null,
  payables: null,
};

const initialState: AuthenticationState = {
  isLoggedIn: false,
  user: null,
  error: null,
  token: null,
  store: null,
  vendors: null,
  payables: null,
  ...JSON.parse(localStorage.getItem("auth") || "{}"),
};

export const authenticationReducer = createReducer(
  initialState,
  on(login, (state) => ({ ...state, error: null })),
  on(loginSuccess, (state, { user, token, store }) => {
    const authState = {
      ...state,
      isLoggedIn: true,
      user,
      error: null,
      token,
      store,
    };
    localStorage.setItem("auth", JSON.stringify(authState));
    return authState;
  }),
  on(loginFailure, (state, { error }) => ({ ...state, error })),
  on(logout, () => {
    localStorage.removeItem("auth");
    return logoutState;
  }),
  on(tokenExpiration, () => logoutState),
  on(vendorsSuccess, (state, { vendors }) => ({ ...state, vendors })),
  on(selectStore, (state, { store }) => ({ ...state, store }))
);
