import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Observable, Subject, debounceTime, take } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { Vendor } from "src/app/core/models";
import { PayablesService } from "src/app/core/services/payables.service";
import { VendorService } from "src/app/core/services/vendor.service";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { PaginationParams } from "src/app/types/pagination";
import { Options } from "src/app/types/select";

@Component({
  selector: "app-receivables",
  templateUrl: "./receivables.component.html",
  styleUrls: ["./receivables.component.css"],
})
export class ReceivablesComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;
  @Input() child: boolean = false;
  vendor: Vendor;
  items: Array<any> = [];
  _items: Array<any> = [];
  storeMap: Record<number, string> = {
    1: "Mill Valley",
    2: "Berkeley",
  };
  stores: Array<Options> = [
    {
      _id: 1,
      label: "Mill Valley",
      value: 1,
    },
    {
      _id: 2,
      label: "Berkeley",
      value: 2,
    },
  ];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Receivables", url: "/receivables" },
  ];
  selectedStore: number = 1;
  showPaid: boolean = false;
  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "name";
  dir = 1;
  search$ = new Subject<void>();
  loading$ = true;
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;
  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      col: this.col,
      dir: this.dir,
      receivables: true,
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private vS: VendorService,
    private payablesService: PayablesService,
    private route: ActivatedRoute,
    private ms: BsModalService,
    private store: Store<AuthenticationState>
  ) {
    this.store
      .select(getStore)
      .pipe(take(1))
      .subscribe((store) => {
        this.selectedStore = store;
      });
  }

  ngOnInit() {
    this.getPayableGroups();
    this.search$
      .pipe(debounceTime(500))
      .subscribe(() => this.getPayableGroups());
  }

  selectStore(store: string | number) {
    this.selectedStore = Number(store);
    // console.log(this.selectedStore);
  }

  getPayableGroups() {
    this.loading$ = true;
    this.payablesService
      .getReceivablesCounts(this.queryParams)
      .subscribe((res) => {
        console.log(res);
        this.items = res.map((item) => ({
          ...item,
          showPaid: false,
        }));
        this._items = this.items;
      });
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.sortPageItems();
  }

  onPageSizeChange(take: number) {
    this.loading$ = true;
    this.pagination.skip = 0;
    this.pagination.take = take;
    this.sortPageItems();
  }

  onSort(col: string) {
    this.loading$ = false;

    if (col === this.col) {
      this.dir = this.dir * -1;
    } else {
      this.dir = 1;
    }
    this.col = col;
    this.sortPageItems();
  }

  sortPageItems() {
    this._items = this.items
      .reduce((acc, item) => {
        if (!this.search?.length) return [...acc, item];
        const { name, paid, unpaid } = item;
        const match = [name, paid, unpaid].some((v) =>
          v.toString().toLowerCase().includes(this.search?.toLowerCase())
        );
        if (match) return [...acc, item];
        return acc;
      }, [])
      .sort((a, b) => {
        return this.dir === 1
          ? a[this.col] > b[this.col]
            ? 1
            : -1
          : b[this.col] > a[this.col]
          ? 1
          : -1;
      })
      .slice(this.pagination.skip, this.pagination.skip + this.pagination.take);
    this.noResults = !this._items.length;
    this.loading$ = false;
  }

  toggleStoreModal(id: number) {
    this.selectedStore = id;
  }

  togglePaid() {
    this.showPaid = !this.showPaid;
  }

  toggleType(index: number) {
    this.showPaid = !this.showPaid;
  }
}
