<div class="container-fluid">
  <app-page-title
    title="Eye Exams"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchOrders
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered table-hover table-sm mb-0"
                data-cols="8"
              >
                <thead>
                  <tr>
                    <!-- <th width="25"></th> -->
                    <th class="sortable" (click)="onSort('firstName')">
                      First Name
                    </th>
                    <th class="sortable" (click)="onSort('lastNames')">
                      Last Name
                    </th>
                    <th class="sortable" (click)="onSort('lastExam')">Date</th>
                    <th lass="sortable" (click)="onSort('author')">MD</th>
                    <th>RX</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (exam of items; track exam; let index = $index) {
                  <tr>
                    <!-- <td  class="icon-cell" width="25">
                      <a  class="expand-row" (click)="toggleRow(index)"> + </a>
                    </td> -->
                    <td>{{ exam.firstName }}</td>
                    <td>{{ exam.lastName }}</td>
                    <td>{{ exam.lastExam | date : "MM/dd/yyyy" }}</td>
                    <td>{{ exam.author }}</td>
                    <td colspan="100%" class="p-0 cell-full-width">
                      <table class="table table-sm table-striped m-1 w-100">
                        <tbody>
                          <tr>
                            <td class="pl-2">
                              <kbd class="mw-auto-1">Left</kbd>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >sph</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.left.sph ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >cyl</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.left.cyl ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >axis</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.left.axis ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >add</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.left.add ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >prism</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.left.prism ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-2"><kbd>Right</kbd></td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >sph</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.right.sph ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >cyl</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.right.cyl ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >axis</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.right.axis ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >add</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.right.add ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                            <td>
                              <div
                                class="d-flex flex-no-wrap align-items-center"
                              >
                                <kbd class="mw-auto bg-light text-dark"
                                  >prism</kbd
                                >
                                <kbd class="bg-primary">{{
                                  exam.finalRx.right.prism ?? "-"
                                }}</kbd>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <!-- <td class="action icon-cell">
                        <i class="fas fa-edit"></i>
                      </td>
                      <td class="action icon-cell">
                        <i class="fas fa-trash-alt"></i>
                      </td> -->
                  </tr>
                  <tr>
                    <td colspan="100%" class="multiline">
                      {{ exam.comments }}
                    </td>
                  </tr>

                  }
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  exams
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [itemsPerPage]="pagination.take"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" (click)="openModal()">Add User</button>
    </div>
  </div> -->
</div>
