

import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { concatMap, tap, catchError, EMPTY, Subject, timer, forkJoin } from "rxjs";
import { ConfigService } from "src/app/core/services/config.service";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { environment } from "src/environments/environment";
import { RevenueResponse } from "../saas/saas.model";
import {
  chartTypes,
  displayModeOptions,
  filterKeys,
  monthOptions,
  quarterOptions,
  storeOptions,
  keyLabels,
    viewKeys,
  viewOptions,
  yearOptions,
  getRandomLightColor,
} from "../saas/constants";
import { defaultTimeChart } from "../saas/data";
import { CurrencyPipe, DatePipe } from "@angular/common";

@Component({
  selector: 'app-store-data',
  templateUrl: './store-data.component.html',
  styleUrls: ['./store-data.component.css']
})
export class StoreDataComponent implements OnInit {
  data: any;
  loading$: boolean;
  breadCrumbItems: Array<{}> = [
    { label: 'Analytics', link: '/dashboard' },
    { label: 'Stores', link: '/dashboard/stores' }
  ];

  mOptions = monthOptions;
  yoOptions = yearOptions;
  qOptions = quarterOptions;
  sOptions = storeOptions;
  displayModeOptions = displayModeOptions;
  chartTypes = chartTypes;
  storeOptions = [];
  viewOptions = viewOptions;

  month: (typeof monthOptions)[0] = monthOptions[0];
  year: (typeof yearOptions)[0] = yearOptions[0];
  quarter: (typeof quarterOptions)[0] = quarterOptions[0];
  store: (typeof storeOptions)[0] = storeOptions[0];
  displayMode: (typeof displayModeOptions)[0] = displayModeOptions[1];

  month$ = new Subject<(typeof monthOptions)[0]>();
  year$ = new Subject<(typeof yearOptions)[0]>();
  quarter$ = new Subject<(typeof quarterOptions)[0]>();
  store$ = new Subject<(typeof storeOptions)[0]>();
  displayMode$ = new Subject<(typeof displayModeOptions)[0]>();
  vendor$ = new Subject<string | undefined>();
  view$ = new Subject<string>();
  chartType$ = new Subject<string>();

  sassEarning: any;
  lineChart: any = {
    ... defaultTimeChart,
    options: {
      ...defaultTimeChart.options,
      scales: {
        y: {
          ...defaultTimeChart.options.scales.y,
          type: 'linear',
        },
        x: {
          ...defaultTimeChart.options.scales.x,
          // type: 'linear',
          ticks: {
            max: 1000,
            min: 0,
          }
        }
      },
      plugins: {
        ...defaultTimeChart.options.plugins,
        title: {
          display: true,
          text: 'Generate Vendor Data',
          padding: 0
        },
        subtitle: {
          display: true,
          text: 'Select multiple cobinations of data points to view',
          padding: 15
      },
    },
  }
  };
  pieChart: any = {
    data: {
      labels: [],
      datasets: []
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: 'Inventory By Age',
          padding: 0
        },
        subtitle: {
          display: true,
          text: 'Displays Inventory age in six month intervals',
          padding: 15
      },
        legend: {
          position: 'bottom',
          align: 'left',
          display: false,
        }
      }
    }
  }
  pieChart2: any = {
    data: {
      labels: [],
      datasets: []
    },
    options: {
      plugins: {
        ...this.pieChart.options.plugins,
        title: {
          display: true,
          text: 'Inventory Value By Age',
          padding: 0
        },subtitle: {
          display: true,
          text: 'Displays Inventory value by age six month intervals',
          padding: 15
      },
      }
    }
  };
  pieChart3: any = {
    data: {
      labels: [],
      datasets: []
    },
    options: {
      plugins: {
        ...this.pieChart.options.plugins,
        title: {
          display: true,
          text: 'Inventory Count',
          padding: 0
        },subtitle: {
          display: true,
          text: 'Displays Inventory count',
          padding: 15
      },
      }
    }
  };
  activeDataSet: Array<any> = [];

  chartKeys = ["revenue", "retail","total", "cost"];
  tableKeys = ["tax", "shipping", "total", "cost", "revenue"];
  vendor: string | undefined;

  filterKeys = filterKeys;
  viewKeys = viewKeys;
  view: (typeof viewOptions)[0] = viewOptions[0];
  chartType: string = "line";
  keyLabels = keyLabels;
  dp = new DatePipe('en-US');
  cp = new CurrencyPipe('en-US');
  page: number = 1;

  get dataSetKeys() {
    return this.tableKeys;
  }

  get dataHeaderKeys() {
    return this.tableKeys.map((k) => k.toUpperCase());
  }

  get pageStart() {
    return (this.page - 1) * 10;
  }

  get pageEnd() {
    return this.page * 10;
  }

  constructor(
    public formBuilder: UntypedFormBuilder,
    private configService: ConfigService,
    public stateStore: Store,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    // console.log(this);
    this.getSalesData();
  }

  ngOnInit(): void {
    this.month$.subscribe((m) => {
      this.month = m;
      this.getChartData();
    });

    this.year$.subscribe((y) => {
      this.year = y;
      this.getChartData();
    });

    this.quarter$.subscribe((q) => {
      this.quarter = q;
      this.getChartData();
    });

    this.store$.subscribe((s) => {
      this.store = s;
      this.getChartData();
    });


    this.view$.subscribe((v) => {
      this.view = viewOptions.find((i) => i.key === v);
      this.chartKeys = this.viewKeys[v].map((k) => k.key);
      this.getChartData();
    });

    this.chartType$.subscribe((c) => {
      this.chartType = c;
      this.getChartData();
    });

    this.displayMode$.subscribe((m) => {
      this.displayMode = m;
      // this.chartKeys = this.viewKeys[m.value];
      this.getChartData();
    });
  }

  getSalesData() {
    this.loading$ = true;
    this.stateStore
      .select(getStore)
      .pipe(
        concatMap((store) =>
          this.http.get<any>(`${environment.apiDomain}/orders/analytics/sales`, {}).pipe(
            tap((data) => {
              console.log(data);
              this.data = data;
              this.year = yearOptions.find((y) => y.value === data?.ys[0]?._id.year);
              this.quarter = quarterOptions.find((q) => q.value === data?.qs[0]?._id.quarter);
              this.month = monthOptions.find((m) => m.value === data?.ms[0]?._id.month);
              this.getChartData();
            })
          )
        ),
        catchError((error) => {
          console.log(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  setChartKeys(key: string) {
    if (this.chartKeys.includes(key)) {
      this.chartKeys = this.chartKeys.filter((k) => k !== key);
    } else {
      this.chartKeys.push(key);
    }
  }

  hexToRgba(hex: string, alpha: number) {
    // Remove the '#' if it exists
    hex = hex.replace("#", "");

    // Handle the case where the hex code is shortened (e.g., #abc)
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    // Parse the hex values for red, green, and blue
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // If alpha is not provided, default to 1 (fully opaque)
    alpha = alpha === undefined ? 1 : alpha;

    // Return the rgba value as a string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  quarterFilter(v: Array<any>) {
    return v.filter(
      (d) =>
        d._id.year === this.year.value && d._id.quarter === this.quarter.value
    );
  }

  monthFilter(v: Array<any>) {
    return v.filter(
      (d) => d._id.year === this.year.value && d._id.month === this.month.value
    );
  }

  yearFilter(v: Array<any>) {
    return v.filter((d) => d._id.year === this.year.value);
  }

  getAxisMonths() {
    if (this.displayMode.value === 0) {
      const { value: quarter } = this.quarter;
      return quarter === 1
        ? [1, 2, 3]
        : quarter === 2
        ? [4, 5, 6]
        : quarter === 3
        ? [7, 8, 9]
        : [10, 11, 12];
    }

      return [1,2,3,4,5,6,7,8,9,10,11,12]


  }

  getAxisLabels() {
    const months = this.getAxisMonths();
    console.log(months)
    console.log(this.mOptions)
    return months.map((m) => this.mOptions.find((mo) => mo.value === m)?.label);
  }

  getMaxValue(data: Array<any>) {
    return data.reduce((acc, d) => {
      const dMax = this.chartKeys.reduce((iAcc, k) => {
        return Math.max(acc, d[k]);
      }, 0);
      return Math.max(acc, dMax);
    }, 0);
  }

  getChartData() {
    this.getPieCharts();
    this.loading$ = true;
    this.lineChart.data.datasets = [];
    timer(2000).subscribe(() => {
      this.setChart();
    });
  }

  formatLabel(tooltipItem: any) {
      console.log(tooltipItem);
      if(this.displayMode.value === 0) {
        return this.cp.transform(tooltipItem.formattedValue);
      }
      return tooltipItem.formattedValue;
  }

  filterData() {
    console.log(this.year.value, this.quarter.value, this.month.value)
    const { qs, ms, ys } = this.data;
    const { value: mode } = this.displayMode;
    const dataSet = mode === 0 ? qs : mode === 1 ? ms : ys;
    return mode === 0
      ? this.quarterFilter(dataSet)
      : mode === 1
      ? this.yearFilter(dataSet)
      : this.yearFilter(dataSet);
  }

  setChart() {
    if(!this.data) return;
    // console.log(this)
    const data = this.filterData();
    const maxValue = data.sort((a, b) => b.revenue - a.revenue)?.[0]?.revenue ?? 0;
    const axisMonths = this.getAxisMonths();
    const axisLabels = this.getAxisLabels();
    const axisHexes = axisMonths.map(() =>
      this.hexToRgba(
        "#" + Math.floor(Math.random() * 16777215).toString(16),
        0.3
      )
    );
    let axisData = axisMonths.reduce((acc, label) => {
      acc[label] = {};
      return acc;
    }, {});

    console.log(data)
    console.log(axisMonths, storeOptions)
    axisMonths.forEach((m) => {


      const setsOfData = data.reduce(
        (acc: Record<string, number>, d: Record<string, any>) => {
          if(d._id.month !== m) return acc;
          this.chartKeys.forEach((k) => {
            acc[k] = acc[k] ? acc[k] + d[k] : d[k];
          });
          return acc;
        },
        {}
      );

      const month = monthOptions.find((mo) => mo.value === m);
      axisData[m] = { label: month?.label, data: setsOfData };
    });

    console.log(axisData)
    const s = [];

    Object.entries(axisData).forEach(([key, value]: [string, any]) => {
      if(value?.data) {
      Object.entries(value.data).forEach(
        ([entryKey, entryValue]: [string, number]) => {
          const set = {};
          this.chartKeys.forEach((k) => {
            if (entryKey === k) {
              set[k] = entryValue;
            }
          });
            s.push({ label: key, ...set });
          }
        );
      }
    });

    const z = s.reduce(
      (acc: Record<string, any>, value) => {
        const { label, ...rest} = value;
        const key = Object.keys(rest)[0];
        return {
          ...acc,
          [key]: [...(acc[key] ?? []), value[key]],
        };
      },
      {}
    );

    console.log(z)

    const series = Object.entries(z).map(
      ([key, value]: [string, any], i) => {
        console.log(key)
        const hex = getRandomLightColor()
        return {
          label: `${(keyLabels[key] ?? key)}`.trim(),
          fill: false,
          tension: 0.5,
          backgroundColor: `${this.hexToRgba(hex, 0.9)}`,
          borderColor: `${hex}`,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: `${hex}`,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: `${hex}`,
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: value,
        };
      }
    );




    console.log(series);
    this.activeDataSet = data;
    this.lineChart.data.datasets = series;
    this.lineChart.data.labels = this.displayMode.value === 2 ? [`${this.store.label} - ${this.year.value}`] : axisLabels;
    this.lineChart.options.scales.x.grid.color = axisHexes[0];
    this.lineChart.options.scales.y.ticks.max = maxValue;
    this.lineChart.options.scales.y.ticks.min = 0;
    this.lineChart.options.scales.y.grid.color = axisHexes[0];
    this.lineChart.options.plugins.legend.display = this.displayMode.value !== 2;
    this.lineChart.options.plugins.title.text = this.displayMode.value === 1 ? 'Inventory V by Orders' : 'Inventory Age by Month';
    this.lineChart.options.plugins.title.text = this.displayMode.value === 1 ? 'Distribution of invetory age by orders for this period' : 'Distribution of inventory age by month/quarter';

    this.lineChart.options.plugins['tooltip'] = {
      callbacks: {
          label: (tooltipItem: any) => {
            if(this.view.key === 'currency') {
              return '$' + tooltipItem.formattedValue;
            }
            return tooltipItem.formattedValue;
          },
      }
    }

console.log(this)

    this.loading$ = false;
    this.cdr.detectChanges();
  }

  getPieCharts() {
    if(!this.data) return;
    const { vendor } = this.data;
    const { value: mode } = this.displayMode;
    const dataSet = vendor;

    
    console.log(this.data.store)
    const pd = this.data.store.find((d: any) => d._id.store === this.store.value);
    console.log(pd)
    const pcd = this.viewKeys['age'].map((i) => i.key).reduce((acc: any, key: string) => {
      const hex = this.hexToRgba("#" + Math.floor(Math.random() * 16777215).toString(16), 0.7);
      return {
        ...acc,
        label: 'Total Inventory',
        backgroundColor: [...(acc?.backgroundColor ?? []), hex],
        data: [...(acc?.data ?? []), pd?.[key] ?? 0],
      }
    }, {})
    this.pieChart.data.labels = this.viewKeys['age'].map((i) => i.key).map((i) => {
      const { [i]: v, totalCount } = pd;
      const percentage = Number(((v / totalCount) * 100).toFixed(2));
      return `${this.keyLabels[i]} | ${percentage}%`;
    });
    this.pieChart.data.datasets = [pcd];

    const pcd2 = this.viewKeys['age'].map((i) => i.key).reduce((acc: any, key: string) => {
      const hex = this.hexToRgba("#" + Math.floor(Math.random() * 16777215).toString(16), 0.7);
      return {
        ...acc,
        label: 'Total Retail Value',
        backgroundColor: [...(acc?.backgroundColor ?? []), hex],
        data: [...(acc?.data ?? []), pd[`${key}Retail`]],
      }
    }, {})
    this.pieChart2.data.labels = this.viewKeys['age'].map((i) => i.key).map((i) => {
      const { [`${i}Retail`]: v, totalRetail } = pd;
      return `${this.keyLabels[i]} | ${this.cp.transform(v)}`;
    });
    this.pieChart2.data.datasets = [pcd2];

    const pcd3 = this.viewKeys['currency'].map((i) => i.key).reduce((acc: any, key: string) => {
      console.log(key)
      const hex = this.hexToRgba("#" + Math.floor(Math.random() * 16777215).toString(16), 0.7);
      return {
        ...acc,
        label: '',
        backgroundColor: [...(acc?.backgroundColor ?? []), hex],
        data: [...(acc?.data ?? []), pd[`${key}Retail`]],
      }
    }, {})
    this.pieChart3.data.labels = this.viewKeys['age'].map((i) => i.key).map((i) => {
      const { [`${i}Retail`]: v, totalRetail } = pd;
      return `${this.keyLabels[i]} | ${this.cp.transform(v)}`;
    });
    this.pieChart3.data.datasets = [pcd3];

    console.log(pcd, pcd2, pcd3)
  }
}
