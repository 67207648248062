import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { debounceTime, Observable, Subject, take, tap } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { FavoriteLine } from "src/app/core/models";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { ConfigService } from "src/app/core/services/config.service";
import { FavoritesService } from "src/app/core/services/favorites.service";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationParams } from "src/app/types/pagination";
import { Options } from "src/app/types/select";

@Component({
  selector: "app-favorites",
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.css"],
})
export class FavoritesComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;

  favorites: Array<FavoriteLine> = [];
  stores$: Observable<Array<Options>>;
  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Favorite Lines", url: "/favorite-lines" },
  ];

  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "";
  dir = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;

  favoriteForm = new FormGroup({
    _id: new FormControl(null),
    name: new FormControl(null, Validators.required),
    store: new FormControl(null, Validators.required),
  });

  get startIndex() {
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private fS: FavoritesService,
    private authService: AuthenticationService,
    private cs: ConfigService,
    private ms: BsModalService
  ) {
    this.stores$ = this.cs.storeOptions();
  }

  ngOnInit() {
    this.getFavorites();
    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.page = 1;
          this.pagination.skip = 0;
          this.getFavorites();
        })
      )
      .subscribe();

    this.favoriteForm.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  getFavorites() {
    this.fS
      .getFavorites(this.queryParams)
      .subscribe(({ items, offset, limit, total }) => {
        this.favorites = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
      });
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getFavorites();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getFavorites();
  }

  openModal(favorite?: FavoriteLine) {
    this.modalId = new Date().getTime();
    if (favorite?._id) {
      this.favoriteForm.patchValue(favorite);
    }
    const modalRef = this.ms.show(this.content, {
      id: this.modalId,
      class: "modal-sm",
    });
    modalRef.onHide
      .pipe(
        take(1),
        tap(() => this.favoriteForm.reset())
      )
      .subscribe();
  }

  onCancel() {
    this.ms.hide(this.modalId);
  }

  onUpdate() {
    console.log(this.favoriteForm.value);
    this.fS
      .updateFavorite(this.favoriteForm.value._id, this.favoriteForm.value)
      .pipe(
        take(1),
        tap(() => this.getFavorites())
      )
      .subscribe(() => this.ms.hide(this.modalId));
  }

  onCreate() {
    console.log(this.favoriteForm.value);
    this.fS
      .createFavorite(this.favoriteForm.value)
      .pipe(
        take(1),
        tap(() => this.getFavorites())
      )
      .subscribe(() => this.ms.hide(this.modalId));
  }

  onSort(col: string) {
    console.log(col);
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getFavorites();
  }
}
