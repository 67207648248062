import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiSelectComponent } from "./select.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [CommonModule, NgSelectModule],
  exports: [MultiSelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectModule {}
