import { CdkStepper } from "@angular/cdk/stepper";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Location } from "@angular/common";

import { Image, Order, Patient, Product } from "src/app/core/models";
import { OrderMinimalComponent } from "./order/order.component";
import {
  debounceTime,
  filter,
  map,
  Subject,
  Subscription,
  take,
  takeUntil,
  timer,
} from "rxjs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { generateObjectId } from "src/app/core/utils/objectId";
import { ImageCropperModalComponent } from "../image-cropper/image-cropper-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox";
import { OrdersService } from "src/app/core/services/orders.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormGroup } from "@angular/forms";
import { getOrderFormGroup, getOrderFrameFormGroup } from "src/app/shared/utils/forms";
import { isEqual } from "lodash";
import { PatientService } from "src/app/core/services/patient.service";
  @Component({
  selector: "app-new-patient-order",
  templateUrl: "./new-patient-order.component.html",
  styleUrls: ["./new-patient-order.component.css"],
})
export class NewPatientOrderComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @ViewChild("patientOrderNotes") ckEditor: CKEditorComponent;
  @ViewChild("orderImage") imageEl: HTMLImageElement;
  @ViewChild(CdkStepper) stepper: CdkStepper;
  @Output() orderSaved = new EventEmitter<Partial<Order>>();
  @Output() orderCancelled = new EventEmitter<void>();
  @Input() minimal: boolean = false;
  @Input() externalSave: boolean = false;
  @Input() patientId: string;
  defaultItem = { valid: false, product: {} };
  order: Partial<Order> = {
    _id: generateObjectId(),
    images: [],
    total:0,
    tax:0,
    shipping:0,
    cost:0,
    retail:0,
    deposit:0,
    balance:0,
    duty:false,
    status:"ORDERED",
    notify:false,
    shippingMethod:'',
    _products:[]
  };
  patient: Patient;
  productItems = [];
  newPatient: boolean = false;
  orderLoaded: boolean = false;
  modal: boolean = false;
  modalId: string;
  uploadedImages: Image[] = [];

  orderForm: FormGroup;

  // CkEditor variables
  editor = ClassicEditor;
  editorConfig = {
    id: "patient-order-notes",
    height: "150px",
    licenseKey: environment.editorLicenseKey,
    ai: {
      enabled: true,
      openAI: {
        requestHeaders: {
          Authorization: `Bearer ${environment.openAIKey}`,
        },
      },
    },
    toolbar: [
      "aiCommands",
      "aiAssistant",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
    ],
  };

  breadCrumbs = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Orders",
      url: "/orders",
    },
  ];

  editor$: Subscription;
  notesReady$ = new Subject<any>();
  destroy$ = new Subject<void>();
  wizardMode: boolean = false;
  manualEntry: Record<number, boolean> = {};
  favoriteVendorOptions: any[] = [];
  editable: boolean = true;

  get hasControls() {
    return (this.orderForm?.get('_products') as FormArray<FormGroup>)?.controls?.length;
  }

  get productControls() {
    const formArray = this.orderForm?.get('_products') as FormArray<FormGroup> ?? new FormArray<FormGroup>([]);
    return formArray.controls;
  }

  get productsValid() {
    return this.orderForm?.get('_products')?.valid;
  }

  get productValues() {
    return this.orderForm?.getRawValue()?._products;
  }

  get shippingMethod() {
    return this.orderForm?.get('shippingMethod')?.value;
  }

  get value() {
    return this.orderForm?.getRawValue() ?? this.order;
  }

  constructor(
    private location: Location,
    private ms: BsModalService,
    private lightbox: Lightbox,
    private oS: OrdersService,
    private route: ActivatedRoute,
    private pS: PatientService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    console.log(this.modal)
  }

  ngOnInit() {
    this.wizardMode = this.route.snapshot.data?.useWizard;
    if (!this.wizardMode && !this.minimal) {
      this.route.params.subscribe((params) => {
          this.oS.getOrder(params.orderId).pipe(take(1)).subscribe((order) => {
            console.log(order.status)
            this.editable = !['DISPENSED', 'CANCELLED', 'RETURNED'].includes(order.status);
            this.order = order;
            this.orderForm = getOrderFormGroup(this.order);
            this.orderLoaded = true;
            this.productItems = order._products.map((p,i) => i);
            if (order.invoiceNumber?.length) {
              this.breadCrumbs.push({
                label: order.invoiceNumber,
                url: undefined,
              });
            }
            if(order.patient) {
              this.patientId = order.patient;
              this.getPatient();
            }
            if(order.images?.length) {
              this.uploadedImages = order.images.filter(i => i.image).map((image, index) => ({
                image: image,
                caption: `Order image ${index + 1}`,
                src: image,
              }));
            }   
          });
      });
    } else {
      this.orderForm = getOrderFormGroup(this.order);
      if(this.order._images?.length) {
        this.uploadedImages = this.order._images;
      }   
    }

    if(this.patientId) {
      this.getPatient();
    }
  }

  ngAfterViewInit() {
    console.log(this)
    this.orderForm?.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((order) => {
      console.log(order);
      this.onCalculate();
    });
    this.notesReady$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      const notes = this.order?.notes;
      if (!notes) return;
      this.ckEditor.editorInstance.data.set(notes ?? "Order Notes");
    });

    timer(100, 100)
      .pipe(
        takeUntil(this.notesReady$),
        filter(() => !!this.order && !!this.ckEditor?.editorInstance)
      )
      .subscribe(() => {
        this.notesReady$.next(this.order?.notes);
      });
  }

  ngAfterViewChecked() {
    if (this.ckEditor && !this.editor$) {
      // Patch the editor changes to the log control of type 'patient'
      // only one exists
      this.editor$ = this.ckEditor.change
        .pipe(debounceTime(250), takeUntil(this.destroy$))
        .subscribe((event) => {
          const editorContent = this.ckEditor.editorInstance.data.get();
          this.orderForm.patchValue({ notes: editorContent }, { emitEvent: false });
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addProduct(manual?: boolean, vendor?: string) {
    const productsFormArray = this.orderForm.get('_products') as FormArray<FormGroup>;
    const idx = productsFormArray.length;
    if(manual) {
      this.manualEntry = {
        ...this.manualEntry,
        [idx]: manual ?? false
      }
    }
    productsFormArray.push(getOrderFrameFormGroup({
      _id: generateObjectId(),
      quantity: 1,
      total: 0,
      tax: 0,
      shipping: 0,
      cost: 0,
      retail: 0,
      orderStatus: this.order.status,
      order: this.order._id,
      patient: this.patient?._id,
      vendor: vendor ?? null,
    }));
  }

  getPatient() {
    this.pS.getPatient(this.patientId).pipe(take(1)).subscribe((patient) => {
      this.patient = patient;
      this.orderForm.patchValue({ patient: patient._id }, { emitEvent: false });
      this.favoriteVendorOptions = patient._vendors ?? [];
    });
  }

  getNumberValue(value: string | number) {
    if (typeof value === "number") return value;
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }

  removeProduct(index: number) {
    const productsFormArray = this.orderForm.get('_products') as FormArray<FormGroup>;
    const { [index]: _, ...rest } = this.manualEntry;
    this.manualEntry = rest;
    productsFormArray.removeAt(index);
    this.onCalculate();
  }

  onProductChange(product: Partial<Product>, index: number) {
    const productsFormArray = this.orderForm.get('_products') as FormArray<FormGroup>;
    productsFormArray.controls[index].patchValue(product, { emitEvent: false });
    this.onCalculate();
  } 

  onPatientSelect(patient: Patient) {
    this.patient = patient;
    this.orderForm.patchValue({ patient: patient._id }, { emitEvent: false });
    this.favoriteVendorOptions = patient._vendors ?? [];
    this.stepper.next();
  }

  onShippingMethodChange(event: any, method: string) {
    if(!this.orderForm) return;
    const order = this.orderForm.getRawValue();
    order.shippingMethod = event.target.checked ? method : null;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onTaxChange() {
    const order = this.orderForm.getRawValue();
    const additionalTax =
      order.tax - order._products.reduce((acc, p) => acc + p.tax, 0);
    const avgTax = additionalTax / order._products.length;
    order._products.forEach((p) => {
      p.tax = Number((p.tax + avgTax).toFixed(2));
    });
    order.total = order._products.reduce(
      (acc, p) => acc + p.total + p.shipping + (!this.order.duty ? 0 : p.tax),
      0
    );
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onShippingChange() {
    const order = this.orderForm.getRawValue();
    const additionalShipping =
      order.shipping - order._products.reduce((acc, p) => acc + p.shipping, 0);
    const avgShipping = additionalShipping / order._products.length;
    order._products.forEach((p) => {
      p.shipping = Number((p.shipping + avgShipping).toFixed(2));
    });
    order.total = order._products.reduce(
      (acc, p) => acc + p.total + p.shipping + (!order.duty ? 0 : p.tax),
      0
    );
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onDutyChange() {
    const order = this.orderForm.getRawValue();
    if (!order.duty) {
      order.tax = 0;
    } else {
      order.tax = order._products.reduce((acc, p) => acc + p.tax, 0);
    }
    order.total = order._products.reduce(
      (acc, p) => acc + p.total + p.shipping + (!order.duty ? 0 : p.tax),
      0
    );
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onDepositChange() {
    const order = this.orderForm.getRawValue();
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onTotalChange() {
    const order = this.orderForm.getRawValue();
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.patchValue(order, { emitEvent: false });
  }

  onCalculate() {
    const order = this.orderForm.getRawValue();
    order.total = order._products.reduce((acc, item) => acc + item.total, 0);
    order.tax = order._products.reduce((acc, item) => acc + item.tax, 0);
    order.shipping = order._products.reduce((acc, item) => acc + item.shipping, 0);
    order.cost = order._products.reduce((acc, item) => acc + item.cost, 0);
    order.retail = order._products.reduce((acc, item) => acc + item.retail, 0);
    order.balance = order.total - order.deposit;
    if(isEqual(order, this.orderForm.getRawValue())) return;
    this.orderForm.setValue(order);
  }

  onConfirm() {
    this.onCalculate();
    timer(1000).subscribe(() => {
      this.stepper.next();
    });
  }

  onSubmit() {
    const order = this.orderForm.getRawValue();
    console.log(order);
    this.oS.createOrder(order).subscribe((order) => {
      this.order = order;
      if(this.externalSave) {
        this.orderSaved.emit(this.order);
      } else {
        this.router.navigate(['/orders', order._id, 'invoice']);
      }
    });
  }

  onCancel() {
    if(this.modalId) {
      this.orderCancelled.emit();
    } else if(!this.minimal) {
      this.location.back();
    } else {
      this.orderSaved.emit(this.order);
    }
  }

  openImageCropper() {
    if(this.modal) return;
    const modalRef = this.ms.show(ImageCropperModalComponent, {
      id: `${this.order._id}-image-cropper`,
      class: "modal-lg",
      ignoreBackdropClick: true,
      backdrop: true,
      initialState: {
        refId: this.order._id,
        type: "order",
        modalId: `${this.order._id}-image-cropper`,
      },
    });
    modalRef.content.image$.pipe(take(1)).subscribe((image) => {
      this.uploadedImages.push(image);
      console.log(image);
    });
    modalRef.onHide.subscribe((reason: string) => {
      if (reason !== "cancel") {
        this.order.images = [...this.order.images, reason];
        console.log(this.order);
      }
      modalRef.hide();
    });
  }

  openImages(idx: number) {
    const album = this.uploadedImages.map((image, index) => ({
      caption: `Order image ${index + 1}`,
      src: image.image,
      thumb: image.image,
    }));
    this.lightbox.open(album, idx);
  }
}
