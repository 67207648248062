<div class="container-fluid">
  <app-page-title
    title="Favorite Lines"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchFavorites
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-select form-control-sm mx-2 heading"
                    >
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th class="sortable" (click)="onSort('name')">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (favorite of favorites; track favorite; let i = $index) {
                  <tr>
                    <td>{{ favorite.name || "-" }}</td>
                    <td class="action icon-cell">
                    

                      <div class="btn-group">
                        <div class="dropdown" dropdown placement="left">
                          <i
                            data-toggle="dropdown"
                            dropdownToggle
                            class="mdi mdi-dots-vertical dropdown-toggle"
                          ></i>
                          <div
                            class="dropdown-menu dropdown-menu-end"
                            *dropdownMenu
                          >
                            <a
                              class="dropdown-item"
                              href="javascript: void(0);"
                              (click)="openModal(favorite)"
                              ><i class="fas fa-edit"></i> Update</a
                            >
                            <a class="dropdown-item" href="javascript: void(0);"
                              ><i class="fas fa-trash-alt"></i> Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  users
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [itemsPerPage]="pagination.take"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" (click)="openModal()">
        Add Favorite Line
      </button>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="container-fluid">
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel">
        {{
          favoriteForm.value._id ? "Update Favorite Line" : "Add Favorite Line"
        }}
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="onCancel()"
      ></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="favoriteForm">
        <div class="row">
          <div class="col-12">
            <div class="form-floating mb-3">
              <input
                type="text"
                formControlName="name"
                class="form-control form-select"
                id="name"
                placeholder="Name"
              />
              <label for="name">Name</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-floating mb-3">
              <select
                formControlName="store"
                class="form-select"
                id="store"
                aria-label="Store"
              >
                @for (option of stores$ | async; track option) {
                <option value="{{ option.value }}">
                  {{ option.label }}
                </option>
                }
              </select>
              <label for="store">Store</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-actions px-3 py-2">
      <button class="btn btn-secondary me-3" (click)="onCancel()">
        Cancel
      </button>
      @if (favoriteForm.value._id) {
      <button
        class="btn btn-primary"
        (click)="onUpdate()"
        [disabled]="favoriteForm.invalid"
      >
        Update Favorite Line
      </button>
      } @else {
      <button
        class="btn btn-primary"
        (click)="onCreate()"
        [disabled]="favoriteForm.invalid"
      >
        Add Favorite Line
      </button>
      }
    </div>
  </div>
</ng-template>
