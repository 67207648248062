import { Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { debounceTime, of, Subject, take, takeUntil, tap } from "rxjs";
import { Observable } from "rxjs";
import { OrdersService } from "src/app/core/services/orders.service";
import { getOrderFormGroup } from "src/app/shared/utils/forms";
import { Pagination, PaginationParams } from "src/app/types/pagination";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Order } from "src/app/core/models";
import { BsModalService } from "ngx-bootstrap/modal";
import { OrderComponent } from "./order/order.component";
import { Lightbox } from "ngx-lightbox";
import { NewPatientOrderComponent } from "../new-patient-order/new-patient-order.component";
@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @Input() child: boolean = false;
  @Input() limit: number = 10;
  @Input() offset: number = 0;
  @Input() patientId: string;
  @Input() vendorId: string;
  orderForm = getOrderFormGroup();
  orders: Array<Order> = [];
  orderSub$: Observable<Pagination<Order>> = of({
    items: [],
    offset: this.offset ?? 0,
    limit: this.limit ?? 100,
    total: 0,
  });
  expandedRows: Record<number, boolean> = {};
  page: number = 1;
  search = "";
  search$ = new Subject<void>();
  pagination: PaginationParams = DEFAULT_PAGINATION;
  noResults: boolean = false;
  adding: boolean = false;
  col = "";
  dir = "";

  breadCrumbs = [
    { label: "Admin", url: "/" },
    { label: "Orders", url: "/orders" },
  ];

  loading$: boolean = true;

  get startIndex() {
    if (!this.pagination.total) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return { search: this.search, ...this.pagination };
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private os: OrdersService,
    private route: ActivatedRoute,
    private ms: BsModalService,
    private lightbox: Lightbox
  ) {}

  ngOnInit(): void {
    this.getOrders();

    this.search$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.loading$ = true;
          this.page = 1;
          this.pagination.skip = 0;
          this.getOrders();
        })
      )
      .subscribe();
  }

  getCall(params?: Params) {
    this.loading$ = true;
    if (params.patientId) {
      return this.os.getPatientOrders(params.patientId, this.queryParams);
    } else if (params.vendorId) {
      return this.os.getVendorOrders(params.vendorId, this.queryParams);
    } else {
      return this.os.getOrders(params);
    }
  }

  getOrders() {
    const { skip, take } = this.pagination;
    this.getCall(
      removeEmptyParams({
        skip,
        take,
        col: this.col,
        dir: this.dir,
        search: this.search,
        ...{
          patientId: this.patientId,
          vendorId: this.vendorId,
        },
        ...this.route.snapshot.params,
      })
    ).subscribe(({ items, offset, limit, total }) => {
      this.orders = items;
      this.pagination = {
        skip: +offset,
        take: +limit,
        total: +total,
      };
      this.noResults = !items.length;
      this.loading$ = false;
    });
  }

  expandRow(index: number | string) {
    this.expandedRows = { [index]: true };
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getOrders();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getOrders();
  }

  onEdit(order: Order) {

    const done$ = new Subject<void>();
    const modalRef = this.ms.show(NewPatientOrderComponent, {
      id: `${order._id}-modal`,
      initialState: { order, minimal: true, patientId: this.patientId, modal: true, externalSave: true, modalId: `${order._id}-modal` },
      class: "modal-xl",
    });

    modalRef.content.orderCancelled.pipe(take(1),takeUntil(done$)).subscribe(() => {
      done$.next();
      modalRef.hide();
    });
    modalRef.content.orderSaved.pipe(take(1),takeUntil(done$)).subscribe(() => {
      done$.next();
      modalRef.hide();
      this.getOrders();
    });
  }

  onAdd() {
    const modalRef = this.ms.show(OrderComponent, {
      id: `${this.patientId}-order-modal`,
      initialState: {
        modal: true,
        patientId: this.patientId,
        modalId: `${this.patientId}-order-modal`,
      },
      class: "modal-xl",
    });
    modalRef.onHide.subscribe((reason) => {
      if (reason === "save") {
        this.getOrders();
      }
    });
  }

  onSort(col: string) {
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getOrders();
  }

  openLightbox(order: Order, index: number) {
    let album = order._products.map((product) => ({ 
      src: product._images[0].image,
      caption: `Invoice #${order.invoiceNumber} | ${product.vendorName} | ${product.model}`,
      thumb: product._images[0].image,
    }));
    if(order._images.length) {
      const orderImages = order._images.filter((image) => image.image).map((image) => ({
        src: image.image,
        caption: `Invoice #${order.invoiceNumber}`,
        thumb: image.image,
      }));
      album = [ ...orderImages, ...album];
    }
    this.lightbox.open(album, index);
  }

  onComplete(saved?: boolean) {
    this.adding = false;
    this.getOrders();
  }
}
