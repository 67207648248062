<div [class.container-fluid]="!child">
  <app-page-title
    *ngIf="!child"
    title="Prescriptions"
    [breadcrumbItems]="breadCrumbs"
  ></app-page-title>
  <div class="row">
    <div [class.card]="!child">
      <div [class.card-body]="!child">
        <div [class.row]="!child">
          <div class="col-12">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      #searchPrescriptions
                      name="tickets-table_length"
                      aria-controls="tickets-table"
                      name="pageSize"
                      [(ngModel)]="pagination.take"
                      (ngModelChange)="onPageSizeChange($event)"
                      class="form-control form-control-sm mx-2"
                    >
                      <option *ngIf="child" [ngValue]="5">5</option>
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <input
                      type="text"
                      name="searchTerm"
                      class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table"
                      [(ngModel)]="search"
                      (ngModelChange)="search$.next()"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Status</th>
                    <th>RX Type</th>
                    <th>Exam Date</th>
                    <th>Doctor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @if(noResults) {
                  <tr>
                    <td colspan="10">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                        style="width: 100%; min-height: 100px; opacity: 0.3"
                      >
                        <h2 class="mt-3">
                          No orders found
                          {{ search ? "for the search " + search : "" }}
                        </h2>
                      </div>
                    </td>
                  </tr>
                  } @else { @for (prescription of prescriptions; track
                  prescription) {
                  <tr>
                    <td align="center">
                      <a
                        class="expand-row"
                        (click)="expandRow(prescription._id)"
                      >
                        +
                      </a>
                    </td>
                    <td>{{ prescription | rxExpired }}</td>
                    <td>{{ prescription.type || 0 }}</td>
                    <td>{{ prescription.examDate | date : "MM-dd-YYYY" }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  }}
                </tbody>
              </table>
            </div>
            <!-- End table -->
            <div
              *ngIf="pagination.total"
              class="row justify-content-md-between align-items-md-center mt-2"
            >
              <div class="col-sm-12 col-md-5">
                <div
                  class="dataTables_info mb-2"
                  id="tickets-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {{ startIndex }} to {{ endIndex }} of
                  {{ pagination.total }}
                  prescriptions
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-sm-12 col-md-5">
                <div class="text-md-right float-md-end pagination-rounded">
                  <pagination
                    [maxSize]="4"
                    [totalItems]="pagination.total"
                    [(ngModel)]="page"
                    (ngModelChange)="onPageChange($event)"
                  >
                  </pagination>
                </div>
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" [class.mt-3]="child">
        Add Prescription
      </button>
    </div>
  </div>
</div>
