import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Options } from "chartist";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { Observable, Subject, take } from "rxjs";
import { DEFAULT_PAGINATION } from "src/app/core/constants/pagination";
import { PayableGroup, Vendor } from "src/app/core/models";
import { PayablesService } from "src/app/core/services/payables.service";
import { removeEmptyParams } from "src/app/shared/utils/queries/params";
import { PaginationParams } from "src/app/types/pagination";
import { PayableComponent } from "../payable/payable.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorService } from "src/app/core/services/vendor.service";

@Component({
  selector: "app-paid-payables",
  templateUrl: "./paid-payables.component.html",
  styleUrls: ["./paid-payables.component.css"],
})
export class PaidPayablesComponent implements OnInit, OnChanges {
  @ViewChild(PaginationComponent) pC: PaginationComponent;
  @ViewChild("content") content: TemplateRef<any>;
  @Input() receivable: boolean = false;
  @Input() store: number;

  payables: Array<PayableGroup> = [];
  storeMap: Record<string, string> = {};
  stores: Array<Options> = [];
  stores$: Observable<Array<Options>>;
  roles$: Observable<Array<Options>>;
  breadCrumbs = [{ label: "Admin", url: "/" }];

  vendorId: string;
  vendor: Vendor;
  modalId: number;
  page: number = 1;
  searchId: number;
  search = "";
  col = "";
  dir = "";
  search$ = new Subject<void>();
  loading$ = true;

  pagination: PaginationParams = DEFAULT_PAGINATION;

  get startIndex() {
    if (!this.payables.length) return 0;
    return this.pagination.skip + 1;
  }

  get endIndex() {
    const lastIndex = this.pagination.skip + this.pagination.take;
    return lastIndex > this.pagination.total
      ? this.pagination.total
      : lastIndex;
  }

  get queryParams() {
    return removeEmptyParams({
      search: this.search,
      ...this.pagination,
      col: this.col,
      dir: this.dir,
      ...(this.vendorId ? { vendors: [this.vendorId] } : {}),
      ...(this.receivable ? { receivable: true } : {}),
      ...(this.store ? { store: this.store } : {}),
    });
  }

  get totalRecords() {
    return this.pagination.total;
  }

  constructor(
    private payablesService: PayablesService,
    private ms: BsModalService,
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.breadCrumbs.push({
          label: "Payables",
          url: `/payables`,
        });
        this.vendorId = params["id"];
        this.getVendor();
        this.getPayableGroups();
      }
    });
  }

  ngOnInit() {
    if (this.receivable) {
      this.breadCrumbs.push({
        label: "Receivables",
        url: `/receivables`,
      });
      this.getPayableGroups();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.store && changes.store.currentValue) {
      this.getPayableGroups();
    }
  }

  getVendor() {
    this.vendorService.getVendor(this.vendorId).subscribe((vendor) => {
      this.breadCrumbs.push({
        label: vendor.name,
        url: `/payables/vendor/${this.vendorId}/paid`,
      });
    });
  }

  getPayableGroups() {
    this.loading$ = true;
    this.payablesService
      .getPayableGroups(this.queryParams)
      .subscribe(({ items, offset, limit, total }) => {
        this.payables = items;
        this.pagination = {
          skip: +offset,
          take: +limit,
          total: +total,
        };
        this.loading$ = false;
      });
  }

  onPageChange(page: number) {
    const skip = (page - 1) * this.pagination.take;
    if (skip === this.pagination.skip) return;
    this.pagination.skip = skip;
    this.getPayableGroups();
  }

  onPageSizeChange(take: number) {
    this.page = 1;
    this.getPayableGroups();
  }

  onSort(col: string) {
    this.col = col;
    this.dir = this.dir === "asc" ? "desc" : "asc";
    this.getPayableGroups();
  }

  openModal(payable?: PayableGroup) {
    this.modalId = new Date().getTime();
    const modalRef = this.ms.show(PayableComponent, {
      id: this.modalId,
      class: "modal-lg",
      initialState: {
        payable,
      },
    });
    modalRef.onHide.pipe(take(1)).subscribe();
  }

  viewByStatus(status: string) {
    console.log(status);
    if(status === 'Paid') {
      this.router.navigate(['/payables/vendor', this.vendorId, 'paid']);
    } else {
      this.router.navigate(['/payables/vendor', this.vendorId, 'unpaid']);
    }
  }
}
