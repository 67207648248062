import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ConfigService } from "src/app/core/services/config.service";
import { PatientService } from "src/app/core/services/patient.service";

import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { getPatientFormGroup } from "src/app/shared/utils/forms/patient";
import { Patient } from "src/app/core/models";

@Component({
  selector: "app-patient-fill",
  templateUrl: "./patient-fill.component.html",
  styleUrls: [],
})
export class PatientFilleComponent implements OnInit {
  @Input() patient: Partial<Patient>;
  @Input() readonly = false;
  patientForm = getPatientFormGroup();

  constructor(
    public cs: ConfigService,
    protected ps: PatientService,
    private ms: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patientForm = getPatientFormGroup(this.patient);
  }
}
