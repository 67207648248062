<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12"></div>
      <div class="col-sm-6">
        <!-- {{year}} © . -->
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          <img
            *ngIf="layout === 'light'"
            src="assets/images/logo_footer.png"
            alt="Powered by Autooptiq"
          />
          <img
            *ngIf="layout === 'dark'"
            src="assets/images/logo_footer_light.png"
            alt="Powered by Autooptiq"
          />
        </div>
      </div>
    </div>
  </div>
</footer>
