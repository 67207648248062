import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: "[appCurrencyFormatter]",
})
export class CurrencyFormatterDirective implements OnInit {
  @Input() decimal = true;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
  ];

  constructor(private el: ElementRef, private control: NgControl) {}

  ngOnInit() {
    this.onInputChange({} as InputEvent);
  }

  @HostListener("input", ["$event"])
  onInputChange(event: InputEvent) {
    const { value } = this.el.nativeElement;
    const initialValue = value?.trim().length ? value : "0.00";

    // Remove all non-digits (except decimal point)
    let newValue = initialValue.replace(/[^\d.]/g, "");
    const numberValue = parseFloat(newValue);

    // Ensure only one decimal point
    const parts = newValue.split(".");
    if (parts.length > 2) newValue = parts[0] + "." + parts.slice(1).join("");

    // Limit to 2 decimal places
    if (parts.length > 1) {
      newValue = parts[0] + "." + parts[1].slice(0, 2); // VVVV
    }

    // Format with commas
    const numberParts = newValue.split(".");
    numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    newValue = numberParts.join(".");

    // Add dollar sign
    newValue = "$" + newValue;

    // Update the value
    this.el.nativeElement.value = newValue;

    if (this.control && this.control.control) {
      // Update form control with numeric value
      this.control.control.setValue(newValue);
    }

    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation?.();
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (!/[0-9.]/.test(event.key)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
  }

  @HostListener("blur", ["$event"])
  onBlur() {
    const value = this.el.nativeElement.value ?? 0;
    if (value) {
      const numericValue = parseFloat(value.replace(/[$,]/g, "")) || 0;
      this.el.nativeElement.value =
        "$" + numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}