import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Vendor } from "../models/vendor";
import { Params } from "@angular/router";
import { Pagination } from "src/app/types/pagination";
import { AuthenticationState } from "src/app/store/Authentication/authentication.reducer";
import { Store } from "@ngrx/store";
import { getStore } from "src/app/store/Authentication/authentication-selector";
import { concatMap, Observable, of } from "rxjs";
import { SelectOptions } from "../models/select";

@Injectable({
  providedIn: "root",
})
export class VendorService {
  baseUrl = environment.apiDomain;

  constructor(
    private http: HttpClient,
    private store: Store<AuthenticationState>
  ) {}

  getVendor(vendorId: string) {
    return this.http.get<Vendor>(`${this.baseUrl}/vendors/${vendorId}`);
  }

  getVendors(params?: Params) {
    return this.store.select(getStore).pipe(
      concatMap((store) => {
        const selectedStore = params?.store ?? store;
        return this.http.get<Pagination<Vendor>>(
          `${this.baseUrl}/vendors/store/${store}`,
          {
            params,
          }
        );
      })
    );
  }

  getVendorGroups(params?: Params) {
    return this.store.select(getStore).pipe(
      concatMap((store) => {
        const selectedStore = params?.store ?? store;
        return this.http.get<
          Pagination<Vendor & { distributors: Array<Vendor> }>
        >(`${this.baseUrl}/vendor-groups`, {
          params: {
            ...(params ?? {}),
            store: selectedStore,
          },
        });
      })
    );
  }

  createVendor(vendor: Vendor) {
    return this.http.post<Vendor>(`${this.baseUrl}/vendors`, vendor);
  }

  updateVendor(vendor: Vendor) {
    return this.http.put<Vendor>(
      `${this.baseUrl}/vendors/${vendor._id}`,
      vendor
    );
  }

  getAllVendors() {
    return this.http.get<Vendor[]>(`${this.baseUrl}/vendors`);
  }

  getVendorOptions(): Observable<Array<SelectOptions>> {
    return this.store.select(getStore).pipe(
      concatMap((store) => {
        return this.http.get<Array<SelectOptions>>(
          `${this.baseUrl}/options/vendors/${store}`
        );
      })
    );
  }

  getVendorModels(vendorId: string): Observable<Array<SelectOptions>> {
    console.log(vendorId);
    if (!vendorId) {
      return of([]);
    }
    return this.http.get<Array<SelectOptions>>(
      `${this.baseUrl}/vendors/${vendorId}/frames/models`
    );
  }

  getVendorModelOptions(vendorId: string): Observable<Array<SelectOptions>> {
    if (!vendorId) {
      return of([]);
    }
    return this.http.get<Array<SelectOptions>>(
      `${this.baseUrl}/vendors/${vendorId}/frames/models`
    );
  }

  getModelColorOptions(vendorId: string, model: string): Observable<Array<SelectOptions>> {
    if (!model || !vendorId) {
      return of([]);
    }
    return this.http.get<Array<SelectOptions>>(
      `${this.baseUrl}/vendors/${vendorId}/frames/models/${model}/colors`
    );
  }

  getColorSizeOptions(vendorId: string, model: string, color: string): Observable<Array<SelectOptions>> {
    if (!model || !color || !vendorId) {
      return of([]);
    }
    return this.http.get<Array<SelectOptions>>(
      `${this.baseUrl}/vendors/${vendorId}/models/${model}/colors/${color}/sizes`
    );
  }
}
